import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DiscussionsApi, GetGoalDto, GoalRecordDetailDto } from "@api";

import { TeamContext } from "~services/contexts";
import { GoalStateService } from "~services/state";
import { mapGoalDiscussionInput } from "~shared/util/discussion-input-builder";
import { mapGoalOrigin } from "~shared/util/origin-builder";

import { CommonAddChildDiscussionButtonDirective } from "./add-child-discussion-button.component";

declare type GoalDto = GetGoalDto | GoalRecordDetailDto;

@Component({
    selector: "app-add-goal-child-discussion-button",
    templateUrl: "./add-child-discussion-button.component.html",
    styleUrls: ["./add-child-discussion-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class AddGoalChildDiscussionButtonComponent extends CommonAddChildDiscussionButtonDirective<GoalDto> {

    readonly mapToOrigin = mapGoalOrigin;
    readonly mapToInput = mapGoalDiscussionInput;

    constructor(
        discussionsApi: DiscussionsApi,
        teamContext: TeamContext,
        dialog: MatDialog,
        private readonly goalStateService: GoalStateService,
    ) {
        super(discussionsApi, teamContext, dialog);
    }

    protected notifyChange = (source: GoalDto) => this.goalStateService.notifyRefresh(source);

    protected creationAllowed = (source: GoalDto): boolean => !source.isPrivate;
}
