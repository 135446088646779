import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { PlanTierDto } from "@api";

interface UserCappedDialogData {
    currentPlan?: PlanTierDto;
}

@Component({
    templateUrl: "./user-capped-dialog.component.html",
    styleUrls: ["./user-capped-dialog.component.scss"],
    standalone: false,
})
export class UserCappedDialogComponent {

    get currentPlan() {
        return this.data?.currentPlan;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) readonly data: UserCappedDialogData,
    ) { }

    static open(dialog: MatDialog, currentPlan?: PlanTierDto) {
        return dialog.open<UserCappedDialogComponent, UserCappedDialogData, unknown>(UserCappedDialogComponent, {
            autoFocus: false,
            data: {
                currentPlan: currentPlan,
            }
        });
    }
}
