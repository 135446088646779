import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActionsV2Api, GetActionDto } from "@api";
import * as moment from "moment";
import {
    BehaviorSubject, catchError, combineLatest, concatMap, debounceTime, distinctUntilChanged,
    map, Observable, of, Subscription, switchMap, tap
} from "rxjs";

import { AccessService, AccessState } from "~services/access.service";
import { MeetingProgressService } from "~services/meeting-progress.service";
import { NotificationService } from "~services/notification.service";
import { ActionStateService } from "~services/state";
import { CommonFunctions } from "~shared/commonfunctions";
import { ActionProgress } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { actionProgressNameKey } from "~shared/util/translation-helper";

const regularProgreses = [
    ActionProgress.notStarted,
    ActionProgress.inProgress,
    ActionProgress.complete,
    ActionProgress.cancelled,
];

const overdueProgresses = [
    ActionProgress.overdue,
    ActionProgress.complete,
    ActionProgress.cancelled,
];

const isPastDue = (action: GetActionDto) =>
    moment(action.dueDate).add(1, "day").isBefore(moment());

const sanitiseProgress = (progress: ActionProgress, isActionPastDue: boolean) => {
    if (progress === ActionProgress.complete || progress === ActionProgress.cancelled) return progress;
    if (isActionPastDue) {
        // If past the due date and not complete, the action is overdue
        return ActionProgress.overdue;
    }
    if (!isActionPastDue && progress === ActionProgress.overdue) {
        return ActionProgress.notStarted;
    }
    return progress;
};

const getActionProgress = (action: GetActionDto | undefined) =>
    !action ? ActionProgress.notStarted : sanitiseProgress(action.progress, isPastDue(action));

interface ActionFormState {
    progress: ActionProgress;
    status: boolean;
}

const toFormState = (progress: ActionProgress): ActionFormState =>
    ({ progress, status: progress === ActionProgress.complete || progress === ActionProgress.cancelled });

const getActionFormState = (action: GetActionDto | undefined): ActionFormState =>
    toFormState(getActionProgress(action));

@Component({
    selector: "app-action-progress-update",
    templateUrl: "./action-progress-update.component.html",
    styleUrls: ["./action-progress-update.component.scss"],
    host: {
        "class": "wf-action-status-update"
    },
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ActionProgressUpdateComponent extends WithDestroy() implements OnInit, OnDestroy {

    get action() {
        return this.actionSubject.value;
    }

    @Input() set action(value: GetActionDto | undefined) {
        const isSameAction = value?.id === this.actionSubject.value?.id;
        this.actionSubject.next(value);
        if (!isSameAction) {
            this.form.reset(getActionFormState(value), { emitEvent: false });
        } else if (!this.progressControl.dirty) {
            this.form.setValue(getActionFormState(value), { emitEvent: false });
        }
    }

    get disabled(): boolean {
        return this.disabledSubject.value;
    }

    @Input() set disabled(value: boolean) {
        this.disabledSubject.next(coerceBooleanProperty(value));
    }

    @Output() updated = new EventEmitter<GetActionDto>();

    @HostBinding("class.wf-action-complete") get isComplete(): boolean {
        return this.sanitizedProgress === ActionProgress.complete;
    }

    @HostBinding("class.wf-action-overdue") get isOverdue(): boolean {
        return this.sanitizedProgress === ActionProgress.overdue;
    }

    @HostBinding("class.wf-action-cancelled") get isCancelled(): boolean {
        return this.sanitizedProgress === ActionProgress.cancelled;
    }

    get isLoading() {
        return this.isLoadingInternal;
    }

    set isLoading(value: boolean) {
        if (value) {
            CommonFunctions.showLoader();
        } else {
            CommonFunctions.hideLoader();
        }
        this.isLoadingInternal = value;
    }

    readonly statusControl = new FormControl(false, { nonNullable: true });
    readonly progressControl = new FormControl(ActionProgress.notStarted, { nonNullable: true });

    readonly form = new FormGroup({
        status: this.statusControl,
        progress: this.progressControl,
    });

    readonly actionProgressNameKey = actionProgressNameKey;

    readonly progressOptions$: Observable<ActionProgress[]>;

    private readonly access$: Observable<AccessState>;

    private readonly actionSubject = new BehaviorSubject<GetActionDto | undefined>(undefined);
    private readonly disabledSubject = new BehaviorSubject<boolean>(false);
    private isLoadingInternal = false;

    private readonly subscriptions = new Subscription();

    private get isPastDue(): boolean {
        return !!this.action && isPastDue(this.action);
    }

    private get sanitizedProgress(): ActionProgress {
        return sanitiseProgress(this.progressControl.value, this.isPastDue);
    }

    constructor(
        private readonly actionsApi: ActionsV2Api,
        private readonly actionStateService: ActionStateService,
        private readonly meetingProgressService: MeetingProgressService,
        private readonly accessService: AccessService,
        private readonly notificationService: NotificationService,
    ) {
        super();

        this.access$ = combineLatest({
            disabled: this.disabledSubject,
            action: this.actionSubject,
        }).pipe(
            switchMap(({ disabled, action }) => {
                if (disabled || !action) return of(AccessState.disabled);
                const { company, team } = getDelegatedItemCompanyTeam(action);
                return this.accessService.getAccessState(company.id, team.id);
            }),
            shareReplayUntil(this.destroyed$),
        );

        const inMeeting$ = this.actionSubject.pipe(
            switchMap(action => !action ? of(false) : this.meetingProgressService.isInProgress$(action?.company.id, action?.team.id)),
        );

        this.progressOptions$ = combineLatest({
            action: this.actionSubject,
            inMeeting: inMeeting$,
        }).pipe(
            map(({ action, inMeeting }) => {
                if (!action) return [];
                const baseProgresses = isPastDue(action) ? overdueProgresses : regularProgreses;
                // We can only mark an action as cancelled from a meeting, or if it is a private action.
                if (!inMeeting && !action.isPrivateAction && action.progress !== ActionProgress.cancelled) {
                    return baseProgresses.filter(p => p !== ActionProgress.cancelled);
                }
                // Otherwise, we can use any status.
                return baseProgresses;
            })
        );
    }

    ngOnInit(): void {
        this.subscriptions.add(this.access$.pipe(
            map(a => a.canEdit),
            distinctUntilChanged(),
        ).subscribe(canEdit => {
            if (canEdit) {
                this.form.enable({ emitEvent: false });
            } else {
                this.form.disable({ emitEvent: false });
            }
        }));
        this.subscriptions.add(
            this.progressControl.valueChanges.pipe(
                tap(() => this.isLoading = true),
                tap(progress => {
                    const status = progress === ActionProgress.complete || progress === ActionProgress.cancelled;
                    if (this.statusControl.value !== status) {
                        this.statusControl.setValue(status, { emitEvent: false });
                    }
                }),
                debounceTime(50),
                concatMap(this.updateActionProgress),
            ).subscribe(() => this.isLoading = false)
        );
        this.subscriptions.add(
            this.statusControl.valueChanges.pipe(
                map(status => {
                    if (status) {
                        return ActionProgress.complete;
                    }
                    if (this.isPastDue) {
                        return ActionProgress.overdue;
                    }
                    return ActionProgress.notStarted;
                })
            ).subscribe(progress => {
                this.progressControl.setValue(progress);
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private updateActionProgress = (progress: ActionProgress): Observable<GetActionDto | null> => {
        if (!this.action) return of(null);
        if (this.action.progress === progress) return of(null);
        const { company, team } = getDelegatedItemCompanyTeam(this.action);
        return this.actionsApi.updateStatus(
            company.id,
            team.id,
            this.action.id,
            { progress },
        ).pipe(
            tap(action => {
                if (this.action) {
                    this.action.status = action.status;
                    this.action.progress = action.progress;
                }
                this.actionStateService.notifyUpdate(action);
                this.updated.emit(action);
                this.form.reset(getActionFormState(action), { emitEvent: false });
                this.actionSubject.next(this.action);
                this.notificationService.success("actions.statusUpdated", undefined, undefined, true);
            }),
            catchError(() => {
                this.notificationService.errorUnexpected();
                this.form.reset(getActionFormState(this.action), { emitEvent: false });
                return of(null);
            })
        );
    };

    /* eslint-disable @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
    static ngAcceptInputType_action: GetActionDto;
    static ngAcceptInputType_disabled: BooleanInput;
    /* eslint-enable  @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
}
