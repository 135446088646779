import { DATE_PIPE_DEFAULT_OPTIONS, DATE_PIPE_DEFAULT_TIMEZONE, DatePipeConfig } from "@angular/common";
import { computed, Inject, LOCALE_ID, Optional, Pipe, PipeTransform } from "@angular/core";

import { TeamContext } from "~services/contexts";

import { getDateTimeFormat } from "../util/date-format-helpers";
import { BaseDatePipe, DateInput } from "./base-date-pipe";

@Pipe({
    name: "dateTimeFormat",
    pure: false,
    standalone: false,
})
export class DateTimeFormatPipe extends BaseDatePipe implements PipeTransform {

    private readonly dateTimeFormat = computed(() => getDateTimeFormat(this.teamContext.company()));

    constructor(
        private readonly teamContext: TeamContext,
        @Inject(LOCALE_ID) locale: string,
        @Inject(DATE_PIPE_DEFAULT_TIMEZONE) @Optional() defaultTimezone?: string | null,
        @Inject(DATE_PIPE_DEFAULT_OPTIONS) @Optional() defaultOptions?: DatePipeConfig | null | undefined,
    ) {
        super(locale, defaultTimezone, defaultOptions);
    }

    transform(value: DateInput, timezone?: string, locale?: string): string;
    transform(value: null | undefined, timezone?: string, locale?: string): null;
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    transform(value: DateInput | null | undefined, timezone?: string, locale?: string): string | null;
    transform(value: DateInput | null | undefined, timezone?: string, locale?: string): string | null {
        return super.transform(value, this.dateTimeFormat(), timezone, locale);
    }
}
