import { Component, Input } from "@angular/core";

type Size = "regular" | "small";
type SizeToCssMapping = {
    [K in Size]: string;
};

@Component({
    selector: "app-completion-indicator",
    templateUrl: "./completion-indicator.component.html",
    styleUrls: ["./completion-indicator.component.scss"],
    standalone: false,
})
export class CompletionIndicatorComponent {
    @Input() completed = false;
    @Input() size: Size = "regular";

    sizeToCssClassName: SizeToCssMapping = {
        regular: "regularSize",
        small: "smallSize"
    };
}
