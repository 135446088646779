import { trigger } from "@angular/animations";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, FormGroupDirective } from "@angular/forms";
import { UpdateCommentFeedItemDto } from "@api";
import { TranslateService } from "@ngx-translate/core";
import { distinctUntilChanged, map, Subscription } from "rxjs";

import { areFeedsEqual, FeedScope } from "~feed/services";
import { NotificationService } from "~services/notification.service";
import { fadeInAnimationBuilder } from "~shared/util/animations";

import { BaseAddFeedItemWithInputDirective } from "../base-add-feed-item.directive";

@Component({
    selector: "app-add-comment",
    templateUrl: "./add-comment.component.html",
    styleUrls: ["./add-comment.component.scss"],
    animations: [
        trigger("fadeIn", fadeInAnimationBuilder()),
    ],
    standalone: false,
})
export class AddCommentComponent extends BaseAddFeedItemWithInputDirective implements OnInit, OnDestroy {

    @ViewChild(FormGroupDirective) formDirective?: FormGroupDirective;

    readonly commentControl = new FormControl<string | null>(null);

    readonly form = new FormGroup({
        comment: this.commentControl,
    });

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly notificationService: NotificationService,
        private readonly feedScope: FeedScope,
        translate: TranslateService,
    ) {
        super(translate);
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.feedScope.adapter$.pipe(
                map(adapter => adapter?.reference ?? null),
                distinctUntilChanged(areFeedsEqual),
            ).subscribe(() => this.clearForm()),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    addComment = () => {
        if (!this.form.valid || !this.commentControl.value || !this.feedScope.adapter) return;
        const commentDto: UpdateCommentFeedItemDto = {
            type: "updateCommentFeedItem",
            text: this.commentControl.value
        };
        this.form.disable();
        this.feedScope.adapter.addFeedItem(commentDto, this.context?.key).subscribe({
            next: (item) => {
                this.form.enable();
                this.clearForm();
                this.itemAdded.emit(item);
            },
            error: () => {
                this.notificationService.errorUnexpected();
                this.form.enable();
            }
        });
    };

    private clearForm = () => {
        this.form.reset();
        this.formDirective?.resetForm();
    };
}
