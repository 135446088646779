import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ScheduledMeetingInstanceDto, WeeklyMeetingsApi } from "@api";
import * as moment from "moment";

import { NotificationService } from "~services/notification.service";
import { toFiscalQuarter } from "~shared/commonfunctions";
import { ButtonState } from "~shared/components/status-button/status-button.component";
import { DURATION_FORMAT } from "~shared/util/constants";
import { timeGreaterThanControl } from "~shared/util/custom-validators";
import { recursivelyUpdateValueAndValidity } from "~shared/util/util";

interface RescheduleMeetingDialogData {
    instance: ScheduledMeetingInstanceDto;
}

@Component({
    selector: "app-reschedule-meeting-dialog",
    templateUrl: "./reschedule-meeting-dialog.component.html",
    styleUrls: ["./reschedule-meeting-dialog.component.scss"],
    standalone: false,
})
export class RescheduleMeetingDialogComponent {

    buttonState: ButtonState;

    readonly reasonControl = new FormControl<string | null>(null, [Validators.required, Validators.maxLength(500)]);
    readonly dateControl = new FormControl<moment.Moment | null>(null, [Validators.required]);
    readonly startTimeControl = new FormControl<string | null>(null, [Validators.required]);
    readonly endTimeControl = new FormControl<string | null>(null, [Validators.required, timeGreaterThanControl("startTime")]);

    readonly form = new FormGroup({
        reason: this.reasonControl,
        date: this.dateControl,
        startTime: this.startTimeControl,
        endTime: this.endTimeControl,
    });

    readonly weekStartDate: moment.Moment;
    readonly weekEndDate: moment.Moment;

    private readonly instance: ScheduledMeetingInstanceDto;

    constructor(
        private readonly weeklyMeetingsApi: WeeklyMeetingsApi,
        private readonly notificationService: NotificationService,
        private readonly dialogRef: MatDialogRef<RescheduleMeetingDialogComponent, ScheduledMeetingInstanceDto>,
        @Inject(MAT_DIALOG_DATA) data: RescheduleMeetingDialogData) {

        this.instance = data.instance;

        const startDateTime = moment.utc(this.instance.schedule.startLocal);

        this.dateControl.setValue(startDateTime.clone().startOf("day"));
        this.startTimeControl.setValue(startDateTime.format(DURATION_FORMAT));
        this.endTimeControl.setValue(moment.utc(this.instance.schedule.endLocal).format(DURATION_FORMAT));

        this.weekStartDate = startDateTime.clone().startOf("isoWeek");
        this.weekEndDate = startDateTime.clone().endOf("isoWeek");

        this.dateControl.addValidators([
            Validators.min(this.weekStartDate.valueOf()),
            Validators.max(this.weekEndDate.valueOf()),
        ]);
    }

    static open(dialog: MatDialog, instance: ScheduledMeetingInstanceDto) {
        return dialog.open<RescheduleMeetingDialogComponent, RescheduleMeetingDialogData, ScheduledMeetingInstanceDto>(
            RescheduleMeetingDialogComponent, {
            width: "600px",
            data: { instance },
        });
    }

    rescheduleMeeting = () => {
        if (this.buttonState) return;

        recursivelyUpdateValueAndValidity(this.form);
        if (!this.form.valid) return;

        const { reason, date, startTime, endTime } = this.form.value;
        if (!reason || !date || !startTime || !endTime) return;

        this.buttonState = "loading";
        const instance = this.instance;

        this.weeklyMeetingsApi.rescheduleMeeting(
            instance.meeting.company.id,
            instance.meeting.team.id,
            toFiscalQuarter({ financialYear: instance.financialYear, quarter: instance.planningPeriod }),
            instance.collectionPeriod,
            instance.id,
            {
                reason: reason,
                schedule: {
                    startLocal: date.clone().add(startTime).toISOString(),
                    endLocal: date.clone().add(endTime).toISOString(),
                    timeZone: instance.schedule.timeZone.value,
                },
            },
        ).subscribe({
            next: result => {
                this.buttonState = "success";
                setTimeout(() => this.dialogRef.close(result), 1000);
            },
            error: () => {
                this.buttonState = "error";
                setTimeout(() => {
                    this.buttonState = undefined;
                }, 2000);
                this.notificationService.errorUnexpected();
            },
        });
    };
}
