import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GoalRecordDetailDto, RequireNote } from "@api";
import { distinctUntilChanged, map, Observable, of, startWith, Subscription, switchMap } from "rxjs";

import { CustomStatusRepository } from "~repositories";
import { TeamContext } from "~services/contexts";
import { GoalStatus } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { NOTES_MAX_LENGTH } from "~shared/util/constants";
import { isCustomGoalStatusesEnabled, isGoalCancellationEnabled, isNoteEnforcementEnabled } from "~shared/util/feature-helper";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { valueAndChanges } from "~shared/util/util";

import {
    areStatusesEqual,
    augmentCustomStatuses, getStatusOptions, getUnderlyingStatus, IGoalStatus, StatusWithNotes
} from "../goal-status-shared";

export interface GoalStatusNotesData {
    record: GoalRecordDetailDto;
    status: IGoalStatus;
}

const getNotesValidators = (required: boolean): ValidatorFn[] => [
    ...(required ? [Validators.required] : []),
    Validators.maxLength(NOTES_MAX_LENGTH),
];

@Component({
    selector: "app-goal-status-notes-dialog",
    templateUrl: "./goal-status-notes-dialog.component.html",
    styleUrls: ["./goal-status-notes-dialog.component.scss"],
    standalone: false,
})
export class GoalStatusNotesDialogComponent extends WithDestroy() implements OnInit, OnDestroy {

    readonly maxNotesLength = NOTES_MAX_LENGTH;
    readonly areStatusesEqual = areStatusesEqual;

    readonly statusControl = new FormControl<IGoalStatus>(GoalStatus.updateRequired, { nonNullable: true });
    readonly notesControl = new FormControl<string | null>(null, getNotesValidators(false));

    readonly form = new FormGroup({
        status: this.statusControl,
        notes: this.notesControl,
    });

    readonly statuses$: Observable<IGoalStatus[]>;

    readonly record: Readonly<GoalRecordDetailDto>;

    get isComplete(): boolean {
        return this.statusInternal === GoalStatus.complete;
    }

    get isOnTarget(): boolean {
        return this.statusInternal === GoalStatus.onTarget;
    }

    get isCancelled(): boolean {
        return this.statusInternal === GoalStatus.cancelled;
    }

    get isOffTarget(): boolean {
        const status = this.statusInternal;
        return status === GoalStatus.updateRequired || status === GoalStatus.offTarget;
    }

    private get statusInternal() {
        return getUnderlyingStatus(this.statusControl.value);
    }

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly teamContext: TeamContext,
        private readonly customStatusRepository: CustomStatusRepository,
        private readonly dialogRef: MatDialogRef<GoalStatusNotesDialogComponent, StatusWithNotes>,
        @Inject(MAT_DIALOG_DATA) data: GoalStatusNotesData,
    ) {
        super();
        this.statuses$ = this.teamContext.companyTeam$.pipe(
            switchMap(ct => {
                const cancellationEnabled = isGoalCancellationEnabled(ct);
                if (!isCustomGoalStatusesEnabled(ct)) {
                    return of(getStatusOptions(cancellationEnabled));
                }
                return this.customStatusRepository.getGoalStatuses(this.record.company.id, this.record.team.id).pipe(
                    map(customStatuses => augmentCustomStatuses(customStatuses, cancellationEnabled)),
                    startWith([this.record.customStatus ?? this.record.status]),
                );
            }),
            shareReplayUntil(this.destroyed$),
        );

        this.record = data.record;

        this.statusControl.setValue(data.status);
        this.notesControl.setValue(data.record.notes ?? null);
    }

    static open(dialog: MatDialog, record: GoalRecordDetailDto, status: IGoalStatus) {
        return dialog.open<GoalStatusNotesDialogComponent, GoalStatusNotesData, StatusWithNotes>(
            GoalStatusNotesDialogComponent,
            {
                width: "500px",
                data: { record, status },
            });
    }

    ngOnInit(): void {
        this.subscriptions.add(this.teamContext.companyTeam$.pipe(
            map(isNoteEnforcementEnabled),
            map(enabled => enabled ? this.record.requireNote : RequireNote.never),
            distinctUntilChanged(),
            switchMap(requireNote => {
                switch (requireNote) {
                    case RequireNote.whenOffTarget:
                        return valueAndChanges(this.statusControl).pipe(
                            map(status => {
                                const underlyingStatus = getUnderlyingStatus(status);
                                return underlyingStatus === GoalStatus.offTarget || underlyingStatus === GoalStatus.cancelled;
                            }),
                            distinctUntilChanged(),
                        );
                    case RequireNote.always:
                        return of(true);
                    case RequireNote.never:
                    default:
                        return of(false);
                }
            }),
        ).subscribe(noteRequired => {
            this.notesControl.setValidators(getNotesValidators(noteRequired));
            this.notesControl.updateValueAndValidity();
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    isCurrentStatusAvailable = (statuses: IGoalStatus[] | null): boolean => {
        if (!statuses) return false;
        const status = this.statusControl.value;
        return statuses.some(s => areStatusesEqual(status, s));
    };

    save = () => {
        if (!this.form.valid) return;

        this.dialogRef.close({
            status: this.statusControl.value,
            notes: this.notesControl.value ?? "",
        });
    };
}
