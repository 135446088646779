import { ChangeDetectionStrategy, Component, HostBinding, input, ViewEncapsulation } from "@angular/core";
import { PlanningStatus } from "@api";

import { getPlanningStatusNameKey } from "~shared/util/translation-helper";

@Component({
    selector: "wf-planning-status",
    templateUrl: "./planning-status.component.html",
    styleUrl: "./planning-status.component.scss",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: "wf-planning-status",
    },
    standalone: false,
})
export class PlanningStatusComponent {
    readonly status = input.required<PlanningStatus>();

    getPlanningStatusNameKey = getPlanningStatusNameKey;

    @HostBinding("class.wf-planning-status-draft")
    get isDraft() {
        return this.status() === PlanningStatus.draft;
    }

    @HostBinding("class.wf-planning-status-locked")
    get isLocked() {
        return this.status() === PlanningStatus.locked;
    }
}
