import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GetNewsItemDto } from "@api";

import { DeleteNewsItemDialogComponent, EditNewsItemDialogComponent } from "~shared/dialogs";

@Component({
    selector: "app-edit-news-item-menu",
    templateUrl: "./edit-news-item-menu.component.html",
    styleUrls: ["./edit-news-item-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class EditNewsItemMenuComponent {

    @Input() newsItem?: GetNewsItemDto;
    @Input() disabled = false;

    @Output() updated = new EventEmitter<GetNewsItemDto>();
    @Output() deleted = new EventEmitter<void>();

    get isTeamOwned(): boolean {
        return !!this.newsItem && this.newsItem.currentTeam.id === this.newsItem.team.id;
    }

    constructor(
        private readonly dialog: MatDialog,
    ) { }

    edit = () => {
        if (!this.newsItem || this.disabled) return;
        EditNewsItemDialogComponent.openForEdit(this.dialog, this.newsItem)
            .afterClosed().subscribe(res => res && this.updated.emit(res));
    };

    view = () => {
        if (!this.newsItem) return;
        EditNewsItemDialogComponent.openForEdit(this.dialog, this.newsItem, /* readonly: */ this.disabled)
            .afterClosed().subscribe(res => res && this.updated.emit(res));
    };

    delete = () => {
        if (!this.newsItem || this.disabled) return;
        DeleteNewsItemDialogComponent.open(this.dialog, this.newsItem)
            .afterClosed().subscribe(res => res && this.deleted.emit());
    };

}
