/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { FeedPartitionContext, feedPartitionContextDescription, feedPartitionKeyHierarchy, partitionKeysEqual } from "../../services";

@Component({
    selector: "app-feed-partition-context-menu",
    templateUrl: "./feed-partition-context-menu.component.html",
    styleUrls: ["./feed-partition-context-menu.component.scss"],
    standalone: false,
})
export class FeedPartitionContextMenuComponent {

    get context() {
        return this.contextInternal;
    }

    @Input() set context(newContext: FeedPartitionContext) {
        if (!partitionKeysEqual(this.contextInternal?.key, newContext.key)) {
            this.contextInternal = this.selectedContext = newContext;
            this.selectableContexts = [[], ...feedPartitionKeyHierarchy(newContext.key)].map(x => ({ key: x, type: newContext.type }));
        }
    }

    @Input() selectedContext?: FeedPartitionContext;
    @Output() readonly selectedContextChanged = new EventEmitter<FeedPartitionContext>();

    selectableContexts: FeedPartitionContext[] = [];

    private contextInternal!: FeedPartitionContext;

    constructor(private readonly translate: TranslateService) {
    }

    setSelectedContext = (context: FeedPartitionContext) => {
        this.selectedContext = context;
        this.selectedContextChanged.emit(context);
    };

    contextToDescription = (context?: FeedPartitionContext) => feedPartitionContextDescription(this.translate, context);
}
