import { Injectable } from "@angular/core";
import { GlobalFeaturesApi } from "@api";
import { Observable, Subscription } from "rxjs";
import { tap } from "rxjs/operators";

import { GlobalFeatures } from "~shared/enums";
import { retryWithDelay } from "~shared/util/caching";
import { isFeatureEnabled } from "~shared/util/feature-helper";

import { SettingsService } from "./settings.service";

@Injectable({
    providedIn: "root"
})
export class GlobalFeatureFlagService {

    get registrationEnabled() {
        return this.isFeatureEnabled(GlobalFeatures.registration);
    }

    private loadSubscription?: Subscription;
    private refreshSubscription?: Subscription;

    constructor(
        private readonly settingsService: SettingsService,
        private readonly globalFeaturesApi: GlobalFeaturesApi) {
    }

    ensureConfigLoaded = (): Promise<void> => new Promise((resolve) => {
        try {
            if (this.settingsService.globalFeatures) resolve();
        } catch {
            // Handle corrupted features
            this.settingsService.globalFeatures = null;
        }
        this.loadSubscription?.unsubscribe();
        this.loadSubscription = this.loadConfig().pipe(
            tap(() => resolve(), () => resolve()),
            retryWithDelay()
        ).subscribe();
    });

    refresh = () => {
        this.refreshSubscription?.unsubscribe();
        this.refreshSubscription = this.loadConfig().pipe(retryWithDelay()).subscribe();
    };

    private loadConfig = (): Observable<unknown> =>
        this.globalFeaturesApi.getGlobalFeatureFlags()
            .pipe(
                tap(features => this.settingsService.globalFeatures = features)
            );

    private isFeatureEnabled = (featureName: string): boolean =>
        isFeatureEnabled(featureName, this.settingsService.globalFeatures ?? []);
}

