import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { GlobalItemScheduleDto } from "@api";

interface ScheduledItem {
    id: string;
    financialYear: number;
    planningPeriod: number;
}

const scheduleEquals = (a: ScheduledItem, b: ScheduledItem): boolean =>
    a.id === b.id &&
    a.financialYear === b.financialYear &&
    a.planningPeriod === b.planningPeriod;

@Component({
    selector: "app-schedule-navigator",
    templateUrl: "./schedule-navigator.component.html",
    styleUrls: ["./schedule-navigator.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ScheduleNavigatorComponent {

    @Input() item: ScheduledItem | null = null;

    @Input() set schedule(value: GlobalItemScheduleDto[] | null) {
        this.scheduleInternal = value ?? [];
    }

    get schedule(): GlobalItemScheduleDto[] {
        return this.scheduleInternal;
    }

    @Output() selected = new EventEmitter<GlobalItemScheduleDto>();

    readonly scheduleEquals = scheduleEquals;

    private scheduleInternal: GlobalItemScheduleDto[] = [];

    selectSchedule = (schedule: GlobalItemScheduleDto) => this.selected.emit(schedule);

}
