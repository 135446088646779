import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { GetCustomGoalStatusDto } from "@api";

import { GoalStatus } from "~shared/enums";
import { getGoalStatusNameKey } from "~shared/util/translation-helper";

import { getUnderlyingStatus, isCustomStatus } from "../goal-status-update/goal-status-shared";

@Component({
    selector: "app-goal-status",
    templateUrl: "./goal-status.component.html",
    styleUrls: ["./goal-status.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: "wf-goal-status wf-status-indicator",
    },
    standalone: false,
})
export class GoalStatusComponent {
    @Input() status: GoalStatus | GetCustomGoalStatusDto = GoalStatus.updateRequired;

    @Input() nameOverride: string | null | undefined;

    readonly getGoalStatusNameKey = getGoalStatusNameKey;

    get underlyingStatus(): GoalStatus {
        return getUnderlyingStatus(this.status);
    }

    get customStatusName(): string | null {
        return this.nameOverride ?? (isCustomStatus(this.status) ? this.status.name : null);
    }

    @HostBinding("class")
    get className() {
        switch (this.underlyingStatus) {
            case GoalStatus.updateRequired:
                return "wf-status-indicator-outline wf-status-indicator-failure";
            case GoalStatus.offTarget:
                return "wf-status-indicator-shaded wf-status-indicator-failure";
            case GoalStatus.onTarget:
                return "wf-status-indicator-shaded wf-status-indicator-success";
            case GoalStatus.complete:
                return "wf-status-indicator-filled wf-status-indicator-success";
            case GoalStatus.cancelled:
                return "wf-status-indicator-filled";
            default:
                return undefined;
        }
    }

}
