import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { EntityReferenceDetailsDto } from "@api";
import { BehaviorSubject, map, Observable, of, switchMap } from "rxjs";

import { AccessService } from "~services/access.service";
import { TeamContext } from "~services/contexts";
import { LinkedItemViewer } from "~services/linked-item-viewer.service";
import { WithDestroy } from "~shared/mixins";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { getEntityTypeNameKey } from "~shared/util/translation-helper";

const MAX_HEADING_LENGTH = 100;

@Component({
    selector: "app-origin-table-button",
    templateUrl: "./origin-table-button.component.html",
    styleUrls: ["./origin-table-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class OriginTableButtonComponent extends WithDestroy() {

    @Input({ required: true }) set origin(value: EntityReferenceDetailsDto | null) {
        this.originSubject.next(value);
        this.calculateHeading();
    }

    get origin(): EntityReferenceDetailsDto | null {
        return this.originSubject.value;
    }

    get originNameKey(): string {
        if (!this.origin) return "";
        return getEntityTypeNameKey(this.origin.type, this.teamContext.settings.useAucbgMenus());
    }

    get canView(): boolean {
        return !!this.origin && this.linkedItemViewer.canView(this.origin);
    }

    heading = "";
    hasMore = false;

    readonly hasAccess$: Observable<boolean>;

    private readonly originSubject = new BehaviorSubject<EntityReferenceDetailsDto | null>(null);

    constructor(
        private readonly teamContext: TeamContext,
        private readonly accessService: AccessService,
        private readonly linkedItemViewer: LinkedItemViewer,
    ) {
        super();

        this.hasAccess$ = this.originSubject.pipe(
            switchMap(origin => {
                if (!origin) return of(false);
                return this.accessService.getAccessState(origin.company.id, origin.team.id).pipe(
                    map(a => a.canRead),
                );
            }),
            shareReplayUntil(this.destroyed$),
        );
    }

    openOrigin = () => {
        const origin = this.origin;
        if (!origin || !this.linkedItemViewer.canView(origin)) return;
        this.linkedItemViewer.view(origin);
    };

    private calculateHeading = () => {
        if (!this.origin) {
            this.heading = "";
            this.hasMore = false;
            return;
        }

        const fullHeading = this.origin?.heading || this.origin?.description || "";
        const lines = fullHeading.split("\n", 2).filter(Boolean);
        const firstLine = lines[0] || "";
        const trimmedHeading = firstLine.substring(0, MAX_HEADING_LENGTH).trim();
        const hasMore = lines.length > 1 || firstLine.length > trimmedHeading.length;

        this.heading = trimmedHeading;
        this.hasMore = hasMore;
    };

    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention
    static ngAcceptInputType_origin: EntityReferenceDetailsDto;
}
