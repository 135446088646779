import { Directive, InjectionToken } from "@angular/core";

export const HOMEPAGE_TITLE_ACTIONS = new InjectionToken<HomepageTitleActionsDirective>("HomepageTitleActions");

@Directive({
    selector: "[appHomepageTitleActions]",
    providers: [{ provide: HOMEPAGE_TITLE_ACTIONS, useExisting: HomepageTitleActionsDirective }],
    standalone: false,
})
export class HomepageTitleActionsDirective { }
