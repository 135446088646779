import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { ReportRecordDetailDto } from "@api";

import { sortNumber } from "~shared/util/sorters";
import { trackByIdAndWeek } from "~shared/util/table-helper";

declare type ReportRecordColumn = "week" | "updated" | "reports";

const sortByWeek = (records: ReportRecordDetailDto[]) =>
    records.sort(sortNumber.ascending(r => r.week));

@Component({
    selector: "app-report-records-table",
    templateUrl: "./report-records-table.component.html",
    styleUrls: ["./report-records-table.component.scss"],
    standalone: false,
})
export class ReportRecordsTableComponent {

    @Input() set records(value: ReportRecordDetailDto[] | null) {
        this.dataSource.data = sortByWeek(value ?? []);
    }

    get records(): ReportRecordDetailDto[] {
        return this.dataSource.data;
    }

    @Input() selectedWeek: number | null | undefined;

    @Output() recordUpdated = new EventEmitter<ReportRecordDetailDto>();

    readonly dataSource = new MatTableDataSource<ReportRecordDetailDto>();
    readonly displayedColumns: ReportRecordColumn[] = ["week", "updated", "reports"];

    readonly trackByIdAndWeek = trackByIdAndWeek;

}
