/* eslint-disable max-classes-per-file */
import { trigger } from "@angular/animations";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { booleanAttribute, Component, ContentChild, Directive, Input, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { GetActionDto, PlanReportsApi, ReportRecordDetailDto, SimpleUserDto } from "@api";
import { BehaviorSubject, combineLatest, map, Observable } from "rxjs";

import { ReportHomepageDialogComponent } from "~homepage";
import { ActionStateService } from "~services/state";
import { toFiscalQuarter } from "~shared/commonfunctions";
import { EntityType } from "~shared/enums";
import { expandOnEnterAnimation } from "~shared/util/animations";
import { mergeChildUpdatesFrom } from "~shared/util/children-state-helper";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { ExpansionControllerWithLoader } from "~shared/util/expansion-controller";
import { getSortablePeriodString } from "~shared/util/period-helper";
import { ReportColumn } from "~shared/util/report-columns";
import { defaultReportsFilterPredicate } from "~shared/util/table-filtering";
import { trackByIdAndWeek } from "~shared/util/table-helper";
import { getUserName } from "~shared/util/user-helper";

import { GenericArrayTable } from "../generic-table/generic-table.directive";
import { ExtraTableOptionsContext } from "../generic-table/generic-table-shared";

const DEFAULT_COLUMNS: ReportColumn[] = ["description", "owner", "updater", "reports"];

declare type ExtendedReportColumn = ReportColumn |
    "expand" | "actionsCount" | "updated" | "actionDiscuss" | "options";

@Directive({
    selector: "[appExtraReportOptions]",
    standalone: false,
})
export class ExtraReportOptionsDirective {
    static ngTemplateContextGuard(dir: ExtraReportOptionsDirective, ctx: unknown): ctx is ExtraTableOptionsContext<ReportRecordDetailDto> {
        return true;
    }
}

@Component({
    selector: "app-generic-reports-table",
    templateUrl: "./generic-reports-table.component.html",
    styleUrls: ["./generic-reports-table.component.scss"],
    animations: [
        trigger("detailExpand", expandOnEnterAnimation),
    ],
    standalone: false,
})
export class GenericReportsTableComponent extends GenericArrayTable<ReportRecordDetailDto, ReportColumn> {

    @Input() set highlightUpdateRequired(value: BooleanInput) {
        this.highlightUpdateRequiredInternal = coerceBooleanProperty(value);
    }

    get highlightUpdateRequired(): boolean {
        return this.highlightUpdateRequiredInternal;
    }

    @Input() set showChildActions(value: BooleanInput) {
        this.showChildActionsSubject.next(coerceBooleanProperty(value));
    }

    get showChildActions(): boolean {
        return this.showChildActionsSubject.value;
    }

    @Input() set showUpdated(value: BooleanInput) {
        this.showUpdatedSubject.next(coerceBooleanProperty(value));
    }

    get showUpdated(): boolean {
        return this.showUpdatedSubject.value;
    }

    @Input({ transform: booleanAttribute }) fromMeeting = false;

    @ContentChild(ExtraReportOptionsDirective, { read: TemplateRef, static: false })
    extraOptionsTemplate: TemplateRef<ExtraTableOptionsContext<ReportRecordDetailDto>> | null = null;

    @ViewChild(MatSort) set matSort(value: MatSort) {
        this.dataSource.sort = value;
    }

    readonly displayedColumns$: Observable<ExtendedReportColumn[]>;

    readonly getUserName = getUserName;
    readonly trackByIdAndWeek = trackByIdAndWeek;

    get limitExceeded(): boolean {
        return this.dataSource.data.some(n => n.canEdit === false);
    }

    get canExpandAll(): boolean {
        return !!this.expandableReports.length;
    }

    get areAllExpanded(): boolean {
        return this.expandableReports.every(this.childActionsController.isExpanded);
    }

    private readonly childActionsController = new ExpansionControllerWithLoader<ReportRecordDetailDto, GetActionDto>(
        r => r.id,
        r => {
            const { company, team } = getDelegatedItemCompanyTeam(r);
            return this.planReportsApi.getActionsForReport(
                company.id,
                team.id,
                toFiscalQuarter({ financialYear: r.financialYear, quarter: r.planningPeriod }),
                r.id,
            ).pipe(
                mergeChildUpdatesFrom(this.actionStateService.events$, r.id, EntityType.report),
            );
        },
        this.destroyed$,
    );

    private get expandableReports() {
        return this.dataSource.data.filter(r => r.actionsCount);
    }

    private highlightUpdateRequiredInternal = false;
    private readonly showChildActionsSubject = new BehaviorSubject<boolean>(true);
    private readonly showUpdatedSubject = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly planReportsApi: PlanReportsApi,
        private readonly actionStateService: ActionStateService,
        private readonly dialog: MatDialog,
    ) {
        super();

        this.dataSource.filterPredicate = defaultReportsFilterPredicate;

        this.displayedColumns$ = combineLatest({
            columns: this.columnsSubject,
            showChildActions: this.showChildActionsSubject,
            showUpdated: this.showUpdatedSubject,
        }).pipe(
            map(({ columns, showChildActions, showUpdated, }) => [
                ...(showChildActions ? ["expand", "actionsCount"] as const : []),
                ...(showUpdated ? ["updated"] as const : []),
                ...columns,
                "actionDiscuss",
                "options",
            ]),
        );

        this.columns = DEFAULT_COLUMNS;
        this.defaultSort = "description";
    }

    applyOwnerFilter = (user: SimpleUserDto | null) => this.dataSource.filter = user?.userId ?? "";

    viewReport = (report: ReportRecordDetailDto, focusFeed = false) =>
        ReportHomepageDialogComponent.open(this.dialog, report, { focusFeed })
            .componentInstance.events$.subscribe(event => this.updated.emit(event));

    openFeed = (report: ReportRecordDetailDto) => this.viewReport(report, /* focusFeed: */ true);

    reportUpdated = (report: ReportRecordDetailDto) =>
        this.updated.emit({ type: "updated", item: report });

    discussionAdded = (report: ReportRecordDetailDto) => {
        this.updated.emit({ type: "child", item: report, childType: "discussion" });
    };

    actionUpdated = (report: ReportRecordDetailDto) => {
        this.updated.emit({ type: "child", item: report, childType: "action" });
    };

    isExpanded = (report: ReportRecordDetailDto) => this.childActionsController.isExpanded(report);

    expandAll = () => this.expandableReports.forEach(this.childActionsController.expand);
    collapseAll = () => this.expandableReports.forEach(this.childActionsController.collapse);

    expand = (report: ReportRecordDetailDto) => this.childActionsController.expand(report);
    collapse = (report: ReportRecordDetailDto) => this.childActionsController.collapse(report);

    getChildActions = (report: ReportRecordDetailDto) => this.childActionsController.getChildren(report);

    protected sortingDataAccessor = (data: ReportRecordDetailDto, sortHeaderId: ReportColumn): string | number => {
        switch (sortHeaderId) {
            case "description": return data.description;
            case "week": return getSortablePeriodString(data);
            case "owner": return getUserName(data.owner);
            case "updater": return getUserName(data.updater);
            case "team": return data.team.name;
            case "reportSummary":
            case "reports": return data.reportIsSet ? 1 : 0;
            case "department": return data.department?.name ?? "";
            case "category": return data.category?.description ?? "";
            case "subCategory": return data.category?.subCategory?.description ?? "";
        }
    };

    protected getDefaultColumns = () => DEFAULT_COLUMNS;
}
