import { animate, state, style, transition, trigger } from "@angular/animations";
import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { IsActiveMatchOptions } from "@angular/router";

export type MenuType = "link" | "menu" | "separator";

interface IMenuBase {
    readonly id: string;
    readonly name: string;
    readonly icon: string;
    readonly isSvgIcon?: undefined | boolean;
    readonly show: boolean;
}

interface IMenuLink extends IMenuBase {
    readonly type: "link";
    readonly route: string | string[];
    readonly routeMatchOptions?: IsActiveMatchOptions;
    readonly teaser?: boolean;
}

interface IMenuSubMenu extends IMenuBase {
    readonly type: "menu";
    readonly children: IMenuItem[];
}

interface IMenuSeparator {
    readonly type: "separator";
    readonly show: boolean;
}

export type IMenuItem = IMenuLink | IMenuSubMenu | IMenuSeparator;

const ID_PREFIX = "wf-nav-";

const DEFAULT_ROUTE_MATCH: IsActiveMatchOptions =
    { paths: "exact", queryParams: "exact", fragment: "ignored", matrixParams: "ignored" };

@Component({
    selector: "app-menu-list-item",
    templateUrl: "./menu-list-item.component.html",
    styleUrls: ["./menu-list-item.component.scss"],
    animations: [
        trigger("indicatorRotate", [
            state("collapsed", style({ transform: "rotate(0deg)" })),
            state("expanded", style({ transform: "rotate(180deg)" })),
            transition("expanded <=> collapsed",
                animate("225ms cubic-bezier(0.4,0.0,0.2,1)")
            ),
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class MenuListItemComponent {
    @Input({ required: true }) item: IMenuItem | null = null;

    expanded = false;

    get shouldShow(): boolean {
        if (!this.item) return false;
        if (this.item && this.item.type === "menu") {
            // An empty menu should be hidden.
            if (!this.item.children.length) {
                return false;
            }
        }
        return this.item.show;
    }

    @HostBinding("attr.id")
    get id(): string | undefined {
        if (!this.item || this.item.type === "separator") return undefined;
        return ID_PREFIX + this.item.id;
    }

    get name(): string {
        if (!this.item || this.item.type === "separator") return "";
        return this.item.name;
    }

    get icon(): string {
        if (this.isSvgIcon) return "";
        return this.iconInternal;
    }

    get svgIcon(): string | undefined {
        if (!this.isSvgIcon) return undefined;
        return this.iconInternal;
    }

    get isLink() {
        return this.item?.type === "link";
    }

    get isMenu() {
        return this.item?.type === "menu";
    }

    get isSeparator() {
        return this.item?.type === "separator";
    }

    get subMenuItem(): IMenuSubMenu | null {
        if (!this.isMenu) return null;
        return this.item as IMenuSubMenu;
    }

    get linkItem(): IMenuLink | null {
        if (!this.isLink) return null;
        return this.item as IMenuLink;
    }

    get routeMatchOptions(): IsActiveMatchOptions {
        if (!this.item || this.item.type !== "link") return DEFAULT_ROUTE_MATCH;
        return this.item.routeMatchOptions ?? DEFAULT_ROUTE_MATCH;
    }

    private get iconInternal(): string {
        if (!this.item || this.item.type === "separator") return "";
        return this.item.icon;
    }

    private get isSvgIcon(): boolean {
        if (!this.item || this.item.type === "separator" || !this.item.isSvgIcon) return false;
        return this.item.isSvgIcon;
    }

    toggleExpand() {
        if (!this.item || this.item.type !== "menu") return;
        if (this.item.children.length) {
            this.expanded = !this.expanded;
        }
    }

    trackMenuItem = (_: number, item: IMenuItem) => item.type === "separator" ? item : item.id;
}
