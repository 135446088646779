import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DiscussionAndSolutionDto, DiscussionsApi } from "@api";

import { NotificationService } from "~services/notification.service";
import { DiscussionStateService } from "~services/state";
import { ButtonState } from "~shared/components/status-button/status-button.component";

@Component({
    selector: "app-delete-discussion-dialog",
    templateUrl: "./delete-discussion-dialog.component.html",
    styleUrls: ["./delete-discussion-dialog.component.scss"],
    standalone: false,
})
export class DeleteDiscussionDialogComponent {

    buttonState: ButtonState;

    get heading(): string {
        return this.discussion.heading;
    }

    constructor(
        private readonly discussionsApi: DiscussionsApi,
        private readonly discussionStateService: DiscussionStateService,
        private readonly notificationService: NotificationService,
        private readonly dialogRef: MatDialogRef<DeleteDiscussionDialogComponent, boolean>,
        @Inject(MAT_DIALOG_DATA) private readonly discussion: DiscussionAndSolutionDto,
    ) { }

    static open(dialog: MatDialog, discussion: DiscussionAndSolutionDto) {
        return dialog.open<DeleteDiscussionDialogComponent, DiscussionAndSolutionDto, boolean>(
            DeleteDiscussionDialogComponent, {
            width: "500px",
            data: discussion,
        });
    }

    delete = () => {
        if (this.buttonState) return;

        this.buttonState = "loading";

        this.discussionsApi.deleteDiscussion(
            this.discussion.company.id,
            this.discussion.team.id,
            this.discussion.id,
        ).subscribe({
            next: () => {
                this.buttonState = "success";
                this.discussionStateService.notifyDelete({
                    companyId: this.discussion.company.id,
                    teamId: this.discussion.team.id,
                    id: this.discussion.id,
                });
                setTimeout(() => {
                    this.dialogRef.close(true);
                }, 1000);
            },
            error: () => {
                this.buttonState = "error";
                setTimeout(() => {
                    this.buttonState = undefined;
                }, 2000);
                this.notificationService.errorUnexpected();
            },
        });
    };

}
