import { Directive, HostBinding, Input } from "@angular/core";

import { DiscussionStatus } from "~shared/enums";

@Directive({
    selector: "[appDiscussionStatusRow]",
    standalone: false,
})
export class DiscussionStatusRowDirective {

    @Input("appDiscussionStatusRow") status: DiscussionStatus = DiscussionStatus.new;

    @HostBinding("class.wf-discussion-referred")
    get isReferred(): boolean {
        return this.status === DiscussionStatus.referred;
    }

    @HostBinding("class.wf-discussion-rejected")
    get isRejected(): boolean {
        return this.status === DiscussionStatus.rejected;
    }

    @HostBinding("class.wf-discussion-approved")
    get isApproved(): boolean {
        return this.status === DiscussionStatus.approved;
    }

    @HostBinding("class.wf-discussion-resolved")
    get isResolved(): boolean {
        return this.status === DiscussionStatus.resolved;
    }

    constructor() { }

}
