import { Component } from "@angular/core";

@Component({
    selector: "app-fake-schedule-field",
    templateUrl: "./fake-schedule-field.component.html",
    styleUrls: ["./fake-schedule-field.component.scss"],
    standalone: false,
})
export class FakeScheduleFieldComponent {
}
