import { animate, state, style, transition, trigger } from "@angular/animations";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { defaultAnimationTiming } from "~shared/util/animations";

@Component({
    selector: "wf-expand-button",
    templateUrl: "./expand-button.component.html",
    styleUrls: ["./expand-button.component.scss"],
    animations: [
        trigger("indicatorRotate", [
            state("collapsed", style({ transform: "rotate(0deg)" })),
            state("expanded", style({ transform: "rotate(-180deg)" })),
            transition("expanded <=> collapsed",
                animate(defaultAnimationTiming)
            ),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ExpandButtonComponent {

    @Input() expanded = false;
    @Input() disabled = false;

    @Input() collapseTooltip: string | null | undefined;
    @Input() expandTooltip: string | null | undefined;

    @Output() expand = new EventEmitter<void>();
    @Output() collapse = new EventEmitter<void>();
}
