import { Component } from "@angular/core";

@Component({
    selector: "app-delegation-responsibility-help-indicator",
    templateUrl: "./delegation-responsibility-help-indicator.component.html",
    styleUrls: ["./delegation-responsibility-help-indicator.component.scss"],
    standalone: false,
})
export class DelegationResponsibilityHelpIndicatorComponent {

}
