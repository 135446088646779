import { HttpErrorResponse } from "@angular/common/http";
import { Component, HostBinding, Injector, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CloneApi, PaymentApi } from "@api";
import { map, Observable, of, switchMap } from "rxjs";

import { DialogCloneTemplateComponent } from "~/app/material-component/dialogs/dialog-clone-template/dialog-clone-template.component";
import { environment } from "~/environments/environment";
import { TeamContext, UserContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";
import { PartnerStripeService } from "~services/partner-stripe.service";
import { UserService } from "~services/user.service";
import { WithDestroy } from "~shared/mixins";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { getUserName } from "~shared/util/user-helper";

declare type UserMenuSize = "large" | "small";

@Component({
    selector: "app-user-menu",
    templateUrl: "./user-menu.component.html",
    styleUrls: ["./user-menu.component.scss"],
    standalone: false,
})
export class UserMenuComponent extends WithDestroy() {

    @Input() size: UserMenuSize = "large";

    @HostBinding("class.user-menu-large") get isLarge(): boolean {
        return this.size === "large";
    }

    @HostBinding("class.user-menu-small") get isSmall(): boolean {
        return this.size === "small";
    }

    readonly isCloneAvailable$: Observable<boolean>;
    readonly isPartnerStripeAccountAvailable$: Observable<boolean>;

    get demoServer() {
        return environment.demoServer;
    }

    get showTimeMachine() {
        return environment.timeMachine && (
            environment.demoServer || this.isSuperAdmin
        );
    }

    get fullName(): string | undefined {
        const currentUser = this.userContext.user();
        if (!currentUser) return undefined;
        return getUserName(currentUser);
    }

    get profilePictureUrl(): string | null {
        return this.userContext.user()?.profilePictureUrl || null;
    }

    get emailAddress(): string | undefined {
        return this.userContext.user()?.email;
    }

    get isSuperAdmin(): boolean {
        return this.userContext.isSuperAdmin();
    }

    get isPartnerAdmin(): boolean {
        return this.userContext.isPartnerAdmin();
    }

    get isPartner(): boolean {
        return this.userContext.isPartner();
    }

    get isBillingAdmin(): boolean {
        return this.userContext.isBillingAdmin();
    }

    get useNewBillingPage(): boolean {
        return this.teamContext.features.userPlanChangeEnabled();
    }

    get authAccountUrl(): string {
        return `${environment.auth.authority}/account`;
    }

    constructor(
        private readonly paymentApi: PaymentApi,
        private readonly cloneApi: CloneApi,
        private readonly partnerStripeService: PartnerStripeService,
        private readonly userService: UserService,
        private readonly userContext: UserContext,
        private readonly teamContext: TeamContext,
        private readonly notificationService: NotificationService,
        private readonly dialog: MatDialog,
        private readonly injector: Injector,
    ) {
        super();
        this.isCloneAvailable$ = this.teamContext.companyTeam$.pipe(
            switchMap(companyTeam => {
                if (!companyTeam || !this.demoServer || !this.userContext.isAdmin()) {
                    return of(null);
                }
                return this.cloneApi.isCloneAvailable(companyTeam.company.id);
            }),
            map(response => response?.available ?? false),
            shareReplayUntil(this.destroyed$),
        );

        this.isPartnerStripeAccountAvailable$ = this.partnerStripeService.status$.pipe(
            map(status => !!status?.status.initialised),
            shareReplayUntil(this.destroyed$),
        );
    }

    logout = () => this.userService.logout();

    billingDetails = () => {
        const currentCompany = this.teamContext.company();
        if (!currentCompany) return;

        this.paymentApi.getBillingPortalUrl(currentCompany.id)
            .subscribe({
                next: result => {
                    if (result.Url) document.location.href = result.Url;
                },
                error: (error) => {
                    let errorMessage: string;
                    if (error instanceof HttpErrorResponse && error.status === 404) {
                        errorMessage = "Billing details not found.";
                    } else {
                        errorMessage = "An unknown error occurred. Please try again later.";
                    }
                    this.notificationService.error(errorMessage);
                },
            });
    };

    /**
     * Determines whether the user's current company has a subscription to edit.
     */
    hasSubscription = (): boolean => {
        const company = this.teamContext.company();
        if (!company) return false;
        return company.hasSubscription && company.canManage;
    };

    openCloneTemplateDialog = () => {
        if (!this.demoServer) return;
        DialogCloneTemplateComponent.open(this.dialog, this.injector);
    };

    goToPartnerStripePortal = () => {
        const company = this.teamContext.company();
        if (!company) return;
        this.partnerStripeService.openDashboard(company.id);
    };

}
