import { Directive, forwardRef, HostListener } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { IUserOrEmail, UserEmailSelectorComponent } from "./user-email-selector.component";

export const USER_EMAIL_SELECTOR_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    useExisting: forwardRef(() => UserEmailSelectorValueAccessor),
    multi: true
};

/* eslint-disable @angular-eslint/directive-selector */ /* We are targeting existing angular form attributes */
/* eslint-disable @typescript-eslint/no-explicit-any */ /* The interface expects we will accept parameters of any type. */
@Directive({
    selector: "app-user-email-selector[formControlName],app-user-email-selector[formControl],app-user-email-selector[ngModel]",
    providers: [USER_EMAIL_SELECTOR_VALUE_ACCESSOR],
    standalone: false,
})
export class UserEmailSelectorValueAccessor implements ControlValueAccessor {

    private onChangedCallback?: (_: any) => void;
    private onTouchedCallback?: () => void;

    constructor(private host: UserEmailSelectorComponent) { }

    @HostListener("userChange", ["$event"]) onChange = (value: any) => {
        this.onChangedCallback?.(value);
    };

    @HostListener("touched") onTouched = () => {
        this.onTouchedCallback?.();
    };

    writeValue(obj: any): void {
        const data: IUserOrEmail = {
            email: obj?.email,
            user: obj?.user
        };
        this.host.setValue(data);
    }

    registerOnChange(fn: any): void {
        this.onChangedCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }
}
