import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
    ActionsV2Api, DiscussionAndSolutionDto, DiscussionsApi, EntityReferenceDto, GetActionDto, SolvedDiscussionDto
} from "@api";
import { concat, filter, map, Observable, of, switchMap, tap, toArray } from "rxjs";

import { DialogConfirmComponent } from "~/app/material-component/dialogs/dialog-confirm/dialog-confirm.component";
import { FeedAdapterBuilder, FeedScope, SimpleFeedScope } from "~feed";
import { NotificationService } from "~services/notification.service";
import { ActionStateService, DiscussionStateService } from "~services/state";
import {
    ActionPendingCreation, ActionPendingUpdate, SolutionAction, SolutionWithActions
} from "~shared/components/solution-form/solution-form.component";
import { ButtonState } from "~shared/components/status-button/status-button.component";
import { WfDialog } from "~shared/dialog";
import { DiscussionStatus, EntityType } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { retryWithDelay } from "~shared/util/caching";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { recursivelyUpdateValueAndValidity } from "~shared/util/util";

interface ISolutionDialogData {
    discussion: DiscussionAndSolutionDto;
    fromMeeting: boolean;
}

@Component({
    selector: "app-edit-solution-dialog",
    templateUrl: "./edit-solution-dialog.component.html",
    styleUrls: ["./edit-solution-dialog.component.scss"],
    providers: [
        SimpleFeedScope,
        {
            provide: FeedScope,
            useExisting: SimpleFeedScope,
        },
    ],
    standalone: false,
})
export class EditSolutionDialogComponent extends WithDestroy() {

    buttonState: ButtonState;

    readonly discussion: DiscussionAndSolutionDto;
    readonly fromMeeting: boolean;
    readonly actions$: Observable<GetActionDto[]>;

    readonly solutionControl = new FormControl<SolutionWithActions | null>(null);

    readonly form = new FormGroup({
        solution: this.solutionControl,
    });

    constructor(
        private readonly discussionsApi: DiscussionsApi,
        private readonly discussionStateService: DiscussionStateService,
        private readonly actionsApi: ActionsV2Api,
        private readonly actionStateService: ActionStateService,
        private readonly notificationService: NotificationService,
        private readonly simpleFeedScope: SimpleFeedScope,
        private readonly feedAdapterBuilder: FeedAdapterBuilder,
        private readonly dialog: MatDialog,
        private readonly dialogRef: MatDialogRef<EditSolutionDialogComponent, SolvedDiscussionDto>,
        @Inject(MAT_DIALOG_DATA) { discussion, fromMeeting }: ISolutionDialogData,
    ) {
        super();

        this.discussion = discussion;
        this.fromMeeting = fromMeeting;

        this.simpleFeedScope.adapter = this.feedAdapterBuilder.build(discussion);

        if (!this.fromMeeting) this.form.disable();

        this.actions$ = of(null).pipe(
            switchMap(() => {
                if (!discussion.solution) return of([]);
                return this.discussionsApi.getSolutionActions(
                    discussion.company.id,
                    discussion.team.id,
                    discussion.id,
                );
            }),
            retryWithDelay(),
            shareReplayUntil(this.destroyed$),
        );
    }

    static open(dialog: WfDialog, discussion: DiscussionAndSolutionDto, fromMeeting: boolean = false) {
        return dialog.open<EditSolutionDialogComponent, ISolutionDialogData, SolvedDiscussionDto>(
            EditSolutionDialogComponent,
            {
                maxWidth: "none",
                // In the case the dialog is being used to view the discussion, we want to auto-focus the header element.
                // This is because the first focusable element is often the action table heading, which is odd behavior.
                autoFocus: !fromMeeting ? "dialog" : "first-tabbable",
                data: { discussion, fromMeeting },
                showCloseButton: false, // The close button is emitted by the homepage scaffold
            }
        );
    }

    save = () => {
        recursivelyUpdateValueAndValidity(this.form);
        if (!this.fromMeeting || this.buttonState || !this.form.valid || !this.solutionControl.value) return;

        const { solution, actions, deletedActions } = this.solutionControl.value;

        of(null).pipe(
            switchMap(() => {
                // If the discussion has not been solved or no approving team is selected, skip to save
                if (!this.discussion.solution || !solution.approvingTeam) return of(true);
                // If the discussion is not approved/resolved, skip to save
                if (this.discussion.status !== DiscussionStatus.approved &&
                    this.discussion.status !== DiscussionStatus.resolved) return of(true);

                // If we get here, the discussion will be moved back to referred on save.
                // Warn the user before continuing.
                return DialogConfirmComponent.open(this.dialog, {
                    title: "Warning",
                    description: "discussions.approvals.editResolvedSolutionWarning",
                    confirm: { title: "Confirm" },
                    cancel: { title: "Cancel" },
                }).afterClosed().pipe(map(res => res === "confirm"));
            }),
            filter(res => !!res),
            tap(() => this.buttonState = "loading"),
            switchMap(() => this.discussionsApi.updateSolution(
                this.discussion.company.id,
                this.discussion.team.id,
                this.discussion.id,
                solution,
            )),
            switchMap(discussion => this.saveActions(discussion, actions, deletedActions)),
        ).subscribe({
            next: discussion => {
                this.buttonState = "success";
                this.discussionStateService.notifyUpdate(discussion);
                setTimeout(() => {
                    this.dialogRef.close(discussion);
                }, 1000);
            },
            error: () => {
                this.buttonState = "error";
                this.notificationService.errorUnexpected();
                setTimeout(() => {
                    this.buttonState = undefined;
                }, 2000);
            }
        });
    };

    private saveActions = (
        discussion: SolvedDiscussionDto,
        actions: SolutionAction[],
        deletedActions: GetActionDto[],
    ): Observable<SolvedDiscussionDto> => {
        const noActionRequired = discussion.solution.noActionRequired;
        // Even if no action is required, we may still need to delete some actions.
        if (noActionRequired && !deletedActions.length) return of(discussion);

        const added = noActionRequired ? [] : actions.filter((a): a is ActionPendingCreation => a.type === "create");
        const updated = noActionRequired ? [] : actions.filter((a): a is ActionPendingUpdate => a.type === "update");
        return concat(
            ...added.map(action => this.addPendingAction(action, discussion)),
            ...updated.map(action => this.updatePendingAction(action)),
            ...deletedActions.map(action => this.deletePendingAction(action)),
        ).pipe(
            toArray(),
            map(() => {
                const actionDelta = added.length - deletedActions.length;
                const openActionDelta = added.length - deletedActions.filter(a => !a.status).length;
                if (actionDelta === 0 && openActionDelta === 0) return discussion;
                return {
                    ...discussion,
                    solution: {
                        ...discussion.solution,
                        actionsCount: discussion.solution.actionsCount + actionDelta,
                        openActionsCount: discussion.solution.openActionsCount + openActionDelta,
                    },
                };
            }),
        );
    };

    private addPendingAction = (action: ActionPendingCreation, discussion: SolvedDiscussionDto): Observable<GetActionDto> => {
        action.action.origin = this.mapToOrigin(discussion);
        return this.actionsApi.addAction(
            action.companyId,
            action.teamId,
            action.action,
            /* sendEmail: */ false,
        ).pipe(
            tap(result => this.actionStateService.notifyAdd(result)),
        );
    };

    private updatePendingAction = (action: ActionPendingUpdate): Observable<GetActionDto> =>
        this.actionsApi.updateAction(
            action.originalAction.company.id,
            action.originalAction.team.id,
            action.originalAction.id,
            action.action,
            /* sendEmail: */ false,
        ).pipe(
            tap(result => this.actionStateService.notifyUpdate(result)),
        );

    private deletePendingAction = (action: GetActionDto): Observable<unknown> =>
        this.actionsApi.deleteAction(
            action.company.id,
            action.team.id,
            action.id,
        ).pipe(
            tap(() => this.actionStateService.notifyDelete({
                companyId: action.company.id,
                teamId: action.team.id,
                id: action.id,
            })),
        );

    private mapToOrigin = (discussion: SolvedDiscussionDto): EntityReferenceDto => ({
        type: EntityType.solution,
        companyId: discussion.company.id,
        teamId: discussion.team.id,
        id: discussion.solution.id,
    });
}
