import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CaptureMethod, GetNumberDto, NumberDeploymentMigrationApi, NumberRecordDetailDto, UpdateNumbersApi } from "@api";
import { catchError, EMPTY, map, Observable, of, switchMap, tap } from "rxjs";

import { NotificationService } from "~services/notification.service";
import { toFiscalQuarter } from "~shared/commonfunctions";
import { ButtonState } from "~shared/components/status-button/status-button.component";
import { WithDestroy } from "~shared/mixins";
import { NumberColumn } from "~shared/util/number-columns";
import { getRelevantWeek, sortNumberDefinition } from "~shared/util/number-helper";
import { shareReplayUntil } from "~shared/util/rx-operators";

@Component({
    selector: "app-deployment-migration-dialog",
    templateUrl: "./deployment-migration-dialog.component.html",
    styleUrl: "./deployment-migration-dialog.component.scss",
    standalone: false,
})
export class DeploymentMigrationDialogComponent extends WithDestroy() {

    buttonState: ButtonState;

    get description() {
        return this.number.description;
    }

    readonly sources$: Observable<NumberRecordDetailDto[]>;

    readonly sourceColumns: NumberColumn[] = ["description", "team", "owner", "updater", "resultSummary"];

    readonly eligible$: Observable<boolean>;

    get canMigrate() {
        return this.hasSources && this.eligible;
    }

    private hasSources = false;
    private eligible = false;

    private readonly number: GetNumberDto;

    constructor(
        private readonly api: NumberDeploymentMigrationApi,
        private readonly updateApi: UpdateNumbersApi,
        private readonly notificationService: NotificationService,
        private readonly dialogRef: MatDialogRef<DeploymentMigrationDialogComponent, GetNumberDto>,
        @Inject(MAT_DIALOG_DATA) number: GetNumberDto,
    ) {
        super();

        this.number = number;

        const relevantWeek = getRelevantWeek(number);

        this.sources$ = of(null).pipe(
            switchMap(() => {
                if (relevantWeek == null) {
                    this.hasSources = true;
                    return of([]);
                }
                return this.updateApi.getCalculationSources(
                    number.company.id,
                    number.team.id,
                    toFiscalQuarter({ financialYear: number.financialYear, quarter: number.planningPeriod }),
                    relevantWeek,
                    number.id,
                ).pipe(
                    tap(() => this.hasSources = true),
                    catchError(() => {
                        this.notificationService.errorUnexpected();
                        return of([]);
                    }),
                );
            }),
            map(sources => sources.sort(sortNumberDefinition.ascending())),
            shareReplayUntil(this.destroyed$),
        );

        this.eligible$ = of(null).pipe(
            switchMap(() => {
                if (number.captureMethod !== CaptureMethod.calculated) return of(false);
                return this.api.checkEligibility(
                    number.company.id,
                    number.team.id,
                    toFiscalQuarter({ financialYear: number.financialYear, quarter: number.planningPeriod }),
                    number.id,
                ).pipe(
                    map(response => response.eligible),
                    tap(eligible => this.eligible = eligible),
                    catchError(() => {
                        this.notificationService.errorUnexpected();
                        this.dialogRef.close();
                        return EMPTY;
                    }),
                );
            }),
            shareReplayUntil(this.destroyed$),
        );
    }

    static open(dialog: MatDialog, number: GetNumberDto) {
        return dialog.open<DeploymentMigrationDialogComponent, GetNumberDto, GetNumberDto>(DeploymentMigrationDialogComponent, {
            maxWidth: "1250px",
            autoFocus: "first-heading",
            data: number,
        });
    }

    migrate = () => {
        if (this.buttonState || !this.canMigrate) return;
        this.buttonState = "loading";

        this.api.migrate(
            this.number.company.id,
            this.number.team.id,
            toFiscalQuarter({ financialYear: this.number.financialYear, quarter: this.number.planningPeriod }),
            this.number.id,
        ).subscribe({
            next: (result) => {
                this.buttonState = "success";
                this.notificationService.success("numbers.deployment.migrationSuccess", undefined, undefined, true);
                setTimeout(() => {
                    this.dialogRef.close(result);
                }, 1000);
            },
            error: () => {
                this.buttonState = "error";
                this.notificationService.error("numbers.deployment.migrationFailed", undefined, undefined, true);
                setTimeout(() => {
                    this.buttonState = undefined;
                }, 2000);
            },
        });
    };
}
