import { ChangeDetectionStrategy, Component, computed, HostBinding, input } from "@angular/core";
import { NumberTargetDto } from "@api";

import { NumberType } from "~shared/enums";
import { getNumberTargetStatus } from "~shared/util/number-helper";

@Component({
    selector: "app-number-result",
    templateUrl: "./number-result.component.html",
    styleUrls: ["./number-result.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class NumberResultComponent {

    readonly result = input.required<number | undefined>();
    readonly target = input<NumberTargetDto | undefined>(undefined);
    readonly numberType = input.required<NumberType>();

    readonly targetStatus = computed(() => getNumberTargetStatus(this.result(), this.target()));

    @HostBinding("class.has-result")
    get hasResult(): boolean {
        return this.result() !== null && this.result() !== undefined;
    }

    @HostBinding("class.no-result")
    get hasNoResult(): boolean {
        return !this.hasResult;
    }

    @HostBinding("class.off-target")
    get isOffTarget(): boolean {
        return this.targetStatus() === "offtarget";
    }

    @HostBinding("class.on-target")
    get isOnTarget(): boolean {
        return this.targetStatus() === "ontarget";
    }
}
