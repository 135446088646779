import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { UserContext } from "~services/contexts";
import { CustomLocalizationService } from "~services/custom-localization.service";
import { TitleService } from "~services/title.service";
import { UserService } from "~services/user.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.css"],
    standalone: false,
})

export class AppComponent implements OnInit {

    constructor(
        translateService: TranslateService,
        private readonly titleService: TitleService,
        private readonly userService: UserService,
        private readonly userContext: UserContext,
        private readonly customLocalizationService: CustomLocalizationService,
    ) {
        translateService.setDefaultLang("en");
        translateService.use(this.userContext.user()?.language || "en");
    }

    ngOnInit() {
        this.userService.initialise();
        this.customLocalizationService.initialise();
        this.titleService.initialise();
    }
}
