import { BooleanInput } from "@angular/cdk/coercion";
import { booleanAttribute, Component, input } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { GetTeamWatchlistDto, GetUserWatchlistDto, GetWatchlistDto, SimpleTeamWatchlistDto, SimpleUserWatchlistDto } from "@api";
import { Observable, of, switchMap } from "rxjs";

import { AccessService } from "~services/access.service";
import { WithDestroy } from "~shared/mixins";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { getUserName } from "~shared/util/user-helper";

declare type WatchlistDto = GetWatchlistDto | SimpleTeamWatchlistDto | SimpleUserWatchlistDto;
declare type TeamWatchlistDto = GetTeamWatchlistDto | SimpleTeamWatchlistDto;
declare type UserWatchlistDto = GetUserWatchlistDto | SimpleUserWatchlistDto;

const isTeamList = (list: WatchlistDto): list is TeamWatchlistDto =>
    "type" in list && list.type === "team" ||
    !("type" in list) && "company" in list && "team" in list;

const isUserList = (list: WatchlistDto): list is UserWatchlistDto =>
    "type" in list && list.type === "user" ||
    !("type" in list) && "clientId" in list && "user" in list;

@Component({
    selector: "app-watchlist-sharing-indicator",
    templateUrl: "./watchlist-sharing-indicator.component.html",
    styleUrl: "./watchlist-sharing-indicator.component.scss",
    standalone: false,
})
export class WatchlistSharingIndicatorComponent extends WithDestroy() {
    readonly list = input.required<GetWatchlistDto | SimpleTeamWatchlistDto | SimpleUserWatchlistDto>();

    readonly includeLink = input<boolean, BooleanInput>(false, { transform: booleanAttribute });

    readonly shouldShowLink$: Observable<boolean>;

    readonly isTeamList = isTeamList;
    readonly isUserList = isUserList;
    readonly getUserName = getUserName;

    constructor(private readonly accessService: AccessService) {
        super();

        const list$ = toObservable(this.list);
        const includeLink$ = toObservable(this.includeLink);

        this.shouldShowLink$ = includeLink$.pipe(
            switchMap(includeLink => !includeLink ? of(false) : list$.pipe(
                switchMap(list => isUserList(list) ? of(false) :
                    this.accessService.canAccessCompanyTeam(list.company.id, list.team.id)),
            )),
            shareReplayUntil(this.destroyed$),
        );
    }
}
