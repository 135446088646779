import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GetNumberDto, NumberRecordDetailDto } from "@api";

import { TeamContext } from "~services/contexts";
import { NumberStateService } from "~services/state";
import { mapNumberActionInput } from "~shared/util/action-input-builder";
import { mapNumberOrigin } from "~shared/util/origin-builder";

import { CommonAddChildActionButtonDirective } from "./add-child-action-button.component";

declare type NumberDto = GetNumberDto | NumberRecordDetailDto;

@Component({
    selector: "app-add-number-child-action-button",
    templateUrl: "./add-child-action-button.component.html",
    styleUrls: ["./add-child-action-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class AddNumberChildActionButtonComponent extends CommonAddChildActionButtonDirective<NumberDto> {

    readonly mapToOrigin = mapNumberOrigin;
    readonly mapToInput = mapNumberActionInput;

    constructor(
        teamContext: TeamContext,
        dialog: MatDialog,
        private readonly numberStateService: NumberStateService,
    ) {
        super(teamContext, dialog);
    }

    protected notifyChange = (source: NumberDto) => this.numberStateService.notifyRefresh(source);

    protected creationAllowed = (source: NumberDto): boolean => !source.isPrivate && source.canEdit;
}
