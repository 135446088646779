import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
    selector: "app-notes-callout",
    templateUrl: "./notes-callout.component.html",
    styleUrls: ["./notes-callout.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class NotesCalloutComponent {
    @Input() notes!: string;
}
