import { trigger } from "@angular/animations";
import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AddDiscussionDto, DiscussionAndSolutionDto, DiscussionsApi } from "@api";
import { Observable } from "rxjs";

import { EditSolutionDialogComponent } from "~/app/meeting/dialogs";
import { MeetingProgressService } from "~services/meeting-progress.service";
import { NotificationService } from "~services/notification.service";
import { DiscussionStateService } from "~services/state";
import { ErrorCode, WorkfactaError, wrapWorkfactaError } from "~shared/api-errors";
import { ButtonState } from "~shared/components/status-button/status-button.component";
import { DiscussionType, Priority } from "~shared/enums";
import { fadeInAnimationBuilder } from "~shared/util/animations";
import { IOriginDetails } from "~shared/util/origin-builder";
import { getDiscussionTypeNameKey } from "~shared/util/translation-helper";

export interface IQuickCreateDiscussionData {
    companyId: string;
    teamId: string;
    origin: IOriginDetails;
    input?: IDiscussionInputModel;
}

export declare type IDiscussionInputModel =
    Partial<Omit<AddDiscussionDto, "creatorUserId" | "origin">>;

@Component({
    selector: "app-quick-add-discussion-dialog",
    templateUrl: "./quick-add-discussion-dialog.component.html",
    styleUrls: ["./quick-add-discussion-dialog.component.scss"],
    animations: [
        trigger("fadeIn", fadeInAnimationBuilder()),
    ],
    standalone: false,
})
export class QuickAddDiscussionDialogComponent {

    buttonState: ButtonState;
    resolveNowState: ButtonState;

    readonly types = [
        DiscussionType.discussion,
        DiscussionType.challenge,
        DiscussionType.opportunity,
    ];

    readonly headingControl = this.fb.control<string | null>(null, [Validators.required, Validators.maxLength(250)]);
    readonly descriptionControl = this.fb.control<string | null>(null, [Validators.maxLength(1000)]);
    readonly typeControl = this.fb.nonNullable.control(DiscussionType.discussion, [Validators.required]);

    readonly form = new FormGroup({
        heading: this.headingControl,
        description: this.descriptionControl,
        type: this.typeControl,
    });

    readonly origin: IOriginDetails;

    readonly getTypeNameKey = getDiscussionTypeNameKey;

    readonly canResolveNow$: Observable<boolean>;

    private readonly companyId: string;
    private readonly teamId: string;
    private readonly input?: IDiscussionInputModel;

    constructor(
        private readonly discussionsApi: DiscussionsApi,
        private readonly discussionStateService: DiscussionStateService,
        private readonly meetingProgressService: MeetingProgressService,
        private readonly notificationService: NotificationService,
        private readonly fb: FormBuilder,
        private readonly dialog: MatDialog,
        private readonly dialogRef: MatDialogRef<QuickAddDiscussionDialogComponent, DiscussionAndSolutionDto>,
        @Inject(MAT_DIALOG_DATA) data: IQuickCreateDiscussionData,
    ) {
        this.origin = data.origin;
        this.companyId = data.companyId;
        this.teamId = data.teamId;
        this.input = data.input;

        // If no input is provided, the heading of the discussion will default to the heading/description of the origin.
        this.headingControl.setValue(this.input?.heading || this.origin.heading || (this.origin.description ?? null));
        this.descriptionControl.setValue(this.input?.description ?? null);
        this.typeControl.setValue(this.input?.type ?? DiscussionType.discussion);

        this.canResolveNow$ = this.meetingProgressService.isInProgress$(this.companyId, this.teamId);
    }

    static open(dialog: MatDialog, data: IQuickCreateDiscussionData):
        MatDialogRef<QuickAddDiscussionDialogComponent, DiscussionAndSolutionDto> {
        return dialog.open(QuickAddDiscussionDialogComponent, {
            width: "500px",
            data,
        });
    }

    save = (resolveNow = false) => {
        if (this.buttonState || this.resolveNowState) return;
        if (resolveNow) this.form.markAllAsTouched();
        if (!this.form.valid) return;
        this.setButtonState(resolveNow, "loading");
        this.discussionsApi.addDiscussion(
            this.companyId,
            this.teamId,
            {
                origin: this.origin,
                type: this.typeControl.value,
                heading: this.headingControl.value ?? "",
                description: this.descriptionControl.value ?? "",
                priority: this.input?.priority ?? Priority.medium,
                departmentId: this.input?.departmentId,
                categoryId: this.input?.categoryId,
                subCategoryId: this.input?.subCategoryId,
            }
        ).pipe(wrapWorkfactaError()).subscribe({
            next: result => {
                this.setButtonState(resolveNow, "success");
                this.discussionStateService.notifyAdd(result);
                if (resolveNow && this.meetingProgressService.isInProgress(result.company.id, result.team.id)) {
                    EditSolutionDialogComponent.open(this.dialog, result, true);
                    this.dialogRef.close(result);
                } else {
                    setTimeout(() => this.dialogRef.close(result), 1000);
                }
            },
            error: error => {
                this.setButtonState(resolveNow, "error");
                setTimeout(() => {
                    this.setButtonState(resolveNow, undefined);
                }, 2000);
                if (error instanceof WorkfactaError) {
                    switch (error.status) {
                        case 409:
                            switch (error.code) {
                                case ErrorCode.planCapReached:
                                    this.form.setErrors({ capReached: true });
                                    return;
                            }
                    }
                }
                this.notificationService.errorUnexpected();
            }
        });
    };

    private setButtonState = (resolveNow: boolean, state: ButtonState) => {
        if (resolveNow) {
            this.resolveNowState = state;
        } else {
            this.buttonState = state;
        }
    };
}
