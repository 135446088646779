import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component, Input } from "@angular/core";

@Component({
    selector: "app-table-spinner",
    template: `<div class="spinner-container" [ngClass]="{ overlay: hasData, inline: !hasData }"><mat-spinner></mat-spinner></div>`,
    styles: [
        `.spinner-container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }`,
        `.spinner-container.overlay {
            background: rgba(255, 255, 255, 0.8);
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 101;
        }`,
        `.spinner-container.inline mat-spinner { margin: 5em; }`
    ],
    standalone: false,
})
export class TableSpinnerComponent {

    get hasData(): boolean {
        return this.hasDataInternal;
    }

    @Input() set hasData(value: boolean) {
        this.hasDataInternal = coerceBooleanProperty(value);
    }

    private hasDataInternal = false;

    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention
    static ngAcceptInputType_hasData: any;
}
