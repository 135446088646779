import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

@Component({
    templateUrl: "./number-capped-dialog.component.html",
    styleUrls: ["./number-capped-dialog.component.scss"],
    standalone: false,
})
export class NumberCappedDialogComponent {

    static open(dialog: MatDialog) {
        return dialog.open(NumberCappedDialogComponent, {
            autoFocus: false,
        });
    }

}
