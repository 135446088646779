import { Component, Input } from "@angular/core";
import { GetFeedItemDto } from "@api";

import { getUserName } from "~shared/util/user-helper";

@Component({
    selector: "app-feed-creation-info",
    templateUrl: "./feed-creation-info.component.html",
    styleUrls: ["./feed-creation-info.component.scss"],
    standalone: false,
})
export class FeedCreationInfoComponent {

    @Input() feedItem!: GetFeedItemDto;

    @Input() isCommentEdited?: boolean;

    readonly getUserName = getUserName;
}
