import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";

import { DiscussionType } from "~shared/enums";
import { getDiscussionTypeNameKey } from "~shared/util/translation-helper";

@Component({
    selector: "app-discussion-type",
    templateUrl: "./discussion-type.component.html",
    styleUrls: ["./discussion-type.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class DiscussionTypeComponent {

    @Input() type = DiscussionType.discussion;

    readonly getTypeNameKey = getDiscussionTypeNameKey;

    @HostBinding("class.challenge")
    get isChallenge() {
        return this.type === DiscussionType.challenge;
    }

    @HostBinding("class.opportunity")
    get isOpportunity() {
        return this.type === DiscussionType.opportunity;
    }

}
