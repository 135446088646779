import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ActionsV2Api, GetUserWatchlistDto, PlanGoalsApi, PlanNumbersApi, PlanReportsApi, UserWatchlistsApi, WatchlistItemReferenceDto } from "@api";
import { Observable } from "rxjs";

import { CompanyMenuRepository, QuarterContextRepository } from "~repositories";
import { TeamContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";
import { UserWatchlistItemsDto } from "~shared/util/watchlist-helper";

import { BaseAddWatchlistItemsDialogComponent } from "../base-add-watchlist-items-dialog/base-add-watchlist-items-dialog.component";

interface AddUserWatchlistItemsDialogData {
    readonly list: GetUserWatchlistDto;
    readonly items: UserWatchlistItemsDto;
}

@Component({
    selector: "app-add-user-watchlist-items-dialog",
    templateUrl: "../base-add-watchlist-items-dialog/base-add-watchlist-items-dialog.component.html",
    styleUrls: ["../base-add-watchlist-items-dialog/base-add-watchlist-items-dialog.component.scss"],
    providers: [QuarterContextRepository],
    standalone: false,
})
export class AddUserWatchlistItemsDialogComponent
    extends BaseAddWatchlistItemsDialogComponent<GetUserWatchlistDto, AddUserWatchlistItemsDialogData> {

    constructor(
        private readonly userWatchlistsApi: UserWatchlistsApi,
        private readonly teamContext: TeamContext,
        companyMenuRepository: CompanyMenuRepository,
        actionsApi: ActionsV2Api,
        planGoalsApi: PlanGoalsApi,
        planNumbersApi: PlanNumbersApi,
        planReportsApi: PlanReportsApi,
        notificationService: NotificationService,
        @Inject(MAT_DIALOG_DATA) data: AddUserWatchlistItemsDialogData,
    ) {
        super(companyMenuRepository, actionsApi, planGoalsApi, planNumbersApi, planReportsApi, notificationService, data);

        this.setInitialTeam();
    }

    static open(dialog: MatDialog, items: UserWatchlistItemsDto) {
        return this.openInternal(AddUserWatchlistItemsDialogComponent, dialog, {
            list: items.list,
            items,
        });
    }

    private setInitialTeam = () => {
        const companyTeam = this.teamContext.companyTeam();
        if (!companyTeam || !companyTeam.team || companyTeam.company.clientId !== this.list.clientId) return;
        this.teamControl.setValue({
            id: companyTeam.team.id,
            name: companyTeam.team.name,
            company: {
                id: companyTeam.company.id,
                clientId: companyTeam.company.clientId,
                name: companyTeam.company.name,
            },
        });
    };

    protected setWatched = (item: WatchlistItemReferenceDto, watched: boolean): Observable<unknown> => {
        if (watched) {
            return this.userWatchlistsApi.watchItem(this.list.clientId, this.list.id, item);
        } else {
            return this.userWatchlistsApi.unwatchItem(this.list.clientId, this.list.id, item);
        }
    };
}
