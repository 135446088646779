import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { Directive, HostBinding, Input } from "@angular/core";

/**
 * A directive allowing to easily change the visual state of a material button to appear as disabled, while still allowing clicks.
 *
 * The reasoning for this is twofold:
 * 1. This allows submitting the form, which will highlight any missed fields.
 * 2. Disabling a control can make it invisible to screen readers - it's better to mark as `aria-disabled="true"`,
 *    which this directive will do.
 */
@Directive({
    selector: "[appMatVisualDisable]",
    standalone: false,
})
export class MatVisualDisableDirective {

    get appMatVisualDisable() {
        return this.appearDisabledInternal;
    }

    @Input() set appMatVisualDisable(value: boolean) {
        this.appearDisabledInternal = coerceBooleanProperty(value);
    }

    get disabled() {
        return this.disabledInternal;
    }

    @Input() set disabled(value: boolean) {
        this.disabledInternal = coerceBooleanProperty(value);
    }

    @HostBinding("attr.aria-disabled")
    get appearDisabled() {
        return this.disabledInternal || this.appearDisabledInternal;
    }

    private appearDisabledInternal = true;
    private disabledInternal = false;

    constructor() { }

    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention
    static ngAcceptInputType_appMatVisualDisable: BooleanInput;
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention
    static ngAcceptInputType_disabled: BooleanInput;
}
