import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { GetActionDto } from "@api";
import { map } from "rxjs/operators";

import { ActionHomepageDialogComponent } from "~homepage";
import { TeamContext } from "~services/contexts";
import {
    columnBuilder,
    ColumnDefinition,
    defaultColumnBuilder,
    getDisplayedColumns,
    TableSettings,
    TableSettingsService
} from "~services/table-settings.service";
import { getActionProgressSortOrder } from "~shared/action-progress";
import { ActionProgress } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { getUserName } from "~shared/util/user-helper";


const TABLE_SETTINGS_NAME = "ChildActions";

export declare type MenuType = "edit" | "feedOnly" | "none";

@Component({
    selector: "app-child-actions-table",
    templateUrl: "./child-actions-table.component.html",
    styleUrls: ["./child-actions-table.component.scss"],
    standalone: false,
})
export class ChildActionsTableComponent extends WithDestroy() {
    @Input() set actions(value: GetActionDto[] | null) {
        this.actionsDataSource.data = value ?? [];
    }

    get actions(): GetActionDto[] {
        return this.actionsDataSource.data;
    }

    /**
     * Whether all controls should be disabled.
     */
    @Input() set disabled(value: BooleanInput) {
        this.disabledInternal = coerceBooleanProperty(value);
    }

    get disabled(): boolean {
        return this.disabledInternal;
    }

    @Input() menuType: MenuType = "edit";

    @Output() reloadActionList = new EventEmitter();
    @Output() reloadParentItem = new EventEmitter();

    @ViewChild(MatSort) set sort(value: MatSort) {
        this.actionsDataSource.sort = value;
    }

    readonly getUserName = getUserName;
    readonly getDelegatedItemCompanyTeam = getDelegatedItemCompanyTeam;

    readonly columnDefinitions: Readonly<ColumnDefinition>[] = [
        defaultColumnBuilder("description", "actions.description", "visibility"),
        defaultColumnBuilder("status", "actions.status", "visibility"),
        defaultColumnBuilder("priorityName", "actions.priority"),
        defaultColumnBuilder("owner", "actions.owner"),
        columnBuilder("creator", "actions.creator"),
        {
            key: "team",
            nameKey: "actions.team",
            isEnabled: () => this.crossTeamFeaturesEnabled,
        },
        columnBuilder("createdDate", "actions.created"),
        defaultColumnBuilder("dueDate", "actions.due"),
        columnBuilder("department", "actions.department"),
        defaultColumnBuilder("category", "actions.category"),
        columnBuilder("subCategory", "actions.subCategory"),
        defaultColumnBuilder("actions", "actions.options", "position"),
    ];

    readonly tableSettings$ = this.tableSettingsService.getTableSettings(TABLE_SETTINGS_NAME, this.columnDefinitions);

    readonly displayedColumns$ = this.tableSettings$.pipe(
        map(settings => getDisplayedColumns(settings, this.columnDefinitions)),
        shareReplayUntil(this.destroyed$),
    );

    get crossTeamFeaturesEnabled() {
        return this.teamContext.features.crossTeamFeaturesEnabled();
    }

    readonly actionsDataSource = new MatTableDataSource<GetActionDto>();

    private disabledInternal = false;

    constructor(
        private readonly tableSettingsService: TableSettingsService,
        private readonly teamContext: TeamContext,
        private readonly dialog: MatDialog,
    ) {
        super();

        this.actionsDataSource.sortingDataAccessor = (data, property) => {
            switch (property) {
                case "status": return getActionProgressSortOrder(data.progress);
                case "owner": return getUserName(data.owner);
                case "creator": return getUserName(data.creator);
                case "team": return data.team.name;
                case "priorityName": return data.priority ?? 0;
                case "department": return data.department?.name ?? "";
                case "category": return data.category?.description ?? "";
                case "subCategory": return data.category?.subCategory?.description ?? "";
                default:
                    return (data as never)[property] ?? "";
            }
        };
    }

    reloadActions = () => this.reloadActionList.emit();
    reloadParent = () => this.reloadParentItem.emit();

    isActionOverdue = (action: GetActionDto) => action.progress === ActionProgress.overdue;
    isActionComplete = (action: GetActionDto) => action.progress === ActionProgress.complete;
    isActionCancelled = (action: GetActionDto) => action.progress === ActionProgress.cancelled;

    tableUpdated = (settings: TableSettings) => {
        this.tableSettingsService.setTableSettings(TABLE_SETTINGS_NAME, settings);
    };

    viewAction = (action: GetActionDto, focusFeed = false) =>
        ActionHomepageDialogComponent.open(this.dialog, action, { focusFeed })
            .componentInstance.events$.subscribe(event => {
                switch (event.type) {
                    case "updated":
                        this.reloadActions();
                        break;
                    case "deleted":
                        this.reloadParent();
                        break;
                }
            });

    openFeed = (action: GetActionDto) => this.viewAction(action, /* focusFeed: */ true);
}
