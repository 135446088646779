import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { DiscussionStatus } from "@api";

import { getDiscussionStatusNameKey } from "~shared/util/translation-helper";

@Component({
    selector: "app-discussion-status",
    templateUrl: "./discussion-status.component.html",
    styleUrl: "./discussion-status.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: "wf-discussion-status wf-status-indicator",
    },
    standalone: false,
})
export class DiscussionStatusComponent {
    @Input() status: DiscussionStatus = DiscussionStatus.new;

    @HostBinding("class")
    get className() {
        switch (this.status) {
            case DiscussionStatus.new: return "wf-status-indicator-outline";
            case DiscussionStatus.referred: return "wf-status-indicator-shaded wf-status-indicator-intermediate";
            case DiscussionStatus.rejected: return "wf-status-indicator-shaded wf-status-indicator-failure";
            case DiscussionStatus.approved: return "wf-status-indicator-shaded wf-status-indicator-success";
            case DiscussionStatus.resolved: return "wf-status-indicator-filled wf-status-indicator-success";
            default: return undefined;
        }
    }

    get statusKeyName() {
        return getDiscussionStatusNameKey(this.status);
    }
}
