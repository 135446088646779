import { Directive, TemplateRef } from "@angular/core";

/**
 * Marker directive for the option content
 */
@Directive({
    selector: "[appAutoOption]",
    standalone: false,
})
export class AutoOptionContentDirective<TOpt> {
    constructor(public template: TemplateRef<TOpt>) { }
}
