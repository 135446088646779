import { Component } from "@angular/core";
import { GetStatusChangedFeedItemDto } from "@api/model/models";

import { ActionProgress, DiscussionStatus, GoalStatus, NumberType, StatusChangedFieldType, StatusChangedValueFormat } from "~shared/enums";

import { BaseFeedListItemDirective } from "../base-feed-list-item.directive";

declare type ChangeType = "added" | "removed" | "updated";

@Component({
    selector: "app-feed-list-status",
    templateUrl: "./feed-list-status.component.html",
    styleUrls: ["./feed-list-status.component.scss"],
    standalone: false,
})
export class FeedListStatusComponent extends BaseFeedListItemDirective<GetStatusChangedFeedItemDto> {

    // eslint-disable-next-line @typescript-eslint/naming-convention
    readonly StatusChangedFieldType = StatusChangedFieldType;

    get changeType(): ChangeType {
        const newValue = this.feedItem.newValue;
        if (newValue === undefined || newValue === null) return "removed";
        const oldValue = this.feedItem.oldValue;
        if (oldValue === undefined || oldValue === null) return "added";
        return "updated";
    }

    get iconName() {
        if (this.feedItem.fieldType === StatusChangedFieldType.discussionSolutionStatus) return "done";
        return "update";
    }

    get numberChangeTypeDescKey(): string {
        switch (this.changeType) {
            case "added": return "FeedsList.listItems.statuses.numbers.resultSetTo";
            case "removed": return "FeedsList.listItems.statuses.numbers.resultRemoved";
            case "updated": return "FeedsList.listItems.statuses.numbers.resultChanged";
        }
    }

    get numberType(): NumberType {
        switch (this.feedItem.valueFormat) {
            case StatusChangedValueFormat.numericCurrency: return NumberType.currency;
            case StatusChangedValueFormat.numericPercentage: return NumberType.percentage;
            case StatusChangedValueFormat.numeric:
            default: return NumberType.normal;
        }
    }

    get positiveStatusChange(): boolean {
        const newGoalStatus = this.newGoalStatus;
        if (newGoalStatus !== undefined) {
            return newGoalStatus === GoalStatus.complete || newGoalStatus === GoalStatus.onTarget;
        }
        const newActionProgress = this.newActionProgress;
        if (newActionProgress !== undefined) {
            return newActionProgress === ActionProgress.complete;
        }
        const newDiscussionStatus = this.newDiscussionStatus;
        if (newDiscussionStatus !== undefined) {
            return newDiscussionStatus === DiscussionStatus.resolved;
        }
        return false;
    }

    // Note: this is not the inverse of positive status change, as "Cancelled" is considered neutral
    get negativeStatusChange(): boolean {
        const newGoalStatus = this.newGoalStatus;
        if (newGoalStatus !== undefined) {
            return newGoalStatus === GoalStatus.updateRequired || newGoalStatus === GoalStatus.offTarget;
        }
        const newActionProgress = this.newActionProgress;
        if (newActionProgress !== undefined) {
            return newActionProgress === ActionProgress.overdue;
        }
        const newDiscussionStatus = this.newDiscussionStatus;
        if (newDiscussionStatus !== undefined) {
            return newDiscussionStatus === DiscussionStatus.rejected;
        }
        return false;
    }

    get newValueGoalStatusCustomName(): string | undefined {
        if (this.feedItem.fieldType !== StatusChangedFieldType.goalStatus) return undefined;
        return this.feedItem.newValueName;
    }

    get newActionProgress(): ActionProgress | undefined {
        if (this.feedItem.fieldType !== StatusChangedFieldType.actionStatus) return undefined;
        const newValue = this.feedItem.newValue as unknown;
        if (newValue === null || newValue === undefined || typeof newValue !== "number") return undefined;
        return newValue as ActionProgress;
    }

    get newGoalStatus(): GoalStatus | undefined {
        if (this.feedItem.fieldType !== StatusChangedFieldType.goalStatus) return undefined;
        const newValue = this.feedItem.newValue as unknown;
        if (newValue === null || newValue === undefined || typeof newValue !== "number") return undefined;
        return newValue as GoalStatus;
    }

    get newDiscussionStatus(): DiscussionStatus | undefined {
        if (this.feedItem.fieldType !== StatusChangedFieldType.discussionSolutionStatus) return undefined;
        const newValue = this.feedItem.newValue as unknown;
        if (newValue === null || newValue === undefined || typeof newValue !== "number") return undefined;
        return newValue as DiscussionStatus;
    }
}
