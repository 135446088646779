import { ChangeDetectionStrategy, Component, computed, HostBinding, input } from "@angular/core";
import { NumberTargetDto } from "@api";

import { NumberType } from "~shared/enums";
import { hasLowerBound, hasUpperBound } from "~shared/util/number-helper";

@Component({
    selector: "app-number-target",
    templateUrl: "./number-target.component.html",
    styleUrls: ["./number-target.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class NumberTargetComponent {

    readonly target = input.required<NumberTargetDto | undefined>();
    readonly numberType = input.required<NumberType>();

    readonly hasUpperBound = computed(() => hasUpperBound(this.target()));
    readonly hasLowerBound = computed(() => hasLowerBound(this.target()));

    @HostBinding("class.no-target")
    get hasNoTarget() {
        return !this.hasUpperBound() && !this.hasLowerBound();
    }
}
