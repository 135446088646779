import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "app-report-exceeded-dialog",
    templateUrl: "./report-exceeded-dialog.component.html",
    styleUrls: ["./report-exceeded-dialog.component.scss"],
    standalone: false,
})
export class ReportExceededDialogComponent {

    static open(dialog: MatDialog) {
        return dialog.open(ReportExceededDialogComponent, {
            autoFocus: false,
        });
    }
}
