import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

import { isCompanyPerformance, isEnterprisePerformance, isTeamPerformance, isUserAnalytics, isUserPerformance, PerformanceDto } from "~shared/util/performance-shared";
import { getUserName } from "~shared/util/user-helper";

interface PerformanceChartDialogData {
    data: PerformanceDto;
    level: string | null;
    showParent: boolean;
}

@Component({
    selector: "app-performance-chart-dialog",
    templateUrl: "./performance-chart-dialog.component.html",
    styleUrls: ["./performance-chart-dialog.component.scss"],
    standalone: false,
})
export class PerformanceChartDialogComponent {

    get description(): string {
        const data = this.data;
        if (isUserPerformance(data)) return getUserName(data.user);
        if (isTeamPerformance(data)) return data.team.name;
        if (isCompanyPerformance(data)) return data.company.name;
        if (isEnterprisePerformance(data)) return data.parentCompany.name;
        return "";
    }

    get parentDescription(): string {
        const data = this.data;
        if (isUserPerformance(data) && !isUserAnalytics(data)) return data.team.name;
        if (isTeamPerformance(data)) return data.team.company.name;
        return "";
    }

    get typeKey(): string {
        const data = this.data;
        if (isUserPerformance(data)) return "performance.userPerformance";
        if (isTeamPerformance(data)) return "performance.teamPerformance";
        if (isCompanyPerformance(data)) return "performance.companyPerformance";
        if (isEnterprisePerformance(data)) return "performance.enterprisePerformance";
        return "";
    }

    readonly data: PerformanceDto;
    readonly level: string | null;
    readonly showParent: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) { data, level, showParent }: PerformanceChartDialogData,
    ) {
        this.data = data;
        this.level = level;
        this.showParent = showParent;
    }

    static open(dialog: MatDialog, data: PerformanceDto, level: string | null = null, showParent = false) {
        return dialog.open<PerformanceChartDialogComponent, PerformanceChartDialogData, void>(PerformanceChartDialogComponent, {
            data: { data, level, showParent },
            width: "850px",
            autoFocus: false,
        });
    }

}
