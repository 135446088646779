import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "app-report-capped-dialog",
    templateUrl: "./report-capped-dialog.component.html",
    styleUrls: ["./report-capped-dialog.component.scss"],
    standalone: false,
})
export class ReportCappedDialogComponent {

    static open(dialog: MatDialog) {
        return dialog.open(ReportCappedDialogComponent, {
            autoFocus: false,
        });
    }

}
