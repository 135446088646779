import { ChangeDetectionStrategy, Component, computed } from "@angular/core";

import { TeamContext, UserContext } from "~services/contexts";

import { IMenuItem } from "../menu-list-item/menu-list-item.component";
import { buildMenuItems } from "./sidebar-items";

@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class AppSidebarComponent {

    readonly menuItems = computed(() => buildMenuItems(this.userContext.user(), this.teamContext.companyTeam()));

    constructor(
        private readonly userContext: UserContext,
        private readonly teamContext: TeamContext,
    ) {
    }

    trackMenuItem = (_: number, item: IMenuItem) => item.type === "separator" ? item : item.id;
}
