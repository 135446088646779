import { booleanAttribute, Component, input } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogClose } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";

@Component({
    selector: "wf-dialog-close-button",
    imports: [
        MatButtonModule,
        MatDialogClose,
        MatIconModule,
    ],
    templateUrl: "./dialog-close-button.component.html",
    styleUrl: "./dialog-close-button.component.scss",
})
export class WfDialogCloseButtonComponent {
    readonly disabled = input(false, { transform: booleanAttribute });
}
