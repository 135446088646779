import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: "app-error-card",
    templateUrl: "./error-card.component.html",
    styleUrls: ["./error-card.component.scss"],
    standalone: false,
})
export class ErrorCardComponent {

    @Input() title: string | undefined;
    @Input() message: string | undefined;

    @Output("retry") retryEmitter = new EventEmitter<void>();

    constructor() { }

    retry = () => this.retryEmitter.emit();

}
