import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

import { FeedScope } from "~feed/services";
import { NotificationService } from "~services/notification.service";
import { ACCEPT_DOCUMENT_OR_IMAGE, hasDocumentOrImageExtension, showInvalidDocumentOrImageWarning } from "~shared/util/attachments";

import { BaseAddFeedItemDirective } from "../base-add-feed-item.directive";

export declare type AddItemType = "comment" | "link" | "attachment";

@Component({
    selector: "app-add-item-header",
    templateUrl: "./add-item-header.component.html",
    styleUrls: ["./add-item-header.component.scss"],
    standalone: false,
})
export class AddItemHeaderComponent extends BaseAddFeedItemDirective {

    @Input() type: AddItemType = "comment";

    @Output() typeChange = new EventEmitter<AddItemType>();

    @ViewChild("filePicker") filePicker?: ElementRef<HTMLInputElement>;

    readonly acceptTypes = ACCEPT_DOCUMENT_OR_IMAGE;

    constructor(
        private readonly notificationService: NotificationService,
        private readonly feedScope: FeedScope,
    ) {
        super();
    }

    selectType = (type: AddItemType) => {
        if (!type || this.type === type) return;
        const previousType = this.type;
        this.type = type;
        if (type !== "attachment") {
            this.typeChange.emit(type);
            return;
        }
        // We are uploading an attachment.
        // Click the filepicker element
        this.filePicker?.nativeElement.click();
        // Reset the selected type back to the previous type
        setTimeout(() => this.type = previousType, 0);
    };

    uploadAttachment = () => {
        const selectedFile = this.filePicker?.nativeElement?.files?.item(0);
        if (!selectedFile || !this.feedScope?.adapter) return;
        if (!hasDocumentOrImageExtension(selectedFile.name)) {
            showInvalidDocumentOrImageWarning(this.notificationService);
            this.clearFile();
            return;
        }
        this.feedScope.adapter.addFeedItemFile(selectedFile, this.context?.key).subscribe({
            next: (item) => {
                this.clearFile();
                this.itemAdded.emit(item);
            },
            error: () => {
                this.notificationService.errorUnexpected();
                this.clearFile();
            }
        });
    };

    private clearFile = () => {
        if (!this.filePicker?.nativeElement) return;
        this.filePicker.nativeElement.value = "";
    };
}
