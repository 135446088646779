import "chartjs-adapter-moment";

import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { GetNumberDto } from "@api";
import { ChartDataset } from "chart.js";

import {
    DataPoint
} from "./number-chart-calculation";

export declare type CustomDataSet = ChartDataset<"line", DataPoint[]>;

@Component({
    templateUrl: "./number-chart-dialog.component.html",
    styleUrls: ["./number-chart-dialog.component.scss"],
    standalone: false,
})
export class NumberChartDialogComponent {

    get description() {
        return this.number.description;
    }

    get financialYear() {
        return this.number.financialYear;
    }

    get quarter() {
        return this.number.planningPeriod;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) readonly number: GetNumberDto,
    ) {
    }

    static open(matDialog: MatDialog, number: GetNumberDto) {
        return matDialog.open<NumberChartDialogComponent, GetNumberDto, void>(NumberChartDialogComponent, {
            width: "850px",
            data: number,
            autoFocus: false
        });
    }
}
