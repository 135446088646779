import { Component, Inject } from "@angular/core";
import { AsyncValidatorFn, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GetNumberDto, GlobalItemScheduleDto, NumberDeploymentMigrationApi, PlanNumbersApi } from "@api";
import { BehaviorSubject, catchError, EMPTY, first, map, Observable, of, startWith, switchMap, tap } from "rxjs";

import { IQuarter } from "~repositories";
import { NotificationService } from "~services/notification.service";
import { toFiscalQuarter } from "~shared/commonfunctions";
import { ButtonState } from "~shared/components/status-button/status-button.component";
import { WithDestroy } from "~shared/mixins";
import { shareReplayUntil } from "~shared/util/rx-operators";

const periodConflictValidator = (schedule$: Observable<GlobalItemScheduleDto[]>): AsyncValidatorFn =>
    control => {
        const period = control.value as IQuarter | null;
        if (!period) return of(null);

        return schedule$.pipe(
            first(),
            map(schedule => {
                const existing = schedule.find(s => s.financialYear === period.financialYear && s.planningPeriod === period.quarter);
                return existing ? { conflict: true } : null;
            }),
        );
    };

@Component({
    selector: "app-copy-number-backwards-dialog",
    templateUrl: "./copy-number-backwards-dialog.component.html",
    styleUrl: "./copy-number-backwards-dialog.component.scss",
    standalone: false,
})
export class CopyNumberBackwardsDialogComponent extends WithDestroy() {

    buttonState: ButtonState;

    get description() {
        return this.number.description;
    }

    get companyId() {
        return this.number.company.id;
    }

    readonly maxPeriod: IQuarter;

    readonly periodControl = new FormControl<IQuarter | null>(null, [Validators.required]);

    readonly form = new FormGroup({
        period: this.periodControl,
    });

    readonly schedule$: Observable<GlobalItemScheduleDto[] | "loading" | "error">;

    readonly columns = ["year", "quarter"];

    private readonly number: GetNumberDto;

    private hasLoaded = false;

    private readonly retrySubject = new BehaviorSubject<void>(undefined);

    constructor(
        private readonly api: NumberDeploymentMigrationApi,
        private readonly planNumbersApi: PlanNumbersApi,
        private readonly notificationService: NotificationService,
        private readonly dialogRef: MatDialogRef<CopyNumberBackwardsDialogComponent, boolean>,
        @Inject(MAT_DIALOG_DATA) number: GetNumberDto,
    ) {
        super();

        this.number = number;
        // Note: this may point to a nonexistant quarter, but the comparison will still work.
        this.maxPeriod = {
            financialYear: number.financialYear,
            quarter: number.planningPeriod - 1,
        };

        this.schedule$ = this.retrySubject.pipe(
            switchMap(() => this.planNumbersApi.getNumberSchedule(
                number.company.id,
                number.team.id,
                toFiscalQuarter({ financialYear: number.financialYear, quarter: number.planningPeriod }),
                number.id,
            ).pipe(
                tap(() => this.hasLoaded = true),
                catchError(() => of("error" as const)),
                startWith("loading" as const),
            )),
            shareReplayUntil(this.destroyed$),
        );

        this.periodControl.addAsyncValidators(periodConflictValidator(this.schedule$.pipe(
            switchMap(schedule => schedule === "loading" || schedule === "error" ? EMPTY : of(schedule)),
        )));
    }

    static open(dialog: MatDialog, number: GetNumberDto) {
        return dialog.open<CopyNumberBackwardsDialogComponent, GetNumberDto, boolean>(CopyNumberBackwardsDialogComponent, {
            width: "600px",
            data: number,
        });
    }

    submit = () => {
        const period = this.periodControl.value;
        if (!this.form.valid || !this.hasLoaded || !period || this.buttonState) return;

        this.buttonState = "loading";

        const number = this.number;

        this.api.copyBackwards(
            number.company.id,
            number.team.id,
            toFiscalQuarter({ financialYear: number.financialYear, quarter: number.planningPeriod }),
            number.id,
            {
                financialYear: period.financialYear,
                planningPeriod: period.quarter,
            }
        ).subscribe({
            next: () => {
                this.buttonState = "success";
                this.notificationService.success("numbers.copyBackwards.success", undefined, undefined, true);
                setTimeout(() => {
                    this.dialogRef.close(true);
                }, 1000);
            },
            error: () => {
                this.buttonState = "error";
                this.notificationService.error("numbers.copyBackwards.error", undefined, undefined, true);
                setTimeout(() => {
                    this.buttonState = undefined;
                }, 2000);
            },
        });
    };

    retry = () => this.retrySubject.next();
}
