// eslint-disable-next-line max-classes-per-file
import { FocusMonitor } from "@angular/cdk/a11y";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import {
    Component, DoCheck, ElementRef, forwardRef, Host, HostBinding, Inject,
    Input, OnDestroy, OnInit, Optional, Self, SkipSelf, ViewChild
} from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroupDirective, NgControl, NgForm, Validators } from "@angular/forms";
import { MatAutocompleteOrigin, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { _ErrorStateTracker, ErrorStateMatcher } from "@angular/material/core";
import { MatOption } from "@angular/material/core";
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from "@angular/material/form-field";
import * as moment from "moment";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { skip } from "rxjs/operators";

import { DURATION_FORMAT } from "~shared/util/constants";

import { TimeParserService } from "./time-parser.service";

interface TimeOption {
    display: string;
    value: string;
}

const DEFAULT_TIME_DISPLAY_FORMAT = "h:mm a";
const INTERNAL_FORMAT = DURATION_FORMAT;

const DEFAULT_TIME_OPTION_INTERVAL_MINS = 15;

const AUTO_ITEM_HEIGHT_PX = 48;

@Component({
    selector: "app-time-picker",
    templateUrl: "./time-picker.component.html",
    styleUrls: ["./time-picker.component.scss"],
    providers: [
        {
            provide: MatFormFieldControl,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            useExisting: forwardRef(() => TimePickerComponent)
        }
    ],
    standalone: false,
})
export class TimePickerComponent
    implements DoCheck, OnInit, OnDestroy, MatFormFieldControl<string>, ControlValueAccessor {

    private static nextId = 0;

    @Input() get displayFormat() {
        return this._displayFormat;
    }

    set displayFormat(value: string) {
        this._displayFormat = value;
        this.generateOptions();
    }

    @Input() get intervalMins() {
        return this._intervalMins;
    }

    set intervalMins(value: number) {
        this._intervalMins = value;
        this.generateOptions();
    }

    @ViewChild("timeInput") timeInput: ElementRef<HTMLInputElement> | undefined;
    @ViewChild("timeInput", { read: MatAutocompleteTrigger }) autoTrigger: MatAutocompleteTrigger | undefined;

    //#region MatFormFieldControl implementation - properties
    /* eslint-disable @typescript-eslint/member-ordering */

    @HostBinding() id = `time-picker-input-${TimePickerComponent.nextId++}`;

    @HostBinding("class.floating")
    get shouldLabelFloat() {
        return this.focused || !!this.inputField.value || !!this.valueSubject.value;
    }

    @Input() get placeholder(): string {
        return this._placeholder ?? "";
    }

    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChangesSubject.next();
    }

    @Input() get required(): boolean {
        return this._required ?? this.ngControl?.control?.hasValidator(Validators.required) ?? false;
    }

    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);
        this.stateChangesSubject.next();
    }

    @Input() get disabled(): boolean {
        return this.inputField.disabled;
    }

    set disabled(value: boolean) {
        value = coerceBooleanProperty(value);
        if (value) {
            this.inputField.disable();
        } else {
            this.inputField.enable();
        }
        this.stateChangesSubject.next();
        this.stateChanges.next();
    }

    inputField = new FormControl<string>("", { nonNullable: true });

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("aria-describedby") userAriaDescribedBy?: string;

    get value(): string | null {
        return this.valueSubject.value;
    }

    set value(value: string | null) {
        this.setInputValue(value);
        this.valueSubject.next(value ?? null);
        this.stateChanges.next();
    }

    get empty() {
        return !this.valueSubject.value;
    }

    get errorState() {
        return this.errorStateTracker.errorState;
    }

    readonly stateChanges = new Subject<void>();

    focused = false;
    controlType = "time-picker-input";
    ariaDescribedBy?: string;

    //#endregion
    /* eslint-enable @typescript-eslint/member-ordering */

    timeOptions!: TimeOption[];

    private _placeholder: string | undefined | null;
    private _required: boolean | undefined;
    private _displayFormat = DEFAULT_TIME_DISPLAY_FORMAT;
    private _intervalMins = DEFAULT_TIME_OPTION_INTERVAL_MINS;

    private valueSubject = new BehaviorSubject<string | null>(null);
    private stateChangesSubject = new Subject<void>();
    private readonly errorStateTracker: _ErrorStateTracker;

    private subscriptions: Subscription[] = [];

    private onChangedCallback?: (_: string | null) => void;
    private onTouchedCallback?: () => void;

    constructor(
        @Optional() @Self() public ngControl: NgControl,
        @Optional() _parentForm: NgForm,
        @Optional() _parentFormGroup: FormGroupDirective,
        @Optional() @Inject(MAT_FORM_FIELD) @Host() @SkipSelf() public formField: MatFormField,
        _defaultErrorStateMatcher: ErrorStateMatcher,
        private fm: FocusMonitor,
        private elRef: ElementRef<HTMLElement>,
        private timeParser: TimeParserService,
    ) {
        this.generateOptions();
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }

        this.errorStateTracker = new _ErrorStateTracker(
            _defaultErrorStateMatcher,
            ngControl,
            _parentFormGroup,
            _parentForm,
            this.stateChanges,
        );
    }

    ngOnInit(): void {
        this.subscriptions.push(this.fm.monitor(this.elRef, true).subscribe(origin => {
            if (!this.focused && !!origin && origin !== "program") {
                this.openDropdown();
            }
            this.focused = !!origin;
            this.stateChangesSubject.next();
        }));
        this.subscriptions.push(this.valueSubject.pipe(skip(1)).subscribe(value => {
            this.setTimeFormatError(false);
            this.stateChangesSubject.next();
            this.onChangedCallback?.(value);
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.fm.stopMonitoring(this.elRef);
    }

    ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    //#region MatFormFieldControl implementation - methods
    setDescribedByIds(ids: string[]): void {
        this.ariaDescribedBy = ids.join(" ");
    }

    onContainerClick(event: MouseEvent): void {
        if (this.disabled) return;
        if ((event.target as Element).tagName.toLowerCase() !== "input") {
            this.timeInput?.nativeElement?.focus();
            setTimeout(this.openDropdown);
        }
    }

    updateErrorState() {
        this.errorStateTracker.updateErrorState();
    }
    //#endregion

    //#region ControlValueAccessor implementation
    writeValue(obj: string): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChangedCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    //#endregion

    autoOpened = () => {
        setTimeout(this.selectTime);
    };

    autoClosed = () => {
        this.setInputValue(this.valueSubject.value);
    };

    onBlur = (isAutoOpen: boolean) => {
        if (!isAutoOpen) this.autoClosed();
        this.onTouchedCallback?.();
        this.setTimeFormatError(false);
    };

    inputChanged = (inputString: string | null) => {
        if (!inputString) {
            this.setTimeFormatError(false);
            if (!this.required) {
                this.valueSubject.next(null);
                this.stateChanges.next();
            }
            return;
        }
        const time = this.timeParser.parseTime(inputString);
        if (!time) {
            this.setTimeFormatError(true);
            return;
        }
        this.setTimeFormatError(false);
        this.valueSubject.next(time);
        this.stateChanges.next();
        this.selectTime();
    };

    inputKeydown = (event: KeyboardEvent) => {
        const selectedValue = this.valueSubject.value;
        if (!selectedValue || !this.autoTrigger?.panelOpen) return;
        switch (event.key) {
            case "ArrowDown":
            case "ArrowUp":
                this.value = this.autoTrigger.activeOption?.value;
                return;
        }
    };

    optionSelected = (option: MatOption) => {
        if (!option) {
            if (!this.required) {
                this.valueSubject.next(null);
                this.stateChanges.next();
            }
            return;
        }
        this.value = option.value;
    };

    getConnectedElement = (): MatAutocompleteOrigin =>
        ({ elementRef: this.formField ? this.formField.getConnectedOverlayOrigin() : this.elRef });

    shouldHighlight = (time: TimeOption): boolean => {
        const currentValue = this.valueSubject.value;
        if (!currentValue) return false;
        return time.value === currentValue;
    };

    private openDropdown = () => {
        if (this.disabled) return;
        this.autoTrigger?.openPanel();
        this.selectTime();
    };

    private selectTime = () => {
        if (!this.autoTrigger?.panelOpen) return;

        const currentValue = this.valueSubject.value;
        if (!currentValue) return;

        const currentTime = moment(currentValue, INTERNAL_FORMAT);

        const mins = currentTime.hour() * 60 + currentTime.minute();
        const index = Math.floor(mins / this._intervalMins);

        this.autoTrigger.autocomplete._keyManager.setActiveItem(index);

        let topIndex = index - 2;
        if (topIndex < 0) topIndex = 0;

        this.autoTrigger.autocomplete._setScrollTop(AUTO_ITEM_HEIGHT_PX * topIndex);
    };

    private setInputValue = (value: string | null) => {
        if (value) {
            const displayTime = moment(value, INTERNAL_FORMAT);
            if (displayTime.isValid()) {
                value = displayTime.format(DEFAULT_TIME_DISPLAY_FORMAT);
            }
        }
        this.inputField.setValue(value ?? "");
    };

    private generateOptions = () => {
        const startTime = moment("00:00:00", INTERNAL_FORMAT);
        const options: TimeOption[] = [];
        for (let mins = 0; mins < 24 * 60; mins += this._intervalMins) {
            const time = startTime.clone().add(mins, "minutes");
            options.push({
                display: time.format(this._displayFormat),
                value: time.format(INTERNAL_FORMAT)
            });
        }
        this.timeOptions = options;
    };

    private setTimeFormatError = (isError: boolean) => {
        if (!this.ngControl?.control) return;
        if (isError) {
            this.ngControl.control.setErrors({
                time: true
            });
        } else {
            this.ngControl.control.updateValueAndValidity();
        }
    };

    /* eslint-disable @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
    static ngAcceptInputType_required: BooleanInput;
    static ngAcceptInputType_disabled: BooleanInput;
    /* eslint-enable  @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
}
