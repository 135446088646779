import { Directive, InjectionToken } from "@angular/core";

export const HOMEPAGE_ACTIONS = new InjectionToken<HomepageActionsDirective>("HomepageActions");

@Directive({
    selector: "[appHomepageActions]",
    providers: [{ provide: HOMEPAGE_ACTIONS, useExisting: HomepageActionsDirective }],
    standalone: false,
})
export class HomepageActionsDirective { }
