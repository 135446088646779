import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ScheduledMeetingInstanceDto, WeeklyMeetingsApi } from "@api";

import { NotificationService } from "~services/notification.service";
import { toFiscalQuarter } from "~shared/commonfunctions";
import { ButtonState } from "~shared/components/status-button/status-button.component";

interface CancelMeetingDialogData {
    instance: ScheduledMeetingInstanceDto;
}

@Component({
    selector: "app-cancel-meeting-dialog",
    templateUrl: "./cancel-meeting-dialog.component.html",
    styleUrls: ["./cancel-meeting-dialog.component.scss"],
    standalone: false,
})
export class CancelMeetingDialogComponent {

    buttonState: ButtonState;

    readonly reasonControl = new FormControl<string | null>(null, [Validators.required, Validators.maxLength(500)]);
    readonly form = new FormGroup({ reason: this.reasonControl });

    private readonly instance: ScheduledMeetingInstanceDto;

    constructor(
        private readonly weeklyMeetingsApi: WeeklyMeetingsApi,
        private readonly notificationService: NotificationService,
        private readonly dialogRef: MatDialogRef<CancelMeetingDialogComponent, ScheduledMeetingInstanceDto>,
        @Inject(MAT_DIALOG_DATA) data: CancelMeetingDialogData) {

        this.instance = data.instance;
    }

    static open(dialog: MatDialog, instance: ScheduledMeetingInstanceDto) {
        return dialog.open<CancelMeetingDialogComponent, CancelMeetingDialogData, ScheduledMeetingInstanceDto>(
            CancelMeetingDialogComponent, {
            width: "600px",
            data: { instance },
        });
    }

    cancelMeeting = () => {
        if (!this.form.valid || this.buttonState) return;

        this.buttonState = "loading";
        const instance = this.instance;
        this.weeklyMeetingsApi.cancelMeeting(
            instance.meeting.company.id,
            instance.meeting.team.id,
            toFiscalQuarter({ financialYear: instance.financialYear, quarter: instance.planningPeriod }),
            instance.collectionPeriod,
            instance.id,
            {
                reason: this.reasonControl.value ?? "",
            },
        ).subscribe({
            next: result => {
                this.buttonState = "success";
                setTimeout(() => this.dialogRef.close(result), 1000);
            },
            error: () => {
                this.buttonState = "error";
                setTimeout(() => {
                    this.buttonState = undefined;
                }, 2000);
                this.notificationService.errorUnexpected();
            },
        });
    };
}
