import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GetTeamWatchlistDto, TeamWatchlistsApi, UpdateWatchlistDto } from "@api";
import { Observable } from "rxjs";

import { NotificationService } from "~services/notification.service";

import { BaseEditWatchlistDialogComponent } from "../base-edit-watchlist-dialog/base-edit-watchlist-dialog.component";

interface EditTeamWatchlistDialogData {
    companyId: string;
    teamId: string;
    list?: GetTeamWatchlistDto;
}

@Component({
    selector: "app-edit-team-watchlist-dialog",
    templateUrl: "../base-edit-watchlist-dialog/base-edit-watchlist-dialog.component.html",
    styleUrls: ["../base-edit-watchlist-dialog/base-edit-watchlist-dialog.component.scss"],
    standalone: false,
})
export class EditTeamWatchlistDialogComponent extends BaseEditWatchlistDialogComponent<GetTeamWatchlistDto, EditTeamWatchlistDialogData> {

    private readonly companyId: string;
    private readonly teamId: string;

    constructor(
        private readonly teamWatchlistsApi: TeamWatchlistsApi,
        notificationService: NotificationService,
        dialogRef: MatDialogRef<EditTeamWatchlistDialogComponent, GetTeamWatchlistDto>,
        @Inject(MAT_DIALOG_DATA) data: EditTeamWatchlistDialogData,
    ) {
        super(notificationService, dialogRef, data);

        this.companyId = data.companyId;
        this.teamId = data.teamId;
    }

    static openForAdd(dialog: MatDialog, companyId: string, teamId: string) {
        return this.openInternal(EditTeamWatchlistDialogComponent, dialog, {
            companyId, teamId,
        });
    }

    static openForEdit(dialog: MatDialog, list: GetTeamWatchlistDto) {
        return this.openInternal(EditTeamWatchlistDialogComponent, dialog, {
            companyId: list.company.id,
            teamId: list.team.id,
            list,
        });
    }

    protected addList = (dto: UpdateWatchlistDto): Observable<GetTeamWatchlistDto> =>
        this.teamWatchlistsApi.addList(this.companyId, this.teamId, dto);

    protected updateList = (list: GetTeamWatchlistDto, dto: UpdateWatchlistDto): Observable<GetTeamWatchlistDto> =>
        this.teamWatchlistsApi.updateList(list.company.id, list.team.id, list.id, dto);
}
