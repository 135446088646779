import { booleanAttribute, Component, HostBinding, input } from "@angular/core";
import { ManageUserApi, UserListDto } from "@api";
import * as moment from "moment";

import { NotificationService } from "~services/notification.service";
import { UserInvitationState } from "~shared/enums";

declare type InvitationButtonState = undefined | "sending" | "success" | "error";

@Component({
    selector: "app-user-invitation-button",
    templateUrl: "./user-invitation-button.component.html",
    styleUrls: ["./user-invitation-button.component.scss"],
    standalone: false,
})
export class UserInvitationButtonComponent {

    readonly user = input.required<UserListDto>();
    readonly disabled = input(false, { transform: booleanAttribute });

    @HostBinding("class.show-button") get showButton(): boolean {
        return !!this.state && !this.disabled();
    }

    @HostBinding("class.has-button") get hasButton(): boolean {
        return !this.disabled();
    }

    state: InvitationButtonState;

    get isComplete(): boolean {
        return this.user().invitationState === UserInvitationState.complete;
    }

    get isExpired(): boolean {
        return this.user().invitationState === UserInvitationState.invitationSent &&
            !!this.user().invitationExpiryDateUtc && moment(this.user().invitationExpiryDateUtc).isBefore(moment());
    }

    get isSent() {
        return this.user().invitationState === UserInvitationState.invitationSent &&
            (!this.user().invitationExpiryDateUtc || !moment(this.user().invitationExpiryDateUtc).isBefore(moment()));
    }

    get isNotInvited() {
        return this.user().invitationState === UserInvitationState.notInvited;
    }

    constructor(
        private readonly manageUserApi: ManageUserApi,
        private readonly notificationService: NotificationService
    ) { }

    sendInvitation = () => {
        if (this.state || this.isComplete) return;

        this.state = "sending";

        const user = this.user();

        this.manageUserApi.sendInvitation(user.userId).subscribe({
            next: () => {
                this.setStateWithReset("success");
                this.notificationService.success("invitationButton.emailSent", undefined, undefined, true);
                const currentUser = this.user();
                if (currentUser === user || currentUser.userId === user.userId) {
                    currentUser.invitationExpiryDateUtc = undefined; // No expiry means it will display as "Invitation Sent"
                    currentUser.invitationState = UserInvitationState.invitationSent;
                }
            },
            error: () => {
                this.setStateWithReset("error");
                this.notificationService.errorUnexpected();
            },
        });

    };

    private setStateWithReset = (state: InvitationButtonState) => {
        this.state = state;
        setTimeout(() => this.state = undefined, 2000);
    };
}
