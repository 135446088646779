import { Component } from "@angular/core";
import { GetApprovalFeedItemDto } from "@api/model/models";
import { TranslateService } from "@ngx-translate/core";

import { ApprovalType } from "~shared/enums";
import { approvalTypeNameKey } from "~shared/util/translation-helper";

import { BaseFeedListItemDirective } from "../base-feed-list-item.directive";

@Component({
    selector: "app-feed-list-approval",
    templateUrl: "./feed-list-approval.component.html",
    styleUrls: ["./feed-list-approval.component.scss"],
    standalone: false,
})
export class FeedListApprovalComponent extends BaseFeedListItemDirective<GetApprovalFeedItemDto> {

    approvalType = ApprovalType;

    get approvalTypeNameKey() {
        return approvalTypeNameKey(this.feedItem.approvalType);
    }

    get comment() {
        return this.feedItem.comment || "";
    }

    get iconName() {
        switch (this.feedItem.approvalType) {
            case ApprovalType.referred:
                return "arrow_right_alt";
            case ApprovalType.approved:
                return "done_all";
            case ApprovalType.rejected:
                return "priority_high";
            case ApprovalType.noted:
                return "thumb_up";
            default:
                return undefined;
        }
    }

    get teamNameOrDefault() {
        return this.getFeedTeamName() || this.translate.instant("FeedsList.listItems.deletedTeam");
    }

    get positiveChange(): boolean {
        return this.feedItem.approvalType === ApprovalType.approved ||
            this.feedItem.approvalType === ApprovalType.noted;
    }

    // Note this is not the inverse of positive change, as a referred type is neutral.
    get negativeChange(): boolean {
        return this.feedItem.approvalType === ApprovalType.rejected;
    }

    constructor(private readonly translate: TranslateService) {
        super();
    }

    private getFeedTeamName() {
        switch (this.feedItem.approvalType) {
            case ApprovalType.referred:
                return this.feedItem.toTeam?.name;
            case ApprovalType.approved:
            case ApprovalType.rejected:
            case ApprovalType.noted:
                return this.feedItem.fromTeam?.name;
            default:
                return undefined;
        }
    }
}
