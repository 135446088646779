import { Component, Input } from "@angular/core";
import { PlanTierDto } from "@api";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { filter, map } from "rxjs/operators";

import { PlanTierRepository } from "~repositories";
import { TeamContext } from "~services/contexts";
import { PlanSelectionBehavior } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { getPlanName } from "~shared/util/translation-helper";

import {
    allFeatures,
    Feature,
    FeatureConstraint,
    getFeatureAvailabilityNameKey,
    getFeatureConstraintDescription,
    isAvailability,
    isConstraint,
    isFeatureAvailable,
    sortFeature,
    sortPlanOrder
} from "../../plan-tier-features";

const FEATURE_PRIORITY: Feature[] = [
    /* Priority 1: */
    "userCount", "teamCount", "numberCount", "openIssueCount", "widgetCount",
    /* Priority 2: */
    "reportCount", "openNewsCount", "flexibleScheduling", "annualPlanning",
    "recurringActions", "adHocMeetings", "numberChartWidgets",
    /* Priority 3: */
    "companyPerformance", "crossTeamFeatures", "enterpriseWidgets",
    /* Priority 4: */
    "publicHolidays", "executionScoreTarget", "companyLogo",
    /* Priority 5: */
    "enterprisePerformance", "numberApi", "externalIntegrations",
    /* Unspecified: */
    "lineManagerNotifications", "minutesNumberCharts", "solutionApprovals", "delegation",
];

const USE_HIGHEST_PLAN = true;
const DEFAULT_MAX_ITEMS = 9;

@Component({
    selector: "app-plan-comparison",
    templateUrl: "./plan-comparison.component.html",
    styleUrls: ["./plan-comparison.component.scss"],
    standalone: false,
})
export class PlanComparisonComponent extends WithDestroy() {

    @Input() set highlightFeature(value: Feature) {
        this.highlightFeatureSub.next(value);
    }

    get features() {
        return this.featuresSub.value;
    }

    @Input() set features(value: Feature[] | undefined) {
        this.featuresSub.next(value);
    }

    get planCode() {
        return this.planCodeSub.value;
    }

    @Input() set planCode(value: string | undefined) {
        this.planCodeSub.next(value);
    };

    get maxItems(): number {
        return this.maxItemsSub.value;
    }

    @Input() set maxItems(value: number) {
        this.maxItemsSub.next(value);
    }

    get currentPlan(): PlanTierDto | undefined {
        return this.currentPlanSub.value;
    }

    @Input() set currentPlan(value: PlanTierDto | undefined) {
        this.currentPlanSub.next(value);
    }

    readonly currentPlan$: Observable<PlanTierDto | undefined>;

    readonly highlightedPlan$: Observable<PlanTierDto | undefined>;
    readonly changedFeatures$: Observable<Feature[]>;

    readonly getFeatureAvailabilityNameKey = getFeatureAvailabilityNameKey;

    readonly isConstraint = isConstraint;
    readonly isAvailability = isAvailability;

    private readonly allPlans$ = this.planTierRepository.getPlanTiers();

    private readonly highlightFeatureSub = new BehaviorSubject<Feature | undefined>(undefined);
    private readonly featuresSub = new BehaviorSubject<Feature[] | undefined>(undefined);
    private readonly planCodeSub = new BehaviorSubject<string | undefined>(undefined);
    private readonly maxItemsSub = new BehaviorSubject<number>(DEFAULT_MAX_ITEMS);
    private readonly currentPlanSub = new BehaviorSubject<PlanTierDto | undefined>(undefined);

    constructor(
        private readonly teamContext: TeamContext,
        private readonly planTierRepository: PlanTierRepository,
        private readonly translate: TranslateService,
    ) {
        super();
        this.currentPlan$ = combineLatest({
            companyTeam: this.teamContext.companyTeam$,
            overridePlan: this.currentPlanSub,
        }).pipe(
            map(({ companyTeam, overridePlan }) => overridePlan ?? companyTeam?.company?.planTier)
        );

        const currentPlan$ = this.currentPlan$.pipe(filter((p): p is PlanTierDto => !!p));
        const highlightFeature$ = this.highlightFeatureSub.pipe(filter((f): f is Feature => !!f));

        this.highlightedPlan$ = combineLatest([
            currentPlan$,
            this.allPlans$,
            highlightFeature$,
            this.planCodeSub,
        ]).pipe(
            map(([currentPlan, allPlans, highlightFeature, planCode]) =>
                this.chooseHighlightedPlan(currentPlan, allPlans, highlightFeature, planCode)),
            shareReplayUntil(this.destroyed$),
        );

        const chosenFeatures$ = this.featuresSub.pipe(
            map(f => f ?? allFeatures),
        );

        this.changedFeatures$ = combineLatest([
            currentPlan$,
            this.highlightedPlan$,
            highlightFeature$,
            chosenFeatures$,
            this.maxItemsSub,
        ]).pipe(
            map(this.getChangedFeatures),
            shareReplayUntil(this.destroyed$),
        );
    }

    shouldShowFeature = (plan: PlanTierDto, feature: Feature) =>
        isConstraint(feature) || isAvailability(feature) && isFeatureAvailable(plan, feature);

    getFeatureConstraintDescription = (plan: PlanTierDto, feature: FeatureConstraint) =>
        getFeatureConstraintDescription(this.translate, plan, feature);

    getPlanName = (plan: PlanTierDto) => getPlanName(this.translate, plan);

    private chooseHighlightedPlan = (
        currentPlan: PlanTierDto,
        allPlans: PlanTierDto[],
        highlightFeature: Feature,
        planCode: string | undefined,
    ): PlanTierDto | undefined => {
        if (!allPlans.length) return undefined;
        if (planCode) {
            const plan = allPlans.find(p => p.code === planCode);
            if (plan) return plan;
        }

        const featureSorter = sortFeature(highlightFeature).ascending();
        const filteredPlans = allPlans.filter(p => featureSorter(p, currentPlan) > 0);
        if (!filteredPlans.length) return undefined;

        const sortedPlans = filteredPlans.sort(USE_HIGHEST_PLAN ? sortPlanOrder.descending() : sortPlanOrder.ascending());

        const selectablePlan = sortedPlans.find(p => p.selectionBehavior === PlanSelectionBehavior.selectable);
        if (selectablePlan) return selectablePlan;
        return sortedPlans[0];
    };

    private getChangedFeatures = ([currentPlan, highlightedPlan, highlightFeature, chosenFeatures, maxItems]:
        [PlanTierDto, PlanTierDto | undefined, Feature, Feature[], number]): Feature[] => {

        if (!highlightedPlan) return [];
        if (chosenFeatures.includes(highlightFeature)) {
            // We always include the highlight, and it's added to the start.
            chosenFeatures = chosenFeatures.filter(f => f !== highlightFeature);
        }

        const chosenConstraints = chosenFeatures.filter(isConstraint);
        const changedConstraints = chosenConstraints.filter(f => {
            const current = currentPlan.featureConstraints[f];
            const highlighted = highlightedPlan.featureConstraints[f];
            return current !== highlighted;
        });
        const chosenAvailabilities = chosenFeatures.filter(isAvailability);
        const changedAvailabilities = chosenAvailabilities.filter(f => {
            const current = isFeatureAvailable(currentPlan, f);
            const highlighted = isFeatureAvailable(highlightedPlan, f);
            return current !== highlighted;
        });

        const allChangedFeatures: Feature[] = [...changedConstraints, ...changedAvailabilities];

        // Order the features by priority, excluding the highlight feature.
        const priorityFeatures = FEATURE_PRIORITY.filter(f =>
            f !== highlightFeature && allChangedFeatures.includes(f)
        );

        // Take the requested number of changed features, leaving room for the highlight feature.
        const selectedFeatures = priorityFeatures.slice(0, maxItems - 1);

        // Revert to the order specified in the feature list
        const orderedFeatures = chosenFeatures.filter(f => selectedFeatures.includes(f));
        orderedFeatures.unshift(highlightFeature); // insert highlight to start of array
        return orderedFeatures;
    };
}
