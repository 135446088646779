import { Component } from "@angular/core";

@Component({
    selector: "app-spinner",
    template: `<div class="preloader">
        <div class="spinner">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
        </div>
    </div>`,
    standalone: false,
})
export class SpinnerComponent { }
