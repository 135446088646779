import { Component, Input } from "@angular/core";

import { AsyncDataSource } from "~shared/util/async-table-data-source";

@Component({
    selector: "wf-table-scroll-footer",
    template: `
        <mat-spinner *ngIf="isLoadingMore" diameter="40"></mat-spinner>
        <div class="load-more-failed" *ngIf="failedToLoadMore">
            <mat-icon color="warn">error</mat-icon>
            <wf-translate-template key="loadMoreFailed">
                <a href="javascript:void(0)" *wfTransTplContent="'retry'; let content" 
                    (click)="retryLoadMore()">{{ content }}</a>
            </wf-translate-template>
        </div>
    `,
    styleUrls: ["./table-scroll-footer.component.scss"],
    standalone: false,
})
export class TableScrollFooterComponent {

    get isLoadingMore(): boolean {
        return !!this.dataSource && this.dataSource.isLoadingMore;
    }

    get failedToLoadMore(): boolean {
        return !!this.dataSource && this.dataSource.loadMoreFailed;
    }

    @Input() dataSource?: AsyncDataSource<any, any, any, any>;

    retryLoadMore = () => {
        this.dataSource?.retryLoadMore();
    }
}
