import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ActionsV2Api, GetTeamWatchlistDto, PlanGoalsApi, PlanNumbersApi, PlanReportsApi, TeamWatchlistItemsDto, TeamWatchlistsApi, WatchlistItemReferenceDto } from "@api";
import { Observable } from "rxjs";

import { CompanyMenuRepository, QuarterContextRepository } from "~repositories";
import { NotificationService } from "~services/notification.service";

import { BaseAddWatchlistItemsDialogComponent } from "../base-add-watchlist-items-dialog/base-add-watchlist-items-dialog.component";

interface AddTeamWatchlistItemsDialogData {
    readonly list: GetTeamWatchlistDto;
    readonly items: TeamWatchlistItemsDto;
}

@Component({
    selector: "app-add-team-watchlist-items-dialog",
    templateUrl: "../base-add-watchlist-items-dialog/base-add-watchlist-items-dialog.component.html",
    styleUrls: ["../base-add-watchlist-items-dialog/base-add-watchlist-items-dialog.component.scss"],
    providers: [QuarterContextRepository],
    standalone: false,
})
export class AddTeamWatchlistItemsDialogComponent
    extends BaseAddWatchlistItemsDialogComponent<GetTeamWatchlistDto, AddTeamWatchlistItemsDialogData> {

    constructor(
        private readonly teamWatchlistsApi: TeamWatchlistsApi,
        companyMenuRepository: CompanyMenuRepository,
        actionsApi: ActionsV2Api,
        planGoalsApi: PlanGoalsApi,
        planNumbersApi: PlanNumbersApi,
        planReportsApi: PlanReportsApi,
        notificationService: NotificationService,
        @Inject(MAT_DIALOG_DATA) data: AddTeamWatchlistItemsDialogData,
    ) {
        super(companyMenuRepository, actionsApi, planGoalsApi, planNumbersApi, planReportsApi, notificationService, data);

        this.teamControl.setValue({
            id: data.list.team.id,
            name: data.list.team.name,
            company: {
                id: data.list.company.id,
                clientId: data.list.company.clientId,
                name: data.list.company.name,
            },
        });
    }

    static open(dialog: MatDialog, items: TeamWatchlistItemsDto) {
        return this.openInternal(AddTeamWatchlistItemsDialogComponent, dialog, {
            list: items.list,
            items,
        });
    }

    protected setWatched = (item: WatchlistItemReferenceDto, watched: boolean): Observable<unknown> => {
        if (watched) {
            return this.teamWatchlistsApi.watchItem(this.list.company.id, this.list.team.id, this.list.id, item);
        } else {
            return this.teamWatchlistsApi.unwatchItem(this.list.company.id, this.list.team.id, this.list.id, item);
        }
    };
}
