import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatOption } from "@angular/material/core";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldAppearance, MatFormFieldDefaultOptions } from "@angular/material/form-field";
import { BehaviorSubject, combineLatest, Observable, Subscription } from "rxjs";
import { map, skip } from "rxjs/operators";

import { getUserName } from "~shared/util/user-helper";

import { userOrEmailValidator } from "./user-email-selector.validator";

export interface IUserOrEmail {
    user?: IUser;
    email?: string;
}

export interface IUser {
    userId: string;
    firstName: string;
    lastName: string;
}

@Component({
    selector: "app-user-email-selector",
    templateUrl: "./user-email-selector.component.html",
    styleUrls: ["./user-email-selector.component.scss"],
    standalone: false,
})
export class UserEmailSelectorComponent implements OnInit, OnDestroy {

    @Input() label!: string;
    @Input() hint?: string;
    @Input() appearance: MatFormFieldAppearance;

    @Input() set users(value: IUser[] | undefined | null) {
        this.usersSubject.next(value ?? []);
    }

    @Output() userChange = new EventEmitter<IUserOrEmail>();
    @Output() touched = new EventEmitter<void>();

    inputField = new UntypedFormControl(null, [userOrEmailValidator]);

    filteredUsers$: Observable<IUser[]>;

    user?: IUser;
    email?: string;

    readonly getUserName = getUserName;

    private usersSubject = new BehaviorSubject<IUser[]>([]);
    private userDetailsSubject = new BehaviorSubject<IUserOrEmail>({});

    private inputFieldSubscription?: Subscription;
    private userDetailsSubscription?: Subscription;

    constructor(
        @Optional() @Inject(MAT_FORM_FIELD_DEFAULT_OPTIONS) _defaults: MatFormFieldDefaultOptions | null,
    ) {
        this.appearance = _defaults?.appearance ?? "outline";
        this.filteredUsers$ = combineLatest([
            this.usersSubject,
            this.inputField.valueChanges
        ]).pipe(
            map(this.filterUsers)
        );
    }

    ngOnInit() {
        this.inputFieldSubscription = this.inputField.valueChanges.subscribe((value) => {
            if (typeof value === "string") {
                this.userDetailsSubject.next({
                    email: value || undefined
                });
            } else {
                this.userDetailsSubject.next(value);
            }
        });

        this.userDetailsSubscription = this.userDetailsSubject.pipe(skip(1)).subscribe(user => {
            this.userChange.emit(user);
        });
    }

    ngOnDestroy() {
        this.inputFieldSubscription?.unsubscribe();
        this.userDetailsSubscription?.unsubscribe();
    }

    setValue(value: IUserOrEmail) {
        this.inputField.setValue(value);
    }

    getUserOrEmailDisplay = (data?: IUserOrEmail) => {
        if (!data) return "";
        if (data.user) {
            return getUserName(data.user);
        }
        return data.email ?? "";
    };

    optionSelected = (option: MatOption) => {
        const user: IUser = option.value;
        this.userDetailsSubject.next({ user });
    };

    autoClosed = () => {
        this.inputField.setValue(this.userDetailsSubject.value);
    };

    onBlur = (isAutoOpen: boolean) => {
        if (!isAutoOpen) this.autoClosed();
        this.touched.emit();
    };

    private filterUsers = (input: [IUser[], string]): IUser[] => {
        const users = input[0];
        const filter = (input[1] || "").toString().toLowerCase();
        return users.filter(user => getUserName(user).toLowerCase().includes(filter));
    };

}

