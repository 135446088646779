import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GetActionDto } from "@api";
import { BehaviorSubject, combineLatest, Observable, of, switchMap } from "rxjs";

import { AccessService, AccessState } from "~services/access.service";
import { DeleteActionDialogComponent, EditActionDialogComponent } from "~shared/dialogs";
import { WithDestroy } from "~shared/mixins";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { shareReplayUntil } from "~shared/util/rx-operators";

@Component({
    selector: "app-edit-action-menu",
    templateUrl: "./edit-action-menu.component.html",
    styleUrls: ["./edit-action-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class EditActionMenuComponent extends WithDestroy() {

    @Input() set action(value: GetActionDto | undefined) {
        this.actionSubject.next(value);
    }

    get action(): GetActionDto | undefined {
        return this.actionSubject.value;
    }

    @Input() set disabled(value: boolean) {
        this.disabledSubject.next(coerceBooleanProperty(value));
    }

    get disabled(): boolean {
        return this.disabledSubject.value;
    }

    @Input() disableCopy = false;

    @Output() updated = new EventEmitter<GetActionDto>();
    @Output() deleted = new EventEmitter<void>();
    @Output() copied = new EventEmitter<void>();

    readonly access$: Observable<AccessState>;

    private readonly actionSubject = new BehaviorSubject<GetActionDto | undefined>(undefined);
    private readonly disabledSubject = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly accessService: AccessService,
        private readonly dialog: MatDialog,
    ) {
        super();

        this.access$ = combineLatest({
            disabled: this.disabledSubject,
            action: this.actionSubject,
        }).pipe(
            switchMap(({ disabled, action }) => {
                if (disabled || !action) return of(AccessState.disabled);
                const { company, team } = getDelegatedItemCompanyTeam(action);
                return this.accessService.getAccessState(company.id, team.id);
            }),
            shareReplayUntil(this.destroyed$),
        );
    }

    editAction = (access: AccessState) => {
        if (!this.action || this.disabled || !access.canEdit || this.action.isDelegated) return;
        EditActionDialogComponent.openForEdit(this.dialog, {
            action: this.action,
        }).afterClosed().subscribe(res => {
            if (res && res.type === "updated") {
                this.updated.emit(res.action);
            }
        });
    };

    viewAction = () => {
        if (!this.action) return;
        EditActionDialogComponent.openForEdit(this.dialog, {
            action: this.action,
            readonly: true
        });
    };

    copyAction = (access: AccessState) => {
        if (!this.action || this.disabled || this.disableCopy || !access.canEdit) return;
        EditActionDialogComponent.openForEdit(this.dialog, {
            action: this.action,
            isCopy: true,
        }).afterClosed().subscribe(res => res && this.copied.emit());
    };

    deleteAction = (access: AccessState) => {
        if (!this.action || this.disabled || !access.canDelete || this.action.isDelegated) return;
        DeleteActionDialogComponent.open(this.dialog, this.action)
            .afterClosed().subscribe(res => res && this.deleted.emit());
    };

    /* eslint-disable @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
    static ngAcceptInputType_action: GetActionDto;
    static ngAcceptInputType_disabled: BooleanInput;
    /* eslint-enable  @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
}
