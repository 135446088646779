import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ReportExceededDialogComponent } from "../../dialogs";

@Component({
    selector: "app-report-exceeded-header",
    templateUrl: "./report-exceeded-header.component.html",
    styleUrls: ["./report-exceeded-header.component.scss"],
    standalone: false,
})
export class ReportExceededHeaderComponent {

    constructor(private readonly matDialog: MatDialog) { }

    openDialog = () => {
        ReportExceededDialogComponent.open(this.matDialog);
    };
}
