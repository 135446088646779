import { Directive } from "@angular/core";

@Directive({
    selector: "a[wfEmptyLink]",
    host: {
        href: "javascript:void(0)",
    },
    standalone: false,
})
export class EmptyLinkDirective { }
