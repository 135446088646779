import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GetActionDto } from "@api";

import { TeamContext } from "~services/contexts";
import { ActionStateService } from "~services/state";
import { mapActionActionInput } from "~shared/util/action-input-builder";
import { mapActionOrigin } from "~shared/util/origin-builder";

import { CommonAddChildActionButtonDirective } from "./add-child-action-button.component";

@Component({
    selector: "app-add-action-child-action-button",
    templateUrl: "./add-child-action-button.component.html",
    styleUrls: ["./add-child-action-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class AddActionChildActionButtonComponent extends CommonAddChildActionButtonDirective<GetActionDto> {

    readonly mapToOrigin = mapActionOrigin;
    readonly mapToInput = mapActionActionInput;

    constructor(
        teamContext: TeamContext,
        dialog: MatDialog,
        private readonly actionStateService: ActionStateService,
    ) {
        super(teamContext, dialog);
    }

    protected notifyChange = (source: GetActionDto) => this.actionStateService.notifyRefresh(source);

    protected creationAllowed = (source: GetActionDto): boolean => !source.isPrivateAction;
}
