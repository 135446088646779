import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { GetActionDto, SimpleCompanyTeamDto, SimpleUserDto } from "@api";
import { Subscription } from "rxjs";

import { ActionHomepageDialogComponent } from "~homepage";
import {
    ActionReference, ActionStateEvent, ActionStateService, buildSimpleMergeHandler, compareByDelegatedTeamAndId,
    compareReferenceByDelegatedTeamAndId
} from "~services/state";
import { getActionProgressSortOrder } from "~shared/action-progress";
import { defaultActionsFilterPredicate } from "~shared/util/table-filtering";
import { getUserName } from "~shared/util/user-helper";

export interface ViewOverdueActionsDialogData {
    team: SimpleCompanyTeamDto;
    actions: GetActionDto[];
    financialYear: number;
    quarter: number;
    week: number;
}

@Component({
    selector: "app-view-overdue-actions-dialog",
    templateUrl: "./view-overdue-actions-dialog.component.html",
    styleUrls: ["./view-overdue-actions-dialog.component.scss"],
    standalone: false,
})
export class ViewOverdueActionsDialogComponent implements OnInit, OnDestroy {

    @ViewChild(MatSort, { static: true }) set sort(value: MatSort | null) {
        this.dataSource.sort = value;
    }

    get sort(): MatSort | null {
        return this.dataSource.sort;
    }

    readonly dataSource = new MatTableDataSource<GetActionDto>;
    readonly columns = ["description", "owner", "dueDate", "status", "options"];

    readonly team: SimpleCompanyTeamDto;
    readonly financialYear: number;
    readonly quarter: number;
    readonly week: number;

    readonly getUserName = getUserName;

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly actionStateService: ActionStateService,
        private readonly dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) data: ViewOverdueActionsDialogData,
    ) {
        this.dataSource.data = data.actions;
        this.team = data.team;
        this.financialYear = data.financialYear;
        this.quarter = data.quarter;
        this.week = data.week;

        this.dataSource.filterPredicate = defaultActionsFilterPredicate;

        this.dataSource.sortingDataAccessor = (action, property) => {
            switch (property) {
                case "owner": return getUserName(action.owner);
                case "status": return getActionProgressSortOrder(action.progress);
                default:
                    return (action as never)[property] ?? "";
            }
        };
    }

    static open(dialog: MatDialog, data: ViewOverdueActionsDialogData) {
        return dialog.open(ViewOverdueActionsDialogComponent, {
            maxWidth: "1250px",
            data,
            autoFocus: "first-heading",
        });
    }

    ngOnInit(): void {
        this.subscriptions.add(this.actionStateService.eventsForActions(...this.dataSource.data).subscribe(this.handleStateEvent));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    applyOwnerFilter = (user: SimpleUserDto | null) => this.dataSource.filter = user?.userId ?? "";

    viewAction = (action: GetActionDto, focusFeed = false) =>
        ActionHomepageDialogComponent.open(this.dialog, action, { focusFeed });

    openFeed = (action: GetActionDto) => this.viewAction(action, /* focusFeed: */ true);

    private handleStateEvent = (event: ActionStateEvent) => {
        const data = this.dataSource.data;
        const mergeHandler = buildSimpleMergeHandler<GetActionDto, ActionReference>(
            compareByDelegatedTeamAndId, compareReferenceByDelegatedTeamAndId);
        const newData = mergeHandler(data, event);
        if (newData) this.dataSource.data = newData;
    };

}
