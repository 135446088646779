/* eslint-disable max-classes-per-file */
import { Directive, Input, TemplateRef } from "@angular/core";

import { TableSettings } from "~services/table-settings.service";

export interface TableSettingsExtraContext<TKey extends string = string, TSettings extends TableSettings<TKey> = TableSettings<TKey>> {
    $implicit: TSettings;
}

@Directive({
    selector: "[appTableSettingsHeader]",
    standalone: false,
})
export class TableSettingsHeaderDirective<TKey extends string = string, TSettings extends TableSettings<TKey> = TableSettings<TKey>> {

    @Input() appTableSettingsHeaderTableSettings: TSettings | null = null; // used only to assert the correct type only

    constructor(public template: TemplateRef<TableSettingsExtraContext<TKey, TSettings>>) { }

    static ngTemplateContextGuard<TKey extends string = string, TSettings extends TableSettings<TKey> = TableSettings<TKey>>(
        dir: TableSettingsHeaderDirective<TKey, TSettings>, ctx: any):
        ctx is TableSettingsExtraContext<TKey, TSettings> {
        return true;
    }
}
