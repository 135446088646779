import { Component, ContentChild, Input, Optional, TemplateRef, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

import { FeedListComponent } from "~feed/components";

import { HomepageActionsDirective } from "./homepage-actions.directive";
import { HomepageBodyDirective } from "./homepage-body.directive";
import { HomepageTitleDirective } from "./homepage-title.directive";
import { HomepageTitleActionsDirective } from "./homepage-title-actions.directive";

@Component({
    selector: "app-homepage-scaffold",
    templateUrl: "./homepage-scaffold.component.html",
    styleUrls: ["./homepage-scaffold.component.scss"],
    standalone: false,
})
export class HomepageScaffoldComponent {

    @Input() title: string | null = null;

    @ContentChild(HomepageTitleDirective, { read: TemplateRef, static: false }) titleTemplate?: TemplateRef<unknown>;
    @ContentChild(HomepageTitleActionsDirective, { read: TemplateRef, static: false }) titleActionsTemplate?: TemplateRef<unknown>;
    @ContentChild(HomepageActionsDirective, { read: TemplateRef, static: false }) actionsTemplate?: TemplateRef<unknown>;
    @ContentChild(HomepageBodyDirective, { read: TemplateRef, static: false }) bodyTemplate?: TemplateRef<unknown>;

    @ViewChild(FeedListComponent) feedList?: FeedListComponent;

    get showCloseButton() {
        return !!this.dialogRef && !this.dialogRef.disableClose;
    }

    constructor(
        @Optional() private readonly dialogRef?: MatDialogRef<any>,
    ) {
    }

    refreshFeed = () => {
        this.feedList?.refresh();
    };
}
