import { Component, Inject, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GetNewsItemDto } from "@api";
import { Observable, Subject } from "rxjs";

import { HomepageDialog, HomepageEvent } from "../homepage-dialog.interface";
import { buildHomepageDialogConfig, HomepageDialogOptions } from "../homepage-dialog-shared";

interface NewsItemHomepageDialogData {
    newsItem: GetNewsItemDto;
}

@Component({
    selector: "app-news-item-homepage-dialog",
    templateUrl: "./news-item-homepage-dialog.component.html",
    styleUrls: ["./news-item-homepage-dialog.component.scss"],
    standalone: false,
})
export class NewsItemHomepageDialogComponent implements HomepageDialog<GetNewsItemDto>, OnDestroy {

    readonly events$: Observable<HomepageEvent<GetNewsItemDto>>;

    newsItem: GetNewsItemDto;

    private readonly eventsInternal = new Subject<HomepageEvent<GetNewsItemDto>>();

    constructor(
        private readonly dialogRef: MatDialogRef<NewsItemHomepageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: NewsItemHomepageDialogData,
    ) {
        this.events$ = this.eventsInternal.asObservable();
        this.newsItem = data.newsItem;
    }

    static open(dialog: MatDialog, newsItem: GetNewsItemDto, options?: HomepageDialogOptions) {
        return dialog.open<NewsItemHomepageDialogComponent, NewsItemHomepageDialogData>(
            NewsItemHomepageDialogComponent,
            {
                data: { newsItem },
                ...buildHomepageDialogConfig(options),
            });
    }

    ngOnDestroy(): void {
        this.eventsInternal.complete();
    }

    newsItemUpdated = (newsItem: GetNewsItemDto) => {
        this.newsItem = newsItem;
        this.eventsInternal.next({ type: "updated", item: newsItem });
    };

    newsItemDeleted = () => {
        this.eventsInternal.next({ type: "deleted", item: this.newsItem });
        this.dialogRef.close();
    };
}
