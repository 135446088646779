import { Component, Input, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DiscussionAndSolutionDto } from "@api";

import { getDiscussionStatusSortOrder } from "~shared/discussion-status";
import { getUserName } from "~shared/util/user-helper";

import { DiscussionHomepageDialogComponent } from "../../dialogs";

@Component({
    selector: "app-related-discussions-table",
    templateUrl: "./related-discussions-table.component.html",
    styleUrls: ["./related-discussions-table.component.scss"],
    standalone: false,
})
export class RelatedDiscussionsTableComponent {

    @Input() set discussions(value: DiscussionAndSolutionDto[]) {
        this.dataSource.data = value;
    }

    get discussions(): DiscussionAndSolutionDto[] {
        return this.dataSource.data;
    }

    @ViewChild(MatSort, { static: true }) set sort(value: MatSort | null) {
        this.dataSource.sort = value;
    }

    get sort(): MatSort | null {
        return this.dataSource.sort;
    }

    readonly dataSource = new MatTableDataSource<DiscussionAndSolutionDto>();
    readonly displayedColumns = ["heading", "creator", "dateIdentified", "status"];

    readonly getUserName = getUserName;

    constructor(
        private readonly dialog: MatDialog,
    ) {
        this.dataSource.sortingDataAccessor = (data: DiscussionAndSolutionDto, sortHeaderId: string) => {
            switch (sortHeaderId) {
                case "heading": return data.heading;
                case "description": return data.description ?? "";
                case "creator": return getUserName(data.creator);
                case "dateIdentified": return data.dateIdentified;
                case "status": return getDiscussionStatusSortOrder(data.status);
                default:
                    return (data as never)[sortHeaderId] ?? "";
            }
        };
    }

    view = (discussion: DiscussionAndSolutionDto) => DiscussionHomepageDialogComponent.openForDiscussion(this.dialog, discussion);

}
