import { Directive, InjectionToken } from "@angular/core";

export const HOMEPAGE_BODY = new InjectionToken<HomepageBodyDirective>("HomepageBody");

@Directive({
    selector: "[appHomepageBody]",
    providers: [{ provide: HOMEPAGE_BODY, useExisting: HomepageBodyDirective }],
    standalone: false,
})
export class HomepageBodyDirective { }
