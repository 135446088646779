import { Directive, InjectionToken } from "@angular/core";

export const HOMEPAGE_TITLE = new InjectionToken<HomepageTitleDirective>("HomepageTitle");

@Directive({
    selector: "[appHomepageTitle]",
    providers: [{ provide: HOMEPAGE_TITLE, useExisting: HomepageTitleDirective }],
    standalone: false,
})
export class HomepageTitleDirective { }
