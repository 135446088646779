
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2 } from "@angular/core";

import { AccessService } from "~services/access.service";
import { TeamContext } from "~services/contexts";

const DISABLED = "disabled";
const APP_DISABLED = "app-disabled";
const TAB_INDEX = "tabindex";
const TAG_ANCHOR = "a";

@Directive({
    selector: "[appDisable]",
    standalone: false,
})
export class DisableDirective implements OnChanges, AfterViewInit {
    @Input() appDisable = false;

    get requireAdmin(): boolean {
        return this.requireAdminInternal;
    }

    @Input() set requireAdmin(value: boolean) {
        this.requireAdminInternal = coerceBooleanProperty(value);
    }

    /**
     * @deprecated Use requireAdmin instead
     */
    @Input() set isEditPermissionValid(value: boolean) {
        this.requireAdmin = value;
    }

    private get shouldDisable(): boolean {
        // Either explicitly disabled,
        return this.appDisable ||
            // or the current user does not have write access to the current page
            !this.accessService.canWriteCurrentPage() ||
            // or we require admin access and the current user cannot manage the current company.
            (this.requireAdmin && !this.teamContext.company()?.canManage);
    }

    private requireAdminInternal = false;

    constructor(
        private readonly eleRef: ElementRef,
        private readonly renderer: Renderer2,
        private readonly accessService: AccessService,
        private readonly teamContext: TeamContext) {
    }

    ngOnChanges() {
        this.disableElement(this.eleRef.nativeElement, this.shouldDisable);
    }

    ngAfterViewInit(): void {
        this.disableElement(this.eleRef.nativeElement, this.shouldDisable);
    }

    private disableElement(element: Element, shouldDisable: boolean) {
        if (shouldDisable) {
            if (!element.hasAttribute(DISABLED)) {
                this.renderer.setAttribute(element, APP_DISABLED, "");
                this.renderer.setAttribute(element, DISABLED, "true");

                // disabling anchor tab keyboard event
                if (element.tagName.toLowerCase() === TAG_ANCHOR) {
                    this.renderer.setAttribute(element, TAB_INDEX, "-1");
                }
            }
        } else {
            if (element.hasAttribute(APP_DISABLED)) {
                if (element.getAttribute(DISABLED) !== "") {
                    element.removeAttribute(DISABLED);
                }
                element.removeAttribute(APP_DISABLED);
                if (element.tagName.toLowerCase() === TAG_ANCHOR) {
                    element.removeAttribute(TAB_INDEX);
                }
            }
        }
        if (element.children) {
            const children = element.children;
            // HTMLCollection is not an iterator, so cannot be iterated with for-of
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < children.length; i++) {
                const ele = children[i];
                this.disableElement(ele, shouldDisable);
            }
        }
    }

    /* eslint-disable @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
    static ngAcceptInputType_requireAdmin: BooleanInput;
    static ngAcceptInputType_isEditPermissionValid: BooleanInput;
    /* eslint-enable @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
}
