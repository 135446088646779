import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component, forwardRef, Input, OnDestroy, OnInit } from "@angular/core";
import { ControlValueAccessor, FormBuilder, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from "@angular/forms";
import { FrequencyApi, RecurrenceDto } from "@api";
import * as moment from "moment";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from "rxjs/operators";

import { DayOfWeek, RecurrenceType } from "~shared/enums";
import { retryWithDelay } from "~shared/util/caching";
import {
    getDayOfWeekNameKey,
    getIndicesNameKey,
    getRecurrenceTypeNameKey,
    getRecurrenceTypePluralNameKey
} from "~shared/util/translation-helper";
import { getCurrentDayOfWeek } from "~shared/util/util";

const todayMoment = () => moment().startOf("day").utc(true);
const todayLocalString = () => todayMoment().toISOString();

const recurrenceDefinitionEqual = (left: RecurrenceDto, right: RecurrenceDto) =>
    left.type === right.type &&
    left.interval === right.interval &&
    left.index === right.index &&
    left.dayOfWeek === right.dayOfWeek;


const getDefaultRecurrence = (): RecurrenceDto => ({
    referenceDate: todayLocalString(),
    type: RecurrenceType.weekly,
    interval: 1,
    dayOfWeek: getCurrentDayOfWeek()
});

@Component({
    selector: "app-recurrence-control",
    templateUrl: "./recurrence-control.component.html",
    styleUrls: ["./recurrence-control.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            useExisting: forwardRef(() => RecurrenceControlComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            useExisting: forwardRef(() => RecurrenceControlComponent),
            multi: true
        }
    ],
    standalone: false,
})
export class RecurrenceControlComponent implements ControlValueAccessor, OnInit, OnDestroy {

    get scheduleVisible(): boolean {
        return this.scheduleVisibleInternal;
    }

    @Input() set scheduleVisible(value: boolean) {
        value = coerceBooleanProperty(value);
        this.scheduleVisibleInternal = value;
        if (!value) {
            this.resetToWeekly();
        }
    }

    get scheduleEnabled(): boolean {
        return this.scheduleEnabledInternal;
    }

    @Input() set scheduleEnabled(value: boolean) {
        value = coerceBooleanProperty(value);
        this.scheduleEnabledInternal = value;
        if (!value) {
            this.resetToWeekly();
        }
    }

    get extended(): boolean {
        return this.extendedInternal;
    }

    @Input() set extended(value: BooleanInput) {
        value = coerceBooleanProperty(value);
        this.extendedInternal = value;
        this.bindIntervals(this.typeControl.value, value);
    }

    readonly intervalControl = this.fb.nonNullable.control(1, [Validators.required]);
    readonly typeControl = this.fb.nonNullable.control(RecurrenceType.weekly, [Validators.required]);
    readonly dayOfWeekControl = this.fb.nonNullable.control(DayOfWeek.monday, [Validators.required]);
    readonly indexControl = this.fb.control<number | null>(null);
    readonly nextScheduledControl = this.fb.nonNullable.control(todayMoment(), [Validators.required]);

    readonly form = this.fb.group({
        interval: this.intervalControl,
        type: this.typeControl,
        dayOfWeek: this.dayOfWeekControl,
        index: this.indexControl,
        nextScheduled: this.nextScheduledControl
    });

    readonly recurrenceTypes = [RecurrenceType.weekly, RecurrenceType.monthlyWeekDay];
    intervalOptions: number[] = [];
    readonly indexOptions: number[] = [1, 2, 3, 4, 5];
    readonly dayOptions: DayOfWeek[] = [0, 1, 2, 3, 4, 5, 6];

    nextScheduledDateOptions: moment.Moment[] = [];

    getRecurrenceTypeNameKey = getRecurrenceTypeNameKey;
    getRecurrenceTypePluralNameKey = getRecurrenceTypePluralNameKey;
    getIndicesNameKey = getIndicesNameKey;
    getDayOfWeekNameKey = getDayOfWeekNameKey;

    get value(): RecurrenceDto {
        return this.getFormValue();
    }

    set value(value: RecurrenceDto) {
        this.bindRecurrence(value);
    }

    loadingPossibleDates = false;

    private onChangedCallback?: (_: RecurrenceDto | null) => void;
    private onTouchedCallback?: () => void;

    private subscriptions: Subscription[] = [];

    private recurrenceSubject = new Subject<RecurrenceDto>();

    private scheduleVisibleInternal = true;
    private scheduleEnabledInternal = true;
    private extendedInternal = false;

    constructor(private readonly fb: FormBuilder, private readonly frequencyApi: FrequencyApi) { }

    ngOnInit(): void {
        this.subscriptions.push(this.form.valueChanges.pipe(map(this.getFormValue)).subscribe(this.onValueChanged));
        this.subscriptions.push(this.typeControl.valueChanges.subscribe(this.typeChange));
        this.subscriptions.push(this.recurrenceSubject.pipe(
            distinctUntilChanged(recurrenceDefinitionEqual),
            debounceTime(50),
            tap(() => this.loadingPossibleDates = true),
            switchMap(dto =>
                this.frequencyApi.getPossibleRecurrenceDates({
                    definition: dto,
                    effectiveDate: todayLocalString()
                }).pipe(
                    retryWithDelay(),
                    map(dates => ({ dto, dates }))
                )
            ),
            tap(() => this.loadingPossibleDates = false),
            map(data => ({ dto: data.dto, dates: data.dates.map(d => moment(d)) })),
            tap(data => this.nextScheduledDateOptions = data.dates),
            tap(data => this.replaceReferenceDate(data.dto, data.dates))
        ).subscribe());
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    writeValue(obj: RecurrenceDto): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChangedCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    validate = (_: FormControl) => this.form.valid ? null : { recurrence: { valid: false } };

    isMonthly = () => this.typeControl.value === RecurrenceType.monthlyWeekDay;
    isWeekly = () => this.typeControl.value === RecurrenceType.weekly;

    private onValueChanged = (value: RecurrenceDto) => {
        this.recurrenceSubject.next(value);
        this.onChangedCallback?.(value);
        this.onTouchedCallback?.();
    };

    private getFormValue = (): RecurrenceDto => {
        const type: RecurrenceType = this.typeControl.value;
        const interval: number = this.intervalControl.value;
        const dto: RecurrenceDto = {
            type: type,
            interval: interval,
            referenceDate: (this.nextScheduledControl.value as moment.Moment).toISOString()
        };

        switch (type) {
            case RecurrenceType.daily:
                break;
            case RecurrenceType.weekly:
                dto.dayOfWeek = this.dayOfWeekControl.value;
                break;
            case RecurrenceType.monthlyWeekDay:
                dto.dayOfWeek = this.dayOfWeekControl.value;
                dto.index = this.indexControl.value ?? 1;
                break;
        }
        return dto;
    };

    private bindRecurrence = (dto: RecurrenceDto) => {

        if (!dto) {
            dto = getDefaultRecurrence();
        }

        this.nextScheduledControl.setValue(moment(dto.referenceDate));
        if (this.scheduleEnabled && this.scheduleVisible) {
            this.typeControl.setValue(dto.type);
            let interval = dto.interval ?? 1;
            if (interval < 1) interval = 1;
            this.intervalControl.setValue(interval);
            this.indexControl.setValue(dto.index ?? 1);
        }
        this.dayOfWeekControl.setValue(dto.dayOfWeek ?? DayOfWeek.monday);

        this.typeChange(dto.type);
    };

    private typeChange = (type: RecurrenceType) => {
        if (type === RecurrenceType.monthlyWeekDay && !this.indexControl.value) {
            this.indexControl.setValue(1);
        }
        this.bindIntervals(type, this.extended);
        this.updateValidators(type);
    };

    private updateValidators = (type: RecurrenceType) => {
        switch (type) {
            case RecurrenceType.daily:
                this.dayOfWeekControl.setValidators([]);
                this.indexControl.setValidators([]);
                break;

            case RecurrenceType.weekly:
                this.dayOfWeekControl.setValidators([Validators.required]);
                this.indexControl.setValidators([]);
                break;

            case RecurrenceType.monthlyWeekDay:
                this.dayOfWeekControl.setValidators([Validators.required]);
                this.indexControl.setValidators([Validators.required]);
                break;
        }
    };

    private bindIntervals = (type: RecurrenceType, extended: boolean = false) => {
        const currentInterval: number = this.intervalControl.value;
        const maxInterval = this.getMaxInterval(type, extended);
        const intervals = new Array(maxInterval).fill(0).map((_, i) => i + 1);
        this.intervalOptions = intervals;

        if (currentInterval > maxInterval) {
            this.intervalControl.setValue(1);
        }
    };

    private getMaxInterval = (type: RecurrenceType, extended: boolean) => {
        switch (type) {
            case RecurrenceType.daily:
                return 28;
            case RecurrenceType.weekly:
                return extended ? 52 : 13;
            case RecurrenceType.monthlyWeekDay:
                return extended ? 24 : 3;
        }
    };

    private replaceReferenceDate = (dto: RecurrenceDto, dates: moment.Moment[]) => {
        const refDate = moment(dto.referenceDate);
        const index = dates.findIndex(d => refDate.isSame(d));
        if (index >= 0) {
            this.nextScheduledControl.setValue(dates[index]);
            return;
        }

        switch (dto.type) {
            case RecurrenceType.daily:
                // This behaviour is not really well defined.
                this.nextScheduledControl.setValue(dates[dates.length - 1]);
                return;
            case RecurrenceType.weekly: {
                // ISO Week starts on monday. We do the same here.
                const startOfWeeks = dates.map(d => d.clone().startOf("isoWeek"));
                const refStartOfWeek = refDate.clone().startOf("isoWeek");
                const weekIndex = startOfWeeks.findIndex(d => refStartOfWeek.isSame(d));
                if (weekIndex >= 0) {
                    // If one of the options is in the same week as the current reference date, use that one.
                    this.nextScheduledControl.setValue(dates[weekIndex]);
                } else {
                    // Otherwise, use the last date as the reference date
                    // Note: This either occurs if the selected date for this week is in the past, or if we have fewer items in the list.
                    //       In either case, choosing the last item is correct.
                    this.nextScheduledControl.setValue(dates[dates.length - 1]);
                }
                break;
            }
            case RecurrenceType.monthlyWeekDay: {
                const startOfMonths = dates.map(d => d.clone().startOf("month"));
                const refStartOfMonth = refDate.clone().startOf("month");
                const monthIndex = startOfMonths.findIndex(d => refStartOfMonth.isSame(d));
                // Logic is the same for weeks.
                if (monthIndex >= 0) {
                    this.nextScheduledControl.setValue(dates[monthIndex]);
                } else {
                    this.nextScheduledControl.setValue(dates[dates.length - 1]);
                }
                break;
            }
        }
    };

    private resetToWeekly = () => {
        if (this.intervalControl.value !== 1) this.intervalControl.setValue(1);
        if (this.typeControl.value !== RecurrenceType.weekly) this.typeControl.setValue(RecurrenceType.weekly);
    };

    /* eslint-disable @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
    static ngAcceptInputType_scheduleVisible: BooleanInput;
    static ngAcceptInputType_scheduleEnabled: BooleanInput;
    /* eslint-enable @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */

}
