import { Component, Inject, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Observable, Subject } from "rxjs";

import { NumberDto } from "../../components/number-homepage/number-homepage.component";
import { HomepageDialog, HomepageEvent } from "../homepage-dialog.interface";
import { buildHomepageDialogConfig, HomepageDialogOptions } from "../homepage-dialog-shared";

interface NumberHomepageDialogData<TNumber extends NumberDto> {
    number: TNumber;
    allowEdit?: boolean;
}

type NumberHomepageDialogOptions = HomepageDialogOptions & {
    allowEdit?: boolean;
};

@Component({
    selector: "app-number-homepage-dialog",
    templateUrl: "./number-homepage-dialog.component.html",
    styleUrls: ["./number-homepage-dialog.component.scss"],
    standalone: false,
})
export class NumberHomepageDialogComponent<TNumber extends NumberDto> implements HomepageDialog<TNumber>, OnDestroy {

    readonly events$: Observable<HomepageEvent<TNumber>>;

    number: TNumber;

    readonly allowEdit: boolean;

    private readonly initialNumber: TNumber;

    private readonly eventsInternal = new Subject<HomepageEvent<TNumber>>();

    constructor(
        private readonly dialogRef: MatDialogRef<NumberHomepageDialogComponent<TNumber>>,
        @Inject(MAT_DIALOG_DATA) data: NumberHomepageDialogData<TNumber>,
    ) {
        this.events$ = this.eventsInternal.asObservable();
        this.initialNumber = data.number;
        this.number = data.number;
        this.allowEdit = data.allowEdit ?? false;
    }

    static open<TNumber extends NumberDto>(dialog: MatDialog, number: TNumber, options?: NumberHomepageDialogOptions) {
        return dialog.open<NumberHomepageDialogComponent<TNumber>, NumberHomepageDialogData<TNumber>>(
            NumberHomepageDialogComponent,
            {
                data: { number, allowEdit: options?.allowEdit },
                ...buildHomepageDialogConfig(options),
            });
    }

    ngOnDestroy(): void {
        this.eventsInternal.complete();
    }

    numberUpdated = (number: TNumber) => {
        this.number = number;

        // Check if the dialog scope has changed from when initially opened.
        // If it differs from the original scope, we no longer should emit change events.
        // This may occur if we move between quarters for a number (for example)
        if (this.initialNumber.id !== number.id) return;
        // If the initial number is scoped to a week (e.g. it is a record) the subsequent number must be scoped to the same week
        if ("week" in this.initialNumber && (!("week" in number) || this.initialNumber.week !== number.week)) return;

        this.eventsInternal.next({ type: "updated", item: number });
    };

    numberDeleted = (number: TNumber | undefined) => {
        this.eventsInternal.next({ type: "deleted", item: number ?? this.number });
        this.dialogRef.close();
    };
}
