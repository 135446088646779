import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, Injector } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { CloneApi, CloneTemplateDto, ManageUserApi, UserListDto } from "@api";
import { EMPTY, Observable, of } from "rxjs";
import { catchError, first, map, switchMap, tap } from "rxjs/operators";

import { CompanyMenuRepository, CompanyTeamRepository } from "~repositories";
import { TeamContext, UserContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";
import { SOCKET_MANAGER, SocketManager } from "~services/socket-manager.service";
import { CommonFunctions } from "~shared/commonfunctions";
import { WfDialog } from "~shared/dialog";
import { WithDestroy } from "~shared/mixins";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { sortString, sortUser } from "~shared/util/sorters";
import { getUserName } from "~shared/util/user-helper";

@Component({
    selector: "app-dialog-clone-template",
    templateUrl: "./dialog-clone-template.component.html",
    styleUrls: ["./dialog-clone-template.component.scss"],
    standalone: false,
})
export class DialogCloneTemplateComponent extends WithDestroy() {

    readonly templateControl = new FormControl<string | null>(null, [Validators.required]);
    readonly userControl = new FormControl<string | null>(null);

    readonly form = new FormGroup({
        template: this.templateControl,
        user: this.userControl
    });

    isLoading = false;

    widthImg = 20;
    heightImg = 20;
    buttonText = "cloneTemplateDialog.load";
    btnAction = {
        buttonText: this.buttonText,
        buttonIconImg: "",
        isClicked: false,
    };

    // We want to set companyId on open - if the company changes while the dialog is open,
    // we don't want to submit for the wrong company.
    companyId = this.teamContext.company()?.id ?? "";

    isPartnerAdmin = this.userContext.isPartnerAdmin();

    templates$: Observable<CloneTemplateDto[]>;
    users$: Observable<UserListDto[]>;

    readonly getUserName = getUserName;

    constructor(
        private readonly teamContext: TeamContext,
        private readonly userContext: UserContext,
        private readonly cloneApi: CloneApi,
        private readonly companyTeamRepository: CompanyTeamRepository,
        private readonly companyMenuRepository: CompanyMenuRepository,
        private readonly manageUserApi: ManageUserApi,
        private readonly matDialog: MatDialog,
        private readonly dialogRef: MatDialogRef<DialogCloneTemplateComponent>,
        private readonly notificationService: NotificationService,
        @Inject(SOCKET_MANAGER) private readonly socketManager: SocketManager,
        private readonly router: Router
    ) {
        super();
        if (this.isPartnerAdmin) {
            this.users$ = this.manageUserApi.listUsersForCompany(this.companyId, /* activeOnly: */ true).pipe(
                map(users => users.sort(sortUser.ascending())),
                shareReplayUntil(this.destroyed$),
            );
            this.userControl.setValidators([Validators.required]);
        } else {
            this.users$ = of([]);
        }

        this.templates$ = this.cloneApi.getTemplatesForCompany(this.companyId)
            .pipe(
                catchError(error => {
                    if (error instanceof HttpErrorResponse && error.status === 409) {
                        this.notificationService.error("cloneTemplateDialog.cloneUnavailable", undefined, undefined, true);
                        this.dialogRef.close();
                        return EMPTY;
                    }
                    this.dialogRef.close();
                    this.notificationService.errorUnexpected();
                    return EMPTY;
                }),
                tap(templates => {
                    if (!templates || !templates.length) {
                        this.notificationService.warning("cloneTemplateDialog.noTemplateAvailable", undefined, undefined, true);
                        this.dialogRef.close();
                        return;
                    }
                    if (templates && templates.length === 1 && !this.templateControl.value) {
                        this.templateControl.setValue(templates[0].id);
                    }
                }),
                map(templates => templates.sort(sortString.ascending(t => t.name))),
                shareReplayUntil(this.destroyed$),
            );
    }

    static open(matDialog: WfDialog, injector: Injector) {
        return matDialog.open<DialogCloneTemplateComponent, void, boolean>(DialogCloneTemplateComponent, {
            width: "400px",
            injector: injector,
            showCloseButton: false,
        });
    }

    getUserValue = (user: UserListDto) => user.userId;

    submit = () => {
        if (!this.form.valid) return;
        if (this.isLoading) return;

        const dialogRef = CommonFunctions.showConfirmDialog(this.matDialog, {
            title: "cloneTemplateDialog.confirmTitle",
            description: "cloneTemplateDialog.confirmDescription",
            confirm: { title: "cloneTemplateDialog.confirmButtonTitle" },
            cancel: { title: "Cancel" }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === "confirm") {
                this.cloneFromTemplate();
            }
        });
    };

    private cloneFromTemplate = () => {
        if (!this.form.valid || !this.templateControl.value) return;
        if (this.isLoading) return;

        this.isLoading = true;
        this.dialogRef.disableClose = true;
        CommonFunctions.showLoader();
        this.btnAction = CommonFunctions.disableAndLoader(this.buttonText);

        this.cloneApi.cloneFromTemplate({
            companyId: this.companyId,
            templateId: this.templateControl.value,
            userId: this.userControl.value ?? undefined,
        }).pipe(
            tap(() => this.socketManager.reconnect()),
            tap(() => this.companyTeamRepository.invalidateCompany(this.companyId)),
            switchMap(this.reloadCompanyData),
        ).subscribe({
            next: () => {
                this.isLoading = false;
                this.dialogRef.disableClose = false;
                CommonFunctions.hideLoader();
                this.btnAction = CommonFunctions.closeAndSuccess(true);
                setTimeout(() => {
                    this.dialogRef.close(true);
                }, 1000);
            },
            error: () => {
                this.isLoading = false;
                this.dialogRef.disableClose = false;
                CommonFunctions.hideLoader();
                this.btnAction = CommonFunctions.closeAndSuccess(false);
                this.notificationService.errorUnexpected();
                setTimeout(() => {
                    this.dialogRef.close();
                }, 2000);
            },
        });
    };

    private reloadCompanyData = (): Observable<void> =>
        this.companyMenuRepository.getMenuItems(/* refresh: */ true)
            .pipe(
                first(),
                map(companies => {
                    const data = companies.find(c => c.company.id === this.companyId);
                    if (!data || !data.teams.length) throw new Error();
                    return { company: data.company, team: data.teams[0] };
                }),
                tap(({ company, team }) => {
                    this.userContext.refresh();
                    this.router.navigate(["/company", company.id, "team", team.id, "dashboard"]);
                }),
                map(() => undefined)
            );

}
