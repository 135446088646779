import { AfterViewInit, Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { CurrentCompanyAndTeamDto, CurrentUserDto } from "@api";
import { combineLatest, Subscription } from "rxjs";
import { first } from "rxjs/operators";

import { TeamContext, UserContext } from "~services/contexts";
import { GlobalFeatureFlagService } from "~services/global-feature-flag.service";
import { SettingsService } from "~services/settings.service";
import { UserService } from "~services/user.service";
import { ClientStatus, OnboardingStatus, Profile, Role } from "~shared/enums";
import { getProfileRoles } from "~shared/util/profile-helper";


@Component({
    selector: "app-root-redirect",
    templateUrl: "./root-redirect.component.html",
    styleUrls: ["./root-redirect.component.scss"],
    standalone: false,
})
export class RootRedirectComponent implements AfterViewInit, OnDestroy {

    private routeSub?: Subscription;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly userService: UserService,
        private readonly userContext: UserContext,
        private readonly teamContext: TeamContext,
        private readonly globalFeatureFlagService: GlobalFeatureFlagService,
        private readonly settingsService: SettingsService,
    ) {

    }

    ngAfterViewInit(): void {
        this.routeSub = combineLatest({
            fragment: this.route.fragment,
            user: this.userContext.user$,
            companyTeam: this.teamContext.companyTeam$,
        }).pipe(
            first()
        ).subscribe(({ fragment, user, companyTeam }) =>
            this.processFragment(fragment, user, companyTeam));
    }

    ngOnDestroy(): void {
        this.routeSub?.unsubscribe();
    }

    private processFragment = (fragment: string | null, user: CurrentUserDto | null, companyTeam: CurrentCompanyAndTeamDto | null) => {
        const extras: NavigationExtras = { replaceUrl: true };
        if (fragment && fragment !== "/") { // Prevent routing back to this same page.
            this.router.navigateByUrl(fragment);
            return;
        }
        if (!user || !companyTeam) {
            this.userService.redirectToLogin();
            return;
        }

        const { company, team } = companyTeam;

        if (company.onboardingStatus < OnboardingStatus.complete &&
            (user.companyId === company.id || user.roleName !== Role.superAdmin)) {
            if (!this.globalFeatureFlagService.registrationEnabled) {
                this.userService.redirectToLogin();
                return;
            }

            this.userService.redirectToSignup();
            return;
        }

        if (company.clientStatus === ClientStatus.active || user.roleName === Role.superAdmin ||
            // A partner is allowed to access a disabled company that they manage (not their home company).
            getProfileRoles(Profile.partner).includes(user.roleName as Role) && company.id !== user.companyId) {
            const returnUrl = this.settingsService.postLoginReturnUrl;
            if (returnUrl && returnUrl !== "/") {
                this.router.navigateByUrl(returnUrl, extras);
            } else {
                this.router.navigate(["/company", company.id, "team", team.id, "dashboard"], extras);
            }
            if (returnUrl !== null) this.settingsService.postLoginReturnUrl = null;
            return;
        }

        if (company.clientStatus !== ClientStatus.suspended &&
            getProfileRoles(Profile.billingAdmin).includes(user.roleName as Role)) {
            this.router.navigate(["/payment"], extras);
            return;
        }

        this.userService.redirectToLogin(); // This will navigate to the login page, so no explicit redirect must be done.
    };

}
