import { DOWN_ARROW, ESCAPE, UP_ARROW } from "@angular/cdk/keycodes";
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatMenu } from "@angular/material/menu";
import { Router } from "@angular/router";
import { CompanyMenuDto, SimpleTeamDto } from "@api";
import { combineLatest, debounceTime, distinctUntilChanged, EMPTY, filter, map, Observable, of, skip, startWith, Subscription, switchMap, throttleTime } from "rxjs";

import { CompanyMenuRepository } from "~repositories";
import { TeamContext } from "~services/contexts";
import { WithDestroy } from "~shared/mixins";
import { splitFilterTokens } from "~shared/util/filtering";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { sortCompanyTeam } from "~shared/util/sorters";
import { valueAndChanges } from "~shared/util/util";

const FILTER_MIN_LENGTH = 10;

interface CompanyTeamName {
    companyName: string;
    teamName: string | null;
}

interface CompanyTeam {
    company: CompanyMenuDto;
    team: SimpleTeamDto;
}

const filterTeams = (teams: CompanyTeam[], teamFilter: string | null): CompanyTeam[] => {
    if (!teamFilter) return teams;
    const filterTokens = splitFilterTokens(teamFilter.toLowerCase());
    if (!filterTokens.length) return teams;

    return teams.filter(team => {
        const companyName = team.company.name.toLowerCase();
        const companyNameOverride = team.company.adminNameOverride?.toLowerCase() ?? "";
        const teamName = team.team.name.toLowerCase();
        return filterTokens.every(token => companyName.includes(token) || companyNameOverride.includes(token) || teamName.includes(token));
    });
};

const MENU_REFRESH_DEBOUNCE_MS = 20 * 1000; // 20 seconds

@Component({
    selector: "app-team-select-menu",
    templateUrl: "./team-select-menu.component.html",
    styleUrls: ["./team-select-menu.component.scss"],
    standalone: false,
})
export class TeamSelectMenuComponent extends WithDestroy() implements OnInit, OnDestroy {

    @ViewChild("filterInput") filterInput?: ElementRef<HTMLInputElement>;
    @ViewChild("menu") menuElement?: MatMenu;

    readonly filterControl = new FormControl<string | null>(null);
    readonly showAllClientsControl = new FormControl<boolean>(false, { nonNullable: true });

    readonly form = new FormGroup({
        filter: this.filterControl,
        showAllClients: this.showAllClientsControl,
    });

    name: CompanyTeamName | null = null;

    readonly filteredTeams$: Observable<CompanyTeam[]>;
    readonly showFilter$: Observable<boolean>;
    readonly showShowAllClients$: Observable<boolean>;

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly teamContext: TeamContext,
        private readonly companyMenuRepository: CompanyMenuRepository,
        private readonly router: Router,
    ) {
        super();

        const teams$ = this.companyMenuRepository.getMenuItems().pipe(
            map(companies => companies.map(({ company, teams }) =>
                teams.map(team => ({ company, team }))
            ).flat().sort(sortCompanyTeam.ascending())));

        const currentClientId$ = this.teamContext.companyTeam$.pipe(
            map(ct => ct?.company.clientId ?? null),
            distinctUntilChanged(),
        );

        this.filteredTeams$ = combineLatest({
            teams: teams$,
            currentClientId: currentClientId$,
            showAllClients: valueAndChanges(this.showAllClientsControl).pipe(
                distinctUntilChanged(),
            ),
            teamFilter: valueAndChanges(this.filterControl).pipe(
                distinctUntilChanged(),
                debounceTime(10),
                startWith(null),
            ),
        }).pipe(
            switchMap(({ teams, currentClientId, showAllClients, teamFilter }) => {
                if (!showAllClients && currentClientId) {
                    const clientTeams = teams.filter(t => t.company.clientId === currentClientId);
                    const filteredTeams = filterTeams(clientTeams, teamFilter);
                    if (!filteredTeams.length) {
                        // Automatically "show all" if the filter leaves us with nothing.
                        this.showAllClientsControl.setValue(true);
                        // This will re-run this block, but with showAllClients set to true.
                        // As this will actually emit *before* this value, we want to avoid emitting the empty array,
                        // so we return an empty observable here.
                        return EMPTY;
                    }
                    return of(filteredTeams);
                }
                return of(filterTeams(teams, teamFilter));
            }),
            shareReplayUntil(this.destroyed$),
        );
        this.showFilter$ = teams$.pipe(
            map(teams => teams.length > FILTER_MIN_LENGTH),
            shareReplayUntil(this.destroyed$),
        );
        this.showShowAllClients$ = combineLatest({
            teams: teams$,
            currentClientId: currentClientId$,
        }).pipe(
            map(({ teams, currentClientId }) => teams.some(t => t.company.clientId !== currentClientId)),
            shareReplayUntil(this.destroyed$),
        );
    }

    ngOnInit(): void {
        this.subscriptions.add(this.teamContext.companyTeam$.pipe(
            map(ct => ct && { companyName: ct.company.name, teamName: ct.team.name }),
        ).subscribe(name => this.name = name));

        // When the team changes or refreshes, we want to refresh the menu items.
        this.subscriptions.add(this.teamContext.companyTeam$.pipe(
            filter(Boolean),
            // If multiple changes occur within the space of 20 seconds, only refresh once.
            throttleTime(MENU_REFRESH_DEBOUNCE_MS),
            // We don't refresh on the first emit, as this is often with fresh data already.
            skip(1),
        ).subscribe(() => this.companyMenuRepository.refresh()));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    changeCompanyTeamName = ({ company, team }: CompanyTeam) => {
        this.name = { companyName: company.name, teamName: team.name };
    };

    focusFilter = () => {
        setTimeout(() => {
            this.filterInput?.nativeElement?.focus();
        }, 10);
    };

    handleFilterKeydown = (event: KeyboardEvent) => {
        const keyCode = event.keyCode;

        switch (keyCode) {
            case ESCAPE:
                if (this.filterControl.value) {
                    this.filterControl.setValue(null);
                    event.stopPropagation();
                    return;
                }
                // If the filter is empty, we let the default menu behavior happen,
                // which will close the menu.
                break;
            case UP_ARROW:
            case DOWN_ARROW:
                // We let the default menu behavior happen here, which will move the focus.
                // The menu thinks the first item is already selected, so we need to reset this item so we can
                // let this move to the top or bottom of the list.
                this.menuElement?.resetActiveItem();
                break;
            default:
                event.stopPropagation();
                break;
        }
    };

}
