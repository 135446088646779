import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { NumberRecordDetailDto } from "@api";

import { sortNumber } from "~shared/util/sorters";
import { trackByIdAndWeek } from "~shared/util/table-helper";

declare type NumberRecordColumn = "week" | "result" | "target";

const sortByWeek = (records: NumberRecordDetailDto[]) =>
    records.sort(sortNumber.ascending(r => r.week));

@Component({
    selector: "app-number-records-table",
    templateUrl: "./number-records-table.component.html",
    styleUrls: ["./number-records-table.component.scss"],
    standalone: false,
})
export class NumberRecordsTableComponent {

    @Input() set records(value: NumberRecordDetailDto[] | null) {
        this.dataSource.data = sortByWeek(value ?? []);
    }

    get records(): NumberRecordDetailDto[] {
        return this.dataSource.data;
    }

    @Input() selectedWeek: number | null | undefined;

    @Output() recordUpdated = new EventEmitter<NumberRecordDetailDto>();

    readonly dataSource = new MatTableDataSource<NumberRecordDetailDto>();
    readonly displayedColumns: NumberRecordColumn[] = ["week", "result", "target"];

    readonly trackByIdAndWeek = trackByIdAndWeek;

}
