import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GetUserWatchlistDto, UpdateWatchlistDto, UserWatchlistsApi } from "@api";
import { Observable } from "rxjs";

import { NotificationService } from "~services/notification.service";

import { BaseEditWatchlistDialogComponent } from "../base-edit-watchlist-dialog/base-edit-watchlist-dialog.component";

interface EditUserWatchlistDialogData {
    clientId: string;
    list?: GetUserWatchlistDto;
}

@Component({
    selector: "app-edit-user-watchlist-dialog",
    templateUrl: "../base-edit-watchlist-dialog/base-edit-watchlist-dialog.component.html",
    styleUrls: ["../base-edit-watchlist-dialog/base-edit-watchlist-dialog.component.scss"],
    standalone: false,
})
export class EditUserWatchlistDialogComponent extends BaseEditWatchlistDialogComponent<GetUserWatchlistDto, EditUserWatchlistDialogData> {

    private readonly clientId: string;

    constructor(
        private readonly userWatchlistsApi: UserWatchlistsApi,
        notificationService: NotificationService,
        dialogRef: MatDialogRef<EditUserWatchlistDialogComponent, GetUserWatchlistDto>,
        @Inject(MAT_DIALOG_DATA) data: EditUserWatchlistDialogData,
    ) {
        super(notificationService, dialogRef, data);

        this.clientId = data.clientId;
    }

    static openForAdd(dialog: MatDialog, clientId: string) {
        return this.openInternal(EditUserWatchlistDialogComponent, dialog, {
            clientId: clientId,
        });
    }

    static openForEdit(dialog: MatDialog, list: GetUserWatchlistDto) {
        return this.openInternal(EditUserWatchlistDialogComponent, dialog, {
            clientId: list.clientId,
            list,
        });
    }

    protected addList = (dto: UpdateWatchlistDto): Observable<GetUserWatchlistDto> =>
        this.userWatchlistsApi.addList(this.clientId, dto);

    protected updateList = (list: GetUserWatchlistDto, dto: UpdateWatchlistDto): Observable<GetUserWatchlistDto> =>
        this.userWatchlistsApi.updateList(list.clientId, list.id, dto);
}
