import { Component, Inject, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Observable, Subject } from "rxjs";

import { ReportDto } from "../../components/report-homepage/report-homepage.component";
import { HomepageDialog, HomepageEvent } from "../homepage-dialog.interface";
import { buildHomepageDialogConfig, HomepageDialogOptions } from "../homepage-dialog-shared";

interface ReportHomepageDialogData<TReport extends ReportDto> {
    report: TReport;
    allowEdit?: boolean;
}

type ReportHomepageDialogOptions = HomepageDialogOptions & {
    allowEdit?: boolean;
};

@Component({
    selector: "app-report-homepage-dialog",
    templateUrl: "./report-homepage-dialog.component.html",
    styleUrls: ["./report-homepage-dialog.component.scss"],
    standalone: false,
})
export class ReportHomepageDialogComponent<TReport extends ReportDto> implements HomepageDialog<TReport>, OnDestroy {

    readonly events$: Observable<HomepageEvent<TReport>>;

    report: TReport;

    readonly allowEdit: boolean;

    private readonly initialReport: TReport;

    private readonly eventsInternal = new Subject<HomepageEvent<TReport>>();

    constructor(
        private readonly dialogRef: MatDialogRef<ReportHomepageDialogComponent<TReport>>,
        @Inject(MAT_DIALOG_DATA) data: ReportHomepageDialogData<TReport>,
    ) {
        this.events$ = this.eventsInternal.asObservable();
        this.initialReport = data.report;
        this.report = data.report;
        this.allowEdit = data.allowEdit ?? false;
    }

    static open<TReport extends ReportDto>(dialog: MatDialog, report: TReport, options?: ReportHomepageDialogOptions) {
        return dialog.open<ReportHomepageDialogComponent<TReport>, ReportHomepageDialogData<TReport>>(
            ReportHomepageDialogComponent,
            {
                data: { report, allowEdit: options?.allowEdit },
                ...buildHomepageDialogConfig(options),
            });
    }

    ngOnDestroy(): void {
        this.eventsInternal.complete();
    }

    reportUpdated = (report: TReport) => {
        this.report = report;

        // Check if the dialog scope has changed from when initially opened.
        // If it differs from the original scope, we no longer should emit change events.
        // This may occur if we move between quarters for a report (for example)
        if (this.initialReport.id !== report.id) return;
        // If the initial report is scoped to a week (e.g. it is a record) the subsequent report must be scoped to the same week
        if ("week" in this.initialReport && (!("week" in report) || this.initialReport.week !== report.week)) return;

        this.eventsInternal.next({ type: "updated", item: report });
    };

    reportDeleted = (report: TReport | undefined) => {
        this.eventsInternal.next({ type: "deleted", item: report ?? this.report });
        this.dialogRef.close();
    };
}
