import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GetMeetingInstanceDto, LaunchDto, WeeklyMeetingsApi } from "@api";

import { IWeek } from "~repositories";
import { NotificationService } from "~services/notification.service";
import { toFiscalQuarter } from "~shared/commonfunctions";
import { ButtonState } from "~shared/components/status-button/status-button.component";
import { MeetingStatus } from "~shared/enums";

import { isInstanceThisWeek } from "../../meeting-helpers";

interface ConfirmAdHocMeetingDialogData {
    instance: GetMeetingInstanceDto;
    currentWeek: IWeek;
    launch: LaunchDto;
}

@Component({
    selector: "app-confirm-adhoc-meeting-dialog",
    templateUrl: "./confirm-adhoc-meeting-dialog.component.html",
    styleUrls: ["./confirm-adhoc-meeting-dialog.component.scss"],
    standalone: false,
})
export class ConfirmAdHocMeetingDialogComponent {

    buttonState: ButtonState;

    get messageType(): "closed" | "cancelled" | "noScheduled" | "notStarted" {
        if (!isInstanceThisWeek(this.instance, this.currentWeek)) return "noScheduled";
        if (this.instance.type === "scheduled" && this.instance.isCancelled) return "cancelled";
        if (this.instance.status === MeetingStatus.notStarted) return "notStarted";
        if (this.instance.status === MeetingStatus.closed) return "closed";
        // We should never get here
        return "notStarted";
    }

    private readonly instance: GetMeetingInstanceDto;
    private readonly currentWeek: IWeek;
    private readonly launch: LaunchDto;

    constructor(
        private readonly weeklyMeetingsApi: WeeklyMeetingsApi,
        private readonly notificationService: NotificationService,
        private readonly dialogRef: MatDialogRef<ConfirmAdHocMeetingDialogComponent, GetMeetingInstanceDto>,
        @Inject(MAT_DIALOG_DATA) data: ConfirmAdHocMeetingDialogData,
    ) {
        this.instance = data.instance;
        this.currentWeek = data.currentWeek;
        this.launch = data.launch;
    }

    static open(dialog: MatDialog, instance: GetMeetingInstanceDto, currentWeek: IWeek, launch: LaunchDto) {
        return dialog.open<ConfirmAdHocMeetingDialogComponent, ConfirmAdHocMeetingDialogData, GetMeetingInstanceDto>(
            ConfirmAdHocMeetingDialogComponent,
            {
                width: "350px",
                data: { instance, currentWeek, launch },
            },
        );
    }

    launchAdHocMeeting() {
        if (this.buttonState) return;

        this.buttonState = "loading";
        this.dialogRef.disableClose = true;
        const instance = this.instance;
        const week = this.currentWeek;
        this.weeklyMeetingsApi.launchAdHocMeeting(
            instance.meeting.company.id,
            instance.meeting.team.id,
            toFiscalQuarter(week),
            week.week,
            this.launch,
        ).subscribe({
            next: result => {
                this.buttonState = "success";
                this.notificationService.success("NewMeetingStartScreen.unscheduledMeetingStarted", undefined, undefined, true);
                setTimeout(() => this.dialogRef.close(result), 1000);
            },
            error: () => {
                this.dialogRef.disableClose = false;
                this.buttonState = "error";
                setTimeout(() => {
                    this.buttonState = undefined;
                }, 2000);
                this.notificationService.errorUnexpected();
            }
        });
    }
}
