import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { BehaviorSubject, expand, of, skip, Subject, Subscription, switchMap, timer } from "rxjs";

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const getSecondsDiff = (dateValue: number): number => Math.floor(Math.abs(Date.now() - dateValue) / 1000);

const getTickInterval = (secondsDiff: number): number => {
    // If less than one minute, tick every second.
    if (secondsDiff < MINUTE) return 1000;
    // If less then two minutes, tick approximately just after we change to seconds.
    if (secondsDiff < MINUTE * 2) return (secondsDiff - MINUTE + 1) * 1000;
    // If less than an hour, tick every minute.
    if (secondsDiff < HOUR) return 1000 * MINUTE;
    // If less than two hours, tick approximately just after we change to minutes.
    if (secondsDiff < HOUR * 2) return (secondsDiff - HOUR + 1) * 1000;
    // Tick every hour.
    return 1000 * HOUR;
};

@Pipe({
    name: "timeDiff",
    pure: false,
    standalone: false,
})
export class TimeDiffPipe implements PipeTransform, OnDestroy {

    private value = "";

    private readonly stateChanges = new Subject<void>();
    private readonly dateValueSubject = new BehaviorSubject<number>(0);

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly translate: TranslateService,
        cd: ChangeDetectorRef,
    ) {
        this.subscriptions.add(this.stateChanges.subscribe(() => {
            this.value = this.formatValue(this.dateValueSubject.value);
            cd.markForCheck();
        }));
        this.subscriptions.add(
            this.translate.onLangChange.subscribe(() => this.stateChanges.next()));
        this.subscriptions.add(this.dateValueSubject.pipe(
            skip(1),
            switchMap(value => of(null).pipe(
                expand(() => timer(getTickInterval(getSecondsDiff(value))))
            )),
        ).subscribe(() => this.stateChanges.next()));
    }

    transform(date: unknown, ...args: unknown[]): string {
        const parsedDate = this.parseValue(date);
        if (!parsedDate) {
            throw new SyntaxError(`Invalid date parameter. Expected a valid date, received: ${date}`);
        }
        const dateValue = parsedDate.valueOf();
        if (this.dateValueSubject.value !== dateValue) {
            this.dateValueSubject.next(dateValue);
        }
        return this.value;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private parseValue = (date: unknown): moment.Moment | null => {
        if (typeof date === "string" || typeof date === "number" || moment.isDate(date)) {
            return moment(date);
        } else if (moment.isMoment(date)) {
            return date;
        } else {
            return null;
        }
    };

    private formatValue = (dateValue: number): string => {
        const secondsDiff = getSecondsDiff(dateValue);
        if (secondsDiff >= DAY) {
            const dayDiff = Math.floor(secondsDiff / DAY);
            return this.translate.instant(dayDiff !== 1 ? "interval.days" : "interval.day", { interval: dayDiff });
        } else if (secondsDiff >= HOUR) {
            const hourDiff = Math.floor(secondsDiff / HOUR);
            return this.translate.instant(hourDiff !== 1 ? "interval.hours" : "interval.hour", { interval: hourDiff });
        } else if (secondsDiff >= MINUTE) {
            const minuteDiff = Math.floor(secondsDiff / MINUTE);
            return this.translate.instant(minuteDiff !== 1 ? "interval.minutes" : "interval.minute", { interval: minuteDiff });
        } else {
            return this.translate.instant(secondsDiff !== 1 ? "interval.seconds" : "interval.second", { interval: secondsDiff });
        }
    };

}
