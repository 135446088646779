import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, FormBuilder, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator, Validators } from "@angular/forms";
import { CompanyTeamNumberExternalDataDto, CompanyTeamNumberExternalDataRetrieverInfoDto, XeroCashInBankAccountRetrieverInfoDto } from "@api";
import { Subscription } from "rxjs";

import { TeamContext } from "~services/contexts";
import { setEnabledState } from "~shared/util/form-helper";
import { valueAndChanges } from "~shared/util/util";

type ExternalDataRetrieverInfo = CompanyTeamNumberExternalDataRetrieverInfoDto["type"];

interface ExternalDataRetrieverInfoState<TType extends ExternalDataRetrieverInfo> {
    data: Extract<CompanyTeamNumberExternalDataRetrieverInfoDto, { type: TType }>;
    isEnabled: boolean;
}

type ExternalDataRetrieverInfoAll = {
    [K in ExternalDataRetrieverInfo]: ExternalDataRetrieverInfoState<K>;
};

const canEnterSchedule = (type: ExternalDataRetrieverInfo | null): boolean => !!type;
const canSelectXeroAccount = (type: ExternalDataRetrieverInfo | null): boolean => type === "xeroCashInBankAccountRetriever";

@Component({
    selector: "app-external-data",
    templateUrl: "./external-data.component.html",
    styleUrls: ["./external-data.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            useExisting: forwardRef(() => ExternalDataComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            useExisting: forwardRef(() => ExternalDataComponent),
            multi: true
        }
    ],
    standalone: false,
})
export class ExternalDataComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {

    @Input() set value(value: CompanyTeamNumberExternalDataDto) {
        if (!value) return; // No external data has been setup.

        const retrieverInfo = value.retrieverInfo as CompanyTeamNumberExternalDataRetrieverInfoDto;
        this.retrieverInfoControl.setValue(retrieverInfo.type);
        this.scheduleControl.setValue(value.retrieveAtTimeOfDay ?? null);
        switch (retrieverInfo.type) {
            case "xeroCashInBankAccountRetriever":
                this.xeroAccountControl.setValue(retrieverInfo);
                break;
        }
    }

    @Output() updated = new EventEmitter<CompanyTeamNumberExternalDataDto>();

    readonly externalRetrievers: ExternalDataRetrieverInfoAll = {
        xeroCashInBankAccountRetriever: {
            data: { type: "xeroCashInBankAccountRetriever", bankAccountId: "" },
            isEnabled: this.teamContext.features.xeroIntegrationEnabled(),
        },
        xeroCashInBankTotalRetriever: {
            data: { type: "xeroCashInBankTotalRetriever" },
            isEnabled: this.teamContext.features.xeroIntegrationEnabled(),
        },
    };

    readonly retrieverInfoControl = this.fb.control<ExternalDataRetrieverInfo | null>(null);
    readonly scheduleControl = this.fb.control<string | null>({ value: null, disabled: true }, [Validators.required]);
    readonly xeroAccountControl = this.fb.nonNullable.control(
        { value: this.externalRetrievers.xeroCashInBankAccountRetriever.data as XeroCashInBankAccountRetrieverInfoDto, disabled: true },
        [Validators.required]);

    readonly form = this.fb.group({
        retrieverInfo: this.retrieverInfoControl,
        schedule: this.scheduleControl,
        xeroAccount: this.xeroAccountControl
    });

    get externalDataSelected() {
        return !!this.retrieverInfoControl.value;
    }

    get externalRetrieversEnabled(): ExternalDataRetrieverInfo[] {
        return Object.keys(this.externalRetrievers)
            .map(x => this.externalRetrievers[x as ExternalDataRetrieverInfo])
            .filter(x => x.isEnabled)
            .map(x => x.data.type);
    }

    get retrieveInfoType(): ExternalDataRetrieverInfo | null {
        return this.retrieverInfoControl.value;
    }

    private onChangedCallback?: (_: CompanyTeamNumberExternalDataDto | undefined) => void;
    private onTouchedCallback?: () => void;

    private subscriptions: Subscription[] = [];

    constructor(
        private readonly teamContext: TeamContext,
        private readonly fb: FormBuilder,
    ) {
    }

    ngOnInit(): void {
        this.subscriptions.push(valueAndChanges(this.retrieverInfoControl).subscribe(this.onRetrieverInfoChange));
        this.subscriptions.push(valueAndChanges(this.xeroAccountControl).subscribe(this.onXeroAccountChange));
        this.subscriptions.push(valueAndChanges(this.scheduleControl).subscribe(this.emitData));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    writeValue(obj: CompanyTeamNumberExternalDataDto): void {
        this.value = obj;
    }

    registerOnChange(fn: (_: CompanyTeamNumberExternalDataDto | undefined) => void): void {
        this.onChangedCallback = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouchedCallback = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable();
        } else {
            this.retrieverInfoControl.enable();
            const type = this.retrieverInfoControl.value;
            // Enable the fields that should be enabled for the specific type.
            setEnabledState(this.scheduleControl, canEnterSchedule(type));
            setEnabledState(this.xeroAccountControl, canSelectXeroAccount(type));
        }
    }

    validate = (_: FormControl) => this.form.valid ? null : { externalData: { valid: false } };

    getExternalRetrieverKey(key: ExternalDataRetrieverInfo) {
        return "externalData." + key;
    }

    private emitData = (): void => {
        const data = this.getFormState();
        this.updated.emit(data);
        this.onChangedCallback?.(data);
        this.onTouchedCallback?.();
    };

    private getFormState = (): CompanyTeamNumberExternalDataDto | undefined => {
        // Indicate that the retriever has been de-selected.
        if (!this.retrieveInfoType) {
            return undefined;
        }

        return {
            retrieverInfo: this.externalRetrievers[this.retrieveInfoType].data,
            retrieveAtTimeOfDay: this.scheduleControl.value ?? undefined,
        };
    };

    private onRetrieverInfoChange = (type: ExternalDataRetrieverInfo | null): void => {
        if (canSelectXeroAccount(type)) {
            this.xeroAccountControl.enable({ emitEvent: false });
            this.xeroAccountControl.updateValueAndValidity({ emitEvent: false });
        } else {
            this.xeroAccountControl.disable();
        }

        // If the external data type is actually set then enable the schedule control.
        // Otherwise, allow no external data source to be set (but the number can still be automatic).
        if (canEnterSchedule(type)) {
            this.scheduleControl.enable({ emitEvent: false });
        } else {
            this.scheduleControl.disable({ emitEvent: false });
        }

        this.emitData();
    };

    private onXeroAccountChange = (retrieverInfo: XeroCashInBankAccountRetrieverInfoDto) => {
        this.externalRetrievers.xeroCashInBankAccountRetriever.data = retrieverInfo;
        this.xeroAccountControl.updateValueAndValidity({ emitEvent: false });
        this.emitData();
    };
}
