import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";

import { NumberType } from "~shared/enums";
import { getNumberPrefix, getNumberSuffix } from "~shared/util/number-helper";

@Pipe({
    name: "numberFormat",
    pure: true,
    standalone: false,
})
export class NumberFormatPipe implements PipeTransform {

    private readonly formatter = new Intl.NumberFormat(this.locale, { maximumSignificantDigits: 8 });

    constructor(
        @Inject(LOCALE_ID) private readonly locale: string,
    ) { }

    transform(value: number, numberType?: NumberType): string;
    transform(value: null | undefined, numberType?: NumberType): null;
    transform(value: number | null | undefined, numberType?: NumberType): string | null;
    transform(value: number | null | undefined, numberType?: NumberType): string | null {
        if (value == null || typeof value !== "number") return null;

        const formattedValue = this.formatter.format(value);
        return getNumberPrefix(numberType ?? NumberType.normal) + formattedValue + getNumberSuffix(numberType ?? NumberType.normal);
    }
}
