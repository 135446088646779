import { trigger } from "@angular/animations";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from "@angular/core";
import { BehaviorSubject, EMPTY, of, Subscription } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";

import { IQuarter, PeriodRepository, QuarterContextRepository } from "~repositories";
import { defaultAnimationTiming, fadeInAnimationBuilder } from "~shared/util/animations";

interface IQuarterSelectorState {
    /**
     * The currently selected period, or null if no period is yet selected
     */
    selectedPeriod: IQuarter | null;

    /**
     * The delta waiting to be applied to the quarter
     */
    pendingDelta: number;
}

export interface IQuarterDetails extends IQuarter {
    readonly startDate: string;
    readonly endDate: string;
}

const mapToState = (period: IQuarter | null, delta?: number): IQuarterSelectorState => ({
    selectedPeriod: period,
    pendingDelta: delta ?? 0
});

const compareQuarters = (o1: IQuarter, o2: IQuarter): number =>
    (o2.financialYear - o1.financialYear) || (o2.quarter - o1.quarter);

@Component({
    selector: "app-quarter-selector",
    templateUrl: "./quarter-selector.component.html",
    styleUrls: ["./quarter-selector.component.scss"],
    animations: [
        trigger("fadeIn", fadeInAnimationBuilder(defaultAnimationTiming)),
    ],
    standalone: false,
})
export class QuarterSelectorComponent implements OnInit, OnDestroy {

    private static lastElementId = 0;

    @Output() selectedQuarterChange = new EventEmitter<IQuarter>();

    get companyId(): string {
        return this._companyId;
    }

    @Input() set companyId(value: string) {
        if (this._companyId === value) return;
        this._companyId = value;
        this.currentQuarter = null;
        if (this._companyId) this.ensurePeriodSet();
    }

    get selectedQuarter() {
        if (this.quarterContext) return this.quarterContext.getSelectedQuarter(this.companyId);
        return this._selectedQuarter;
    }

    @Input() set selectedQuarter(value: IQuarter | null) {
        if (!value) return;

        const selectedQuarter = this.selectedQuarter;
        if (selectedQuarter && compareQuarters(selectedQuarter, value) === 0) return;

        this.stateSubject.next(mapToState(value));
    }

    @Input() allowEdit = false;

    @Input() set readonly(value: boolean) {
        this.readonlyInternal = coerceBooleanProperty(value);
    }

    get readonly(): boolean {
        return this.readonlyInternal;
    }

    @Input() set pastOnly(value: boolean) {
        this.pastOnlyInternal = coerceBooleanProperty(value);
    }

    get pastOnly(): boolean {
        return this.pastOnlyInternal;
    }

    @Output() selectedQuarterDetailsChange = new EventEmitter<IQuarterDetails>();

    get disableNext(): boolean {
        return this.pastOnly && !!this.currentQuarter && !!this.selectedQuarter &&
            compareQuarters(this.currentQuarter, this.selectedQuarter) >= 0;
    }

    get canReset(): boolean {
        return !this.readonly && !!this.currentQuarter && !!this.selectedQuarter &&
            compareQuarters(this.currentQuarter, this.selectedQuarter) !== 0;
    }

    readonly elementId: string;

    private readonlyInternal = false;
    private pastOnlyInternal = false;
    private currentPeriodSub?: Subscription;
    private stateSub?: Subscription;
    private _companyId!: string;

    private currentQuarter: IQuarter | null = null;

    private _selectedQuarter: IQuarter | null = null;

    private stateSubject = new BehaviorSubject<IQuarterSelectorState>({
        pendingDelta: 0,
        selectedPeriod: null
    });

    constructor(
        private readonly periodRepository: PeriodRepository,
        @Optional() private readonly quarterContext: QuarterContextRepository | null,
    ) {
        this.elementId = `quarter-selector-${(QuarterSelectorComponent.lastElementId++)}`;
    }

    ngOnInit(): void {
        this.ensurePeriodSet();
        this.applyStateListener();
    }

    ngOnDestroy(): void {
        this.selectedQuarterChange.complete();
        this.selectedQuarterDetailsChange.complete();
        this.stateSubject.complete();
        this.currentPeriodSub?.unsubscribe();
        this.stateSub?.unsubscribe();
    }

    incrementQuarter = (): void => {
        if (this.readonly || this.disableNext) return;
        this.mutatePendingDelta(1);
    };

    decrementQuarter = (): void => {
        if (this.readonly) return;
        this.mutatePendingDelta(-1);
    };

    reset = (): void => {
        if (!this.canReset) return;
        this.stateSubject.next(mapToState(this.currentQuarter, 0));
    };

    private ensurePeriodSet = () => {
        const selectedQuarter = this.selectedQuarter;
        if (selectedQuarter === null) {
            this.setCurrentQuarter(/* updateSelected: */ true);
        } else {
            if (!this.currentQuarter) {
                this.setCurrentQuarter(/* updateSelected: */ false);
            }
            const state = this.stateSubject.value;
            this.stateSubject.next({
                selectedPeriod: selectedQuarter,
                pendingDelta: state.pendingDelta
            });
        }
    };

    private mutatePendingDelta = (delta: number): void => {
        const state = this.stateSubject.value;
        this.stateSubject.next({
            selectedPeriod: state.selectedPeriod,
            pendingDelta: state.pendingDelta + delta
        });
    };

    private setCurrentQuarter = (updateSelected: boolean) => {
        if (this.currentPeriodSub) this.currentPeriodSub.unsubscribe();
        this.currentPeriodSub = this.periodRepository.getCurrentPeriod(this.companyId).pipe(
            map(period => ({
                financialYear: period.current.financialYear,
                quarter: period.current.planningPeriodIndex
            })),
            tap(period => this.currentQuarter = period),
        ).subscribe(period => {
            if (updateSelected) this.stateSubject.next(mapToState(period, 0));
        });
    };

    private applyStateListener = () => {
        this.stateSub = this.stateSubject.pipe(
            filter((state): state is { pendingDelta: number; selectedPeriod: IQuarter } => !!state.selectedPeriod),

            switchMap(({ selectedPeriod }) =>
                this.periodRepository.getPeriods(this.companyId, selectedPeriod.financialYear).pipe(
                    map(selectedGroupPeriods => ({ selectedPeriod, selectedGroupPeriods })))),

            switchMap(({ selectedPeriod, selectedGroupPeriods }) => {
                const currentFinancialYear = selectedPeriod.financialYear;
                const maxQuarter = selectedGroupPeriods.length;
                let currentQuarter = selectedPeriod.quarter;
                if (currentQuarter < 1) currentQuarter = 1;
                if (currentQuarter > maxQuarter) currentQuarter = maxQuarter;

                const newQuarter = this.stateSubject.value.pendingDelta + currentQuarter; // Always use the latest pending delta

                if (newQuarter >= 1 && newQuarter <= maxQuarter) {
                    const quarterData = selectedGroupPeriods.find(p => p.index === newQuarter);
                    if (!quarterData) return EMPTY; // this should never occur.
                    return of({
                        selectedQuarter: {
                            financialYear: currentFinancialYear,
                            quarter: newQuarter,
                        } as IQuarter,
                        quarterData,
                    });
                }

                this.stateSubject.next(this.buildNextState(newQuarter, currentFinancialYear, maxQuarter));

                // Don't set the state here - let the next iteration set it
                return EMPTY;
            })
        ).subscribe(({ selectedQuarter, quarterData }) => {
            if (this.quarterContext) this.quarterContext.setSelectedQuarter(this._companyId, selectedQuarter);
            this._selectedQuarter = selectedQuarter;
            this.selectedQuarterChange.emit(selectedQuarter);
            this.selectedQuarterDetailsChange.emit({
                ...selectedQuarter,
                startDate: quarterData.startDate,
                endDate: quarterData.endDate,
            });
        });
    };

    private buildNextState = (newQuarter: number, currentFinancialYear: number, currentMaxPeriod: number): IQuarterSelectorState => {
        if (newQuarter < 1) {
            // move back a year
            return mapToState({
                financialYear: currentFinancialYear - 1,
                quarter: Number.MAX_VALUE // This will be lowered to the max period in the previous year
            }, newQuarter);
        } else {
            // move forward a year
            return mapToState({
                financialYear: currentFinancialYear + 1,
                quarter: 1
            }, newQuarter - (currentMaxPeriod + 1));
        }
    };

    /* eslint-disable @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
    static ngAcceptInputType_companyId: string | null;
    static ngAcceptInputType_readonly: BooleanInput;
    static ngAcceptInputType_pastOnly: BooleanInput;
    /* eslint-enable  @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
}
