import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
    selector: "wf-private-indicator",
    templateUrl: "./private-indicator.component.html",
    styleUrls: ["./private-indicator.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class PrivateIndicatorComponent { }
