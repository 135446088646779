import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GetLinkFeedItemDto } from "@api";

import { FeedScope } from "~feed/services";
import { UserContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";

import { DeletableFeedListItemDirective } from "../deletable-feed-list-item.directive";

@Component({
    selector: "app-feed-list-link",
    templateUrl: "./feed-list-link.component.html",
    styleUrls: ["./feed-list-link.component.scss"],
    standalone: false,
})
export class FeedListLinkComponent extends DeletableFeedListItemDirective<GetLinkFeedItemDto> {
    constructor(
        feedScope: FeedScope,
        userContext: UserContext,
        notificationService: NotificationService,
        dialog: MatDialog,
    ) {
        super(feedScope, userContext, notificationService, dialog);
    }
}
