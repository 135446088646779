import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DiscussionsApi, GetActionDto } from "@api";

import { TeamContext } from "~services/contexts";
import { ActionStateService } from "~services/state";
import { mapActionDiscussionInput } from "~shared/util/discussion-input-builder";
import { mapActionOrigin } from "~shared/util/origin-builder";

import { CommonAddChildDiscussionButtonDirective } from "./add-child-discussion-button.component";

@Component({
    selector: "app-add-action-child-discussion-button",
    templateUrl: "./add-child-discussion-button.component.html",
    styleUrls: ["./add-child-discussion-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class AddActionChildDiscussionButtonComponent extends CommonAddChildDiscussionButtonDirective<GetActionDto> {

    readonly mapToOrigin = mapActionOrigin;
    readonly mapToInput = mapActionDiscussionInput;

    constructor(
        discussionsApi: DiscussionsApi,
        teamContext: TeamContext,
        dialog: MatDialog,
        private readonly actionStateService: ActionStateService,
    ) {
        super(discussionsApi, teamContext, dialog);
    }

    protected notifyChange = (source: GetActionDto) => this.actionStateService.notifyRefresh(source);

    protected creationAllowed = (source: GetActionDto): boolean => !source.isPrivateAction;
}
