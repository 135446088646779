import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
    DiscussionAndSolutionDto, DiscussionsApi, GetActionDto, ReferredSolutionDto, ReferredSolutionsApi, SolvedDiscussionDto
} from "@api";
import { BehaviorSubject, catchError, EMPTY, filter, map, Observable, of, Subscription, switchMap, tap } from "rxjs";

import { EditSolutionDialogComponent, NoteSolutionApprovalDialogComponent, ReviewSolutionDialogComponent } from "~/app/meeting/dialogs";
import { FeedAdapterBuilder, FeedScope, SimpleFeedScope } from "~feed";
import { TeamContext } from "~services/contexts";
import { MeetingProgressService } from "~services/meeting-progress.service";
import { ActionStateService, DiscussionStateEvent, DiscussionStateService } from "~services/state";
import { DiscussionStatus, EntityType, SolutionApprovalActionType } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { mergeChildUpdatesFrom } from "~shared/util/children-state-helper";
import { shareReplayUntil, withRefresh } from "~shared/util/rx-operators";
import { getSolutionApprovalActionTypeNameKey } from "~shared/util/translation-helper";
import { getUserName } from "~shared/util/user-helper";

import { HomepageScaffoldComponent } from "../homepage-scaffold/homepage-scaffold.component";

interface DiscussionData {
    discussion: DiscussionAndSolutionDto;
    referredSolution?: ReferredSolutionDto;
}

@Component({
    selector: "app-discussion-homepage",
    templateUrl: "./discussion-homepage.component.html",
    styleUrls: ["./discussion-homepage.component.scss"],
    providers: [
        SimpleFeedScope,
        {
            provide: FeedScope,
            useExisting: SimpleFeedScope,
        },
    ],
    standalone: false,
})
export class DiscussionHomepageComponent extends WithDestroy() implements OnInit, OnDestroy {

    @Input() set discussion(value: DiscussionAndSolutionDto | null) {
        this.dataSubject.next(!value ? null : { discussion: value });
        this.simpleFeedScope.adapter = value ? this.feedAdapterBuilder.build(value) : null;
    }

    get discussion(): DiscussionAndSolutionDto | null {
        return this.dataSubject.value?.discussion ?? null;
    }

    @Input() set referredSolution(value: ReferredSolutionDto | null) {
        this.dataSubject.next(!value ? null : { discussion: value.discussion, referredSolution: value });
        this.simpleFeedScope.adapter = value ? this.feedAdapterBuilder.buildForReferredSolution(value) : null;
    }

    get referredSolution(): ReferredSolutionDto | null {
        return this.dataSubject.value?.referredSolution ?? null;
    }

    @Input() set readonly(value: BooleanInput) {
        this.readonlyInternal = coerceBooleanProperty(value);
    }

    get readonly(): boolean {
        return this.readonlyInternal;
    }

    @Output() discussionChange = new EventEmitter<DiscussionAndSolutionDto>();
    @Output() discussionDeleted = new EventEmitter<void>();

    @Output() solutionChange = new EventEmitter<ReferredSolutionDto>();

    @ViewChild(HomepageScaffoldComponent) scaffold?: HomepageScaffoldComponent;

    get hasApprovalDetails(): boolean {
        const solution = this.discussion?.solution;
        // We want to show the approval history if a discussion has been solved and either referred
        // or
        return !!solution && (!!solution.approvingTeam || !!solution.approvalHistory.length);
    }

    readonly approvalsEnabled = this.teamContext.features.approvalsEnabled;

    readonly canResolveNow$: Observable<boolean>;
    readonly canNoteNow$: Observable<boolean>;
    readonly canApproveNow$: Observable<boolean>;

    readonly solutionActions$: Observable<GetActionDto[]>;
    isLoadingActions = true;
    actionsHasError = false;

    readonly getUserName = getUserName;
    readonly getSolutionApprovalActionTypeNameKey = getSolutionApprovalActionTypeNameKey;

    private readonlyInternal = false;

    private readonly dataSubject = new BehaviorSubject<DiscussionData | null>(null);
    private readonly refreshActionsSubject = new BehaviorSubject<void>(undefined);

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly discussionsApi: DiscussionsApi,
        private readonly referredSolutionsApi: ReferredSolutionsApi,
        private readonly discussionStateService: DiscussionStateService,
        private readonly actionStateService: ActionStateService,
        private readonly meetingProgressService: MeetingProgressService,
        private readonly teamContext: TeamContext,
        private readonly simpleFeedScope: SimpleFeedScope,
        private readonly feedAdapterBuilder: FeedAdapterBuilder,
        private readonly dialog: MatDialog,
    ) {
        super();
        this.solutionActions$ = this.dataSubject.pipe(
            withRefresh(this.refreshActionsSubject),
            tap(() => {
                this.isLoadingActions = true;
                this.actionsHasError = false;
            }),
            switchMap(data => {
                if (!data) return of([]);
                return this.getActions(data).pipe(
                    catchError(() => {
                        this.actionsHasError = true;
                        return of([]);
                    }),
                );
            }),
            tap(() => this.isLoadingActions = false),
            shareReplayUntil(this.destroyed$),
        );

        this.canResolveNow$ = this.dataSubject.pipe(
            switchMap(data => {
                if (!data || data.referredSolution || data.discussion.status === DiscussionStatus.approved) {
                    return of(false);
                }
                return this.meetingProgressService.isInProgress$(data.discussion.company.id, data.discussion.team.id);
            }),
            shareReplayUntil(this.destroyed$),
        );

        this.canNoteNow$ = this.dataSubject.pipe(
            switchMap(data => {
                if (!data || data.referredSolution || data.discussion.status !== DiscussionStatus.approved) {
                    return of(false);
                }
                return this.meetingProgressService.isInProgress$(data.discussion.company.id, data.discussion.team.id);
            }),
            shareReplayUntil(this.destroyed$),
        );

        this.canApproveNow$ = this.dataSubject.pipe(
            map(data => data?.referredSolution),
            switchMap((referredSolution) => !referredSolution ? of(false) :
                this.meetingProgressService.isInProgress$(referredSolution.company.id, referredSolution.team.id)),
            shareReplayUntil(this.destroyed$),
        );
    }

    ngOnInit(): void {
        this.subscriptions.add(this.dataSubject.pipe(
            map(d => d?.discussion),
            switchMap(discussion => !discussion ? EMPTY : this.discussionStateService.eventsForDiscussions(discussion)),
        ).subscribe(this.handleStateEvent));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    refreshActions = () => this.refreshActionsSubject.next();

    isApproved = (operation: SolutionApprovalActionType) => operation === SolutionApprovalActionType.approvedBy;
    isRejected = (operation: SolutionApprovalActionType) => operation === SolutionApprovalActionType.rejectedBy;

    afterUpdated = (discussion: DiscussionAndSolutionDto) => {
        if (this.discussion === discussion) {
            // The reference is literally equal.
            // This is likely because the change has already been applied to the discussion.
            return;
        }
        // Note: we normally could not edit a discussion if coming from a referred solution
        if (this.referredSolution && discussion.solution) {
            this.referredSolution.discussion = discussion as SolvedDiscussionDto;
        }
        this.discussion = discussion;
        this.discussionChange.emit(discussion);
        this.scaffold?.refreshFeed();
    };

    afterDeleted = () => this.discussionDeleted.emit();

    editSolution = (discussion: DiscussionAndSolutionDto | null = null) => {
        if (this.referredSolution || this.readonly) return;
        discussion = discussion ?? this.discussion;
        if (!discussion || !this.meetingProgressService.isInProgress(discussion.company.id, discussion.team.id)) return;
        EditSolutionDialogComponent.open(this.dialog, discussion, /* fromMeeting: */ true)
            .afterClosed().subscribe(result => {
                if (!result) return;
                this.discussion = result;
                this.discussionChange.emit(result);
                this.scaffold?.refreshFeed();
            });
    };

    markNoted = () => {
        if (this.referredSolution || this.readonly) return;
        const discussion = this.discussion;
        if (!discussion || discussion.status !== DiscussionStatus.approved || !discussion.solution ||
            !this.meetingProgressService.isInProgress(discussion.company.id, discussion.team.id)) return;
        NoteSolutionApprovalDialogComponent.open(this.dialog, discussion as SolvedDiscussionDto, /* meetingRunning: */ true)
            .afterClosed().pipe(filter(Boolean)).subscribe(res => {
                switch (res.status) {
                    case "noted":
                        this.discussion = res.discussion;
                        this.discussionChange.emit(res.discussion);
                        this.scaffold?.refreshFeed();
                        break;
                    case "edit":
                        this.editSolution(res.discussion);
                        break;
                }
            });
    };

    reviewSolution = () => {
        if (this.readonly) return;
        const solution = this.referredSolution;
        if (!solution || !this.meetingProgressService.isInProgress(solution.company.id, solution.team.id)) return;
        ReviewSolutionDialogComponent.open(this.dialog, solution, /* fromMeeting: */ true)
            .afterClosed().subscribe(result => {
                if (!result) return;
                this.referredSolution = result;
                this.solutionChange.emit(result);
                this.scaffold?.refreshFeed();
            });
    };

    private getActions = ({ discussion, referredSolution: solution }: DiscussionData) => {
        if (!discussion.solution || discussion.solution.noActionRequired) {
            return of([]);
        }
        if (solution) {
            return this.referredSolutionsApi.getSolutionActions(
                solution.company.id,
                solution.team.id,
                solution.id,
            ).pipe(
                mergeChildUpdatesFrom(this.actionStateService.events$, solution.id, EntityType.solution),
            );
        }
        return this.discussionsApi.getSolutionActions(
            discussion.company.id,
            discussion.team.id,
            discussion.id,
        ).pipe(
            mergeChildUpdatesFrom(this.actionStateService.events$, discussion.solution.id, EntityType.solution),
        );
    };

    private handleStateEvent = (event: DiscussionStateEvent) => {
        switch (event.type) {
            case "added": // We should never get an added event, but treat it as if updated for simplicity
            case "updated":
                this.afterUpdated(event.item);
                break;
            case "deleted":
                this.afterDeleted();
                break;
        }
    };
}
