import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DiscussionsApi, GetReportDto, ReportRecordDetailDto } from "@api";

import { TeamContext } from "~services/contexts";
import { ReportStateService } from "~services/state";
import { mapReportDiscussionInput } from "~shared/util/discussion-input-builder";
import { mapReportOrigin } from "~shared/util/origin-builder";

import { CommonAddChildDiscussionButtonDirective } from "./add-child-discussion-button.component";

declare type ReportDto = GetReportDto | ReportRecordDetailDto;

@Component({
    selector: "app-add-report-child-discussion-button",
    templateUrl: "./add-child-discussion-button.component.html",
    styleUrls: ["./add-child-discussion-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class AddReportChildDiscussionButtonComponent extends CommonAddChildDiscussionButtonDirective<ReportDto> {

    readonly mapToOrigin = mapReportOrigin;
    readonly mapToInput = mapReportDiscussionInput;

    constructor(
        discussionsApi: DiscussionsApi,
        teamContext: TeamContext,
        dialog: MatDialog,
        private readonly reportStateService: ReportStateService,
    ) {
        super(discussionsApi, teamContext, dialog);
    }

    protected notifyChange = (source: ReportDto) => this.reportStateService.notifyRefresh(source);

    protected creationAllowed = (source: ReportDto): boolean => source.canEdit;
}
