import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GetAttachmentFeedItemDto } from "@api";

import { FeedScope } from "~feed/services";
import { UserContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";

import { DeletableFeedListItemDirective } from "../deletable-feed-list-item.directive";

@Component({
    selector: "app-feed-list-attachment",
    templateUrl: "./feed-list-attachment.component.html",
    styleUrls: ["./feed-list-attachment.component.scss"],
    standalone: false,
})
export class FeedListAttachmentComponent extends DeletableFeedListItemDirective<GetAttachmentFeedItemDto> {
    constructor(
        feedScope: FeedScope,
        userContext: UserContext,
        notificationService: NotificationService,
        dialog: MatDialog,
    ) {
        super(feedScope, userContext, notificationService, dialog);
    }
}
