import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

@Component({
    templateUrl: "./number-exceeded-dialog.component.html",
    styleUrls: ["./number-exceeded-dialog.component.scss"],
    standalone: false,
})
export class NumberExceededDialogComponent {

    static open(dialog: MatDialog) {
        return dialog.open(NumberExceededDialogComponent, {
            autoFocus: false,
        });
    }
}
